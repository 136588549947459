"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.physicsPredictionSyncSchemaMap = void 0;
var sync_1 = require("./sync");
exports.physicsPredictionSyncSchemaMap = {
    physicsPrediction: {
        physicsGameObjectId: sync_1.SyncMethod.DiscreteStart,
        position: {
            x: sync_1.SyncMethod.Linear,
            y: sync_1.SyncMethod.Linear,
            z: sync_1.SyncMethod.Linear,
        },
        quaternion: {
            x: sync_1.SyncMethod.Linear,
            y: sync_1.SyncMethod.Linear,
            z: sync_1.SyncMethod.Linear,
            w: sync_1.SyncMethod.Linear,
        },
        shapes: sync_1.SyncMethod.DiscreteStart,
        body: {
            mass: sync_1.SyncMethod.DiscreteStart,
            fixedRotation: sync_1.SyncMethod.DiscreteStart,
            linearDamping: sync_1.SyncMethod.DiscreteStart,
            isTrigger: sync_1.SyncMethod.DiscreteStart,
        },
    },
};
