"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cannonToVector3 = exports.toCannonVec3 = exports.vector3DRotationDeg2Rad = exports.vector3DRotationRad2Deg = exports.vector3DirectionTo = exports.vector3DistanceTo = exports.vector3ProjectTo2D = exports.vector3DistanceToSqred = exports.vector3Differences = exports.vector3IsZero = exports.vector3Zero = exports.isVector3 = exports.vector3Normalize = exports.vector3Length = exports.vector3LengthSqred = exports.vector3Equal = exports.vector3Multiply = exports.vector3Subtract = exports.vector3Add = void 0;
var CANNON = __importStar(require("cannon-es"));
function vector3Add(v1, v2) {
    return {
        x: v1.x + v2.x,
        y: v1.y + v2.y,
        z: v1.z + v2.z,
    };
}
exports.vector3Add = vector3Add;
function vector3Subtract(v1, v2) {
    return {
        x: v1.x - v2.x,
        y: v1.y - v2.y,
        z: v1.z - v2.z,
    };
}
exports.vector3Subtract = vector3Subtract;
function vector3Multiply(v, scalar) {
    return {
        x: v.x * scalar,
        y: v.y * scalar,
        z: v.z * scalar,
    };
}
exports.vector3Multiply = vector3Multiply;
function vector3Equal(v1, v2) {
    return v1.x === v2.x && v1.y === v2.y && v1.z === v2.z;
}
exports.vector3Equal = vector3Equal;
function vector3LengthSqred(v) {
    return Math.pow(v.x, 2) + Math.pow(v.y, 2) + Math.pow(v.z, 2);
}
exports.vector3LengthSqred = vector3LengthSqred;
function vector3Length(v) {
    return Math.sqrt(vector3LengthSqred(v));
}
exports.vector3Length = vector3Length;
function vector3Normalize(v) {
    var mag = vector3Length(v);
    if (mag === 0) {
        throw new Error("Attempted to normalize a zero vector3");
    }
    return vector3Multiply(v, 1 / mag);
}
exports.vector3Normalize = vector3Normalize;
function isVector3(v) {
    if (typeof v === "object" &&
        v !== null &&
        Object.keys(v).length === 3 &&
        typeof v.x === "number" &&
        typeof v.y === "number" &&
        typeof v.z === "number") {
        return true;
    }
    return false;
}
exports.isVector3 = isVector3;
function vector3Zero() {
    return { x: 0, y: 0, z: 0 };
}
exports.vector3Zero = vector3Zero;
function vector3IsZero(v) {
    return v.x === 0 && v.y === 0 && v.z === 0;
}
exports.vector3IsZero = vector3IsZero;
function vector3Differences(v1, v2) {
    var differences = {};
    if (v1.x !== v2.x) {
        differences.x = v2.x;
    }
    if (v1.y !== v2.y) {
        differences.y = v2.y;
    }
    if (v1.z !== v2.z) {
        differences.z = v2.z;
    }
    return differences;
}
exports.vector3Differences = vector3Differences;
function vector3DistanceToSqred(v1, v2) {
    return (Math.pow(v1.x - v2.x, 2) +
        Math.pow(v1.y - v2.y, 2) +
        Math.pow(v1.z - v2.z, 2));
}
exports.vector3DistanceToSqred = vector3DistanceToSqred;
/**
 * Projects a vector3 onto the 2d plane. The (x, y, z) => (x: x, y: z)
 */
function vector3ProjectTo2D(v) {
    return {
        x: v.x,
        y: v.z,
    };
}
exports.vector3ProjectTo2D = vector3ProjectTo2D;
function vector3DistanceTo(v1, v2) {
    return Math.sqrt(vector3DistanceToSqred(v1, v2));
}
exports.vector3DistanceTo = vector3DistanceTo;
/**
 * Returns a normalised vector which is the direction from v1 to v2.
 * If v1 === v2 then the zero vector is returned
 */
function vector3DirectionTo(v1, v2) {
    var dir = vector3Subtract(v2, v1);
    if (vector3IsZero(dir)) {
        return dir;
    }
    return vector3Normalize(dir);
}
exports.vector3DirectionTo = vector3DirectionTo;
function vector3DRotationRad2Deg(v) {
    return {
        x: (180 * v.x) / Math.PI,
        y: (180 * v.y) / Math.PI,
        z: (180 * v.z) / Math.PI,
    };
}
exports.vector3DRotationRad2Deg = vector3DRotationRad2Deg;
function vector3DRotationDeg2Rad(v) {
    return {
        x: (Math.PI * v.x) / 180,
        y: (Math.PI * v.y) / 180,
        z: (Math.PI * v.z) / 180,
    };
}
exports.vector3DRotationDeg2Rad = vector3DRotationDeg2Rad;
// Utility function to transform to cannon Vec3
function toCannonVec3(v) {
    return new CANNON.Vec3(v.x, v.y, v.z);
}
exports.toCannonVec3 = toCannonVec3;
// Utility function to transform from cannon Vec3
function cannonToVector3(v) {
    return {
        x: v.x,
        y: v.y,
        z: v.z,
    };
}
exports.cannonToVector3 = cannonToVector3;
