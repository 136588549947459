import { MMOAssetManager } from "@game/config";
import { combineTextures } from "../utils/combineTextures";
import {
  ConfigurableMesh,
  ConfigurableMeshOptions,
} from "../utils/configurableMesh";
import { GoblinConfig } from "mmo-common";
import { GOBLIN_TEXTURE_MAPPING } from "./mapping";
import THREE from "realms-three";
import { smoothGeometry } from "../player/characterUtils";
import {
  DissolveMaterialUniforms,
  createDissolveMaterial,
} from "@game/materials/dissolveMaterial";
import { LerpGameObject } from "realms-engine";

export class GoblinMesh extends ConfigurableMesh<GoblinConfig> {
  updateDissolveMaterial:
    | ReturnType<typeof createDissolveMaterial>["updateUniforms"]
    | null = null;

  private dissolveUniformLerp: LerpGameObject<number>;
  private dissolveUniforms: Omit<DissolveMaterialUniforms, "progress"> = {};

  constructor(config: GoblinConfig, options?: ConfigurableMeshOptions) {
    const goblinMesh = MMOAssetManager.getObject3D("goblin", {
      cloneDeep: true,
    });
    super(goblinMesh, config, options);

    this.dissolveUniformLerp = this.addChild(
      new LerpGameObject<number>((progress) =>
        this.updateDissolveMaterial?.({ progress, ...this.dissolveUniforms })
      )
    );

    const mesh = this.baseMesh.children.find((m) => m instanceof THREE.Mesh) as
      | THREE.Mesh
      | undefined;
    if (mesh) {
      const { material, updateUniforms } = createDissolveMaterial({
        baseMaterial: mesh.material as THREE.Material,
        progress: 1,
      });
      mesh.material = material;
      this.updateDissolveMaterial = updateUniforms;
    }
  }

  /**
   * Dissolves the mesh
   */
  dissolve({
    start,
    end,
    durationMs,
    ...uniforms
  }: { start: number; end: number; durationMs: number } & Omit<
    DissolveMaterialUniforms,
    "progress"
  >) {
    this.dissolveUniforms = uniforms;
    this.dissolveUniformLerp.lerp(start, end, durationMs);
  }

  /**
   * Update mesh when changes occur
   */
  protected onConfigChanges(config: GoblinConfig) {
    const mesh = this.baseMesh.children.find((m) => m instanceof THREE.Mesh) as
      | THREE.Mesh
      | undefined;

    if (!mesh) {
      return this.baseMesh;
    }

    // Clean previous texture
    (mesh.material as THREE.MeshPhongMaterial).map?.dispose();

    // Create new texture
    (mesh.material as THREE.MeshPhongMaterial).map = combineTextures([
      MMOAssetManager.getTexture(GOBLIN_TEXTURE_MAPPING.skin[config.skin], {
        clone: true,
      }),
      MMOAssetManager.getTexture(
        GOBLIN_TEXTURE_MAPPING.features[config.features],
        {
          clone: true,
        }
      ),
      MMOAssetManager.getTexture(
        GOBLIN_TEXTURE_MAPPING.clothing[config.clothing],
        {
          clone: true,
        }
      ),
    ]);

    smoothGeometry(mesh);

    // No changes
    return this.baseMesh;
  }
}
