import { Render3DGameObject } from "realms-engine-browser";
import { ConfigurableMesh } from "./configurableMesh";
import { ThreeAnimationGameObject } from "./threeAnimation.gameObject";
import { MMOAssetManager } from "@game/config";
import { AssetNames } from "../base-character/mapping";

export class ConfigurableMeshGameObject<
  T extends {},
  K extends {}
> extends Render3DGameObject {
  private animationGameObject: ThreeAnimationGameObject;

  private mesh: ConfigurableMesh<T>;

  constructor(mesh: ConfigurableMesh<T>, private animationMap: K) {
    super(mesh.getMesh());

    this.mesh = this.addChild(mesh);

    this.animationGameObject = new ThreeAnimationGameObject();
    this.loadAnimations();
    this.addChild(this.animationGameObject);

    this.mesh.addListener("onMeshChange", (mesh) => {
      this.loadAnimations();
      this.setThreeObject(mesh.getMesh());
    });
  }

  /**
   * Returns the configurale mesh
   */
  getConfigurableMesh() {
    return this.mesh;
  }

  /**
   * Updates the mesh config
   */
  updateConfig(config: T) {
    this.mesh.updateConfig(config);
  }

  /**
   * Plays an animation
   */
  playAnimation(
    data: {
      name: Exclude<keyof K, symbol | number>;
      duration: number | null;
    },
    options: { fadeOutDuration?: number } = {}
  ) {
    this.animationGameObject.play(data.name, {
      duration: data.duration,
      fadeOutDuration: options.fadeOutDuration,
    });
  }

  /**
   * Load animations
   */
  private loadAnimations() {
    this.animationGameObject.updateAnimationMap(
      Object.fromEntries(
        Object.entries(this.animationMap).map(([animationName, assetName]) => [
          animationName,
          {
            clip: MMOAssetManager.getObject3D(assetName as AssetNames, {
              clone: true,
            }).animations[0],
          },
        ])
      )
    );
  }
}
