export const TEXTURE_PIXEL_MAP: { [key: string]: string | undefined } = {
  baseCharacterMaleEyesNormal:
    "d2ti!d2tp?d3lx!d3m7?d4ec!d4eg?d4ek!d4eo?d56r!d56v?d571?d575?d5z7!d5zb?d5zl?d6rn!d6rr?d6rx!d6s1?d7ke!d7ki?d8cn?d8cu!d8cy?d94z!d953?d95a!d95c?d9xf!d9xj?daqi!daqj?dbix!dbiz?dcbc!dcbf?dd3s!dd3v?ddw8!ddwb?deon!deoq?dfh2!dfh6?dg9h!dg9l?dh1v!dh21?dhu8!dhug?dimj!dimv?djek!djfa?dk6z!dk7o?dkzi!dkzo?dkzr!dl00?3z91",
  baseCharacterFemaleEyesNormal:
    "3gnr!3go4?3hg7!3hgl?3i8n!3i91?3j13!3j17?3j1d!3j1h?3jtj!3jtm?3jtu!3jtx?3kma!3kmd?3ler!3let?3m6v!3m6y?3m79?3mzc!3mzf?3mzp!3mzq?3nrs!3nrv?3ns3!3ns6?3ok8!3okb?3okj!3okk?3pco!3pcr?3q54!3q57?3uwd!3uwe?3vor!3vov?3wh5!3whc?3x9j!3x9s?3y1w!3y28?3yty?3yu1!3yun?3zm8!3zn3?40eo!40fi?4174!417v?41zv!4209",
  baseCharacterMaleEyesDetermined:
    "d5za!d5zi?d6ro!d6rz?d7k3!d7kd?d8cj!d8cn?d8cs!d8ct?d8cw?d94z!d953?d958!d95c?d9xi!d9xj?d9xo!d9xs?dapy!daq8?dbic!dbil?dcat!dcay?dd39!dd3b?ddw8!ddwe?deom!deov?dfh0!dfhc?dg9e!dg9t?dh1t!dh29?dhu7!dhup?diml!din2?djez!djff?dk7d!dk7t?dkzr!dl07?dlrn!dlrp?dls6!dlsm?dmk3!dmk9?dmkj!dml1?dncj!dndg?do51!do5u?doxi!doy9?dppz!dpqo?dqih!dqj3?dray!drbh?ds3h!ds3w?dsw0!dswa",
  baseCharacterFemaleEyesDetermined:
    "3gnq!3go3?3hg4!3hgj?3i8l!3i8p?3i8v!3i8w?3i8y!3i90?3j13!3j14?3j1c?3j1f!3j1g?3jtj!3jtk?3klz!3km0?3lef!3leg?3m6u!3m6w?3mzb!3mzc?3nrt?3sj4!3sj5?3tbj!3tbm?3u3x!3u42?3uwc!3uwi?3voq!3voy?3wh5!3whe?3x9j!3x9u?3y1x!3y29?3yu9!3yuo?3zme?3zmj!3zn3?40ep!40fh?4176!417p?41zq!4201",
  baseCharacterMaleEyesCurious:
    "d4eg!d4eo?d56v!d575?d5z9!d5zc?d5zi!d5zl?d6rn!d6rs?d6rz!d6s2?d7k3!d7k7?d7kf!d7ki?d8cj!d8cn?d8cw?d953?d95c?d9xs!d9xt?daq0?daq8!daqc?dbie!dbig?dbio!dbis?dcat!dcax?dcb4!dcb8?dd39!dd3e?dd3k!dd3n?ddvp!ddw1?deo6!deoe?dml7!dml8?dndk!dndo?do5s!do64?doxx!doyj?dpqc!dpqz?dqit!dqje?drbb!drbt?ds3u!ds48?dswf!dswh?dswj",
  baseCharacterFemaleEyesCurious:
    "3fv9!3fvp?3gno!3go5?3go7?3hg4!3hga?3hgg!3hgj?3i8k!3i8p?3i8x!3i8y?3j15?3j1e!3j1j?3jtk?3jtv!3jty?3klw!3km0?3kmb!3kmf?3led!3lef?3les!3lev?3m6t!3m6v?3m79!3m7b?3mz9!3mzc?3mzp!3mzr?3nrq!3nrs?3ns5!3ns7?3ok6!3oka?3okk!3okn?3pcn!3pcr?3pcz!3pd3?3q53!3q59?3q5c!3q5h?3qxk!3qxw?3rq1!3rqb?3sih!3sio?40fi!40fj?4173!4175?417w!417z?41zi!41zp?4208!420f?42rz!42sv?43ki!43lb?44d1!44dp?455l!4561",
  baseCharacterMaleEyesCool:
    "d4eg!d4en?d56u!d56x?d572!d574?d5z9!d5zc?d5zj!d5zk?d6rq!d6rs?d6rz?d7k6!d7k8?d7kf?d8ck!d8co?d8cv!d8cw?d94z!d954?d95b!d95d?d95k!d95p?d9xf!d9xk?d9xr!d9xt?d9xx!d9y7?daqb!daqo?dbip!dbj5?dcb5!dcbc?dcbh!dcbk?dd3k!dd3r?ddvz!ddw5?deod!deok?dfgs!dfgy?dg97!dg9d?dh16!dh18?dh1l!dh1r?dhtn!dhts?dhty!dhu6?dim5!diml?djen!djey?dk77!dk7a",
  baseCharacterFemaleEyesCool:
    "3hg6!3hgk?3i8m!3i8r?3i8w!3i90?3j12!3j16?3j1d!3j1g?3jti!3jtm?3jtu?3kly!3km2?3kma!3kmb?3leh!3lei?3leq!3les?3m6t?3m6w!3m6y?3m76!3m79?3mz9!3mze?3mzm!3mzp?3nrp!3nrv?3ns1!3ns3?3ok5!3oki?3pcn!3pcu?3uwf!3uwg?3vot!3vox?3wh7!3whd?3x9k!3x9t?3y1x!3y29?3yu7!3yuo?3zmi!3zn1?40eo!40fc?4174!417n",
  baseCharacterMaleEyesKind:
    "d3ly?d4ec?d56v!d572?d5z9!d5zj?d6rp!d6rs?d6ry!d6s0?d7k4!d7k7?d7kf!d7kh?d7kj?d8cm!d8cn?d8cv!d8cw?d951!d953?d95b!d95c?d95g?d9xe!d9xj?d9xs!d9xu?dapw!dapz?daq8!daqa?dbic!dbig?dbij!dbik?dbio!dbiq?dcat!dcax?dcaz!dcb2?dcb4!dcb7?dd3b!dd3n?ddvu!ddvw?dk7s!dk7u?dkzf!dkzh?dl04!dl0b?dlrv!dls0?dlsi!dlsr?dmkb!dmkl?dmkt!dml7?dnct!dndn?do5b!do62?doxt!doyh?dpqc!dpqp?dqiv!dqiz",
  baseCharacterFemaleEyesKind:
    "3fv9!3fvr?3gno!3go7?3hg3!3hgn?3i8j!3i93?3j0z!3j11?3j17!3j1c?3j1j?3jtf!3jth?3jtn!3jtt?3jtz?3klv!3kmf?3leb!3lev?3m6s!3m7b?3mz9!3mzr?3nrq!3ns7?3ok7!3okn?3pcq!3pd0?3x8x!3x8y?3y1c!3y1g?3ytt!3yty?3yul!3yuo?3zmb!3zmi?3zmk!3zn4?40et!40fj?417c!417z?41zy!420e",
  baseCharacterMaleEyesBeautiful:
    "d4ee!d4ep?d56r!d575?d5z7!d5zd?d5zg!d5zi?d5zl?d6rm!d6rn?d6rt?d6rx!d6ry?d6s1!d6s2?d7k2!d7k5?d7k8?d7ki?d8cj!d8cn?d94z!d950?d954!d955?d959!d95b?d9xf?d9xj!d9xm?d9xo!d9xs?d9xu?dapw!daq8?daqa?dbid!dbif?dbii!dbiq?dcat!dcaz?dfh7!dfha?dg9m!dg9q?dh20!dh26?dhuf!dhum?dimu!din1?djf9!djfh?dk7o!dk7w?dl02!dl0c?dlsh!dlss?dmk3!dmk5?dmkw!dml7?dncj!dncp?dndb!dndm?do53!do58?do5p!do60?doxl!doxq?doy4!doye?dpq3!dpq9?dpqh!dpqs?dqik!dqir?dqiu!dqj6?drb2!drbk?ds3j!ds3x?dsw0!dswa?dtoi!dtom",
  baseCharacterFemaleEyesBeautiful:
    "3fva!3fvo?3gnq!3go3?3hg5!3hgf?3hgi!3hgj?3i8l!3i8r?3i8u!3i8z?3j15!3j17?3j1b?3j1e!3j1g?3jtl?3jtu!3jtw?3klx!3km0?3lei?3les!3let?3m6x!3m6z?3m74?3m78!3m79?3mz9!3mzg?3mzk!3mzl?3mzn!3mzq?3nrp!3nrs?3nrw!3nrx?3ns0!3ns1?3ns3!3ns6?3ok6!3ok7?3okc!3okm?3pcn!3pd0?3q55!3q5b?3x9p!3x9t?3y23!3y29?3yuh!3yup?3zmu!3zn5?40ek!40en?40f6!40fk?4170!4175?417f!417z?41zi!420e?42rz!42sr?43ki!43l4?44da!44de",
  baseCharacterMaleEyesSharp:
    "d573!d574?d5z8!d5zk?d6rl!d6rr?d6rw!d6rz?d7k1!d7k6?d7kd!d7ke?d8ch!d8cm?d8ct!d8cx?d950!d952?d959!d95d?d9xh!d9xj?d9xp!d9xr?dapv!dapz?dbia!dbig?dcar!dcav?dg9l!dg9q?dh1z!dh27?dhue!dhun?dims!din2?djf5!djfi?dk7j!dk7y?dkzx!dl0e?dlsb!dlst?dmkq!dml9?dnd6!dndn?do5m!do62?doy2!doyg?dpqr!dpqt",
  baseCharacterFemaleEyesSharp:
    "3j13!3j1g?3jtj!3jtw?3kly!3km3?3km9!3kmd?3lej?3lep?3m6x!3m6z?3m75!3m79?3mza!3mzf?3mzl!3mzq?3nrq!3nrv?3ns1!3ns6?3ok6!3okb?3okh!3okm?3pcn!3pd0?3voz?3whd!3whf?3x9n!3x9v?3y1z!3y2b?3yuc!3yuq?3zmq!3zn6?40f5!40fm?417k!4181?41zz!420h?42sg!42sw?43kx!43lb?44dd!44do?455v!4561",
  baseCharacterMaleEyesAlmond:
    "d56z!d572?d5z8!d5za?d5zf!d5zi?d6rl!d6rq?d6rv!d6rw?d7k1!d7k6?d7kc?d8ch!d8cm?d8cs?d8cv!d8cw?d952?d958!d95d?d9xh!d9xi?d9xo!d9xq?dapv!dapz?daq4!daq5?dbia!dbig?deos!deot?dfh7!dfha?dg9m!dg9q?dh21!dh26?dhug!dhul?dimu!din0?djf8!djfe?dk7l!dk7t?dkzz!dl08?dlsc!dlsm?dmkn!dmkz?dncv!dndd?do57!do5p?doxm!doy0?dpq3!dpqa",
  baseCharacterFemaleEyesAlmond:
    "3i8n!3i8q?3j13!3j1b?3jtk!3jtw?3km1!3km2?3km9!3kme?3leh!3lei?3leq!3ler?3m6w!3m6y?3m76?3mz9!3mze?3mzm?3nrp!3nru?3ns2!3ns3?3ok6!3okb?3okh!3okm?3pcp!3pcz?3u40?3uwf!3uwg?3vot!3vow?3wh7!3whb?3x9k!3x9r?3y1s!3y26?3ytp!3yul?3zmd!3zmz?40ex!40fc",
  baseCharacterMaleEyesFierce:
    "d6rr!d6rz?d7k5!d7kg?d8ck!d8co?d8cu!d8cx?d94z!d952?d9xf!d9xh?d9xs!d9xu?dapv!dapx?daq9!daqa?dbic!dbie?dbj1!dbj3?dcas?dcbg!dcbk?dd3v!dd40?ddvp!ddvr?ddwa!ddwg?deo6?deop!deow?dfh3!dfhc?dg9i!dg9s?dh1w!dh27?dhub!dhun?dimp!din3?djf3!djfi?dk7h!dk7x?dkzv!dl0b?dls8!dlsp?dmkn!dml2?dnd1!dndg?do5f!do5t?doxu!doy6?dpq8!dpqi?dqim!dqit?drb0!drb3",
  baseCharacterFemaleEyesFierce:
    "3fve!3fvo?3gnv!3go5?3hgb!3hgm?3i8p!3i8r?3i8x!3i91?3j15!3j16?3j1e!3j1h?3jtk!3jtm?3jtv!3jtw?3klz!3km2?3kmb!3kmc?3leg!3lei?3m6w!3m6y?3rqm!3rqo?3sj0!3sj4?3tbf!3tbk?3u3u!3u40?3uw9!3uwg?3voo!3vov?3wh1!3whb?3x9g!3x9r?3y1v!3y27?3yu9!3yum?3zmn!3zn1?40f1!40ff?417f!417q?41zz?4201!4202",
  baseCharacterMaleEyesCat:
    "d56t!d575?d5z9!d5ze?d5zg!d5zm?d6ro!d6ru?d6rw!d6rx?d6rz!d6s2?d7k7?d7k9!d7ka?d7kd?d8cj?d8cp!d8cq?d8ct!d8cu?d8cy?d94z!d952?d955!d956?d959!d95a?d95d!d95e?d9xg!d9xi?d9xl!d9xm?d9xp!d9xq?d9xs!d9xu?dapw!dapz?daq2!daq3?daq5!daq6?daq8!daqa?dbic!dbij?dbil!dbip?dcat!dcb3?dd3c?dd3e!dd3g?dd3x!dd3z?ddwd!ddwg?deos!deov?dfh7!dfhb?dg9l!dg9r?dh20!dh26?dhtu!dhtw?dhuc!dhul?dima!dime?dimk!din0?djer!djff?dk79!dk7u?dkzr!dl04?dlsa!dlsf",
  baseCharacterFemaleEyesCat:
    "3gns!3gnx?3hg7!3hga?3hgc!3hgl?3i8m!3i8p?3i8r!3i8u?3i8w!3i8z?3i92?3j16!3j19?3j1d!3j1g?3j1i!3j1k?3jth!3jtj?3jtn!3jtp?3jtt!3jtv?3kly!3km0?3km2!3km5?3km9!3kmc?3kme!3kmf?3lee!3leg?3lei!3lel?3lep!3les?3leu!3lev?3m6v!3m71?3m75!3m78?3m7a!3m7b?3mze!3mzn?3tbk?3u3y!3u3z?3uwd!3uwf?3vos!3vov?3wh6!3wha?3x9k!3x9q?3y16!3y1b?3y1v!3y25?3ytq!3ytw?3yu3!3yul?3zm7!3zmz?40eq!40fa",
};
