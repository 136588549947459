import { Box } from "@chakra-ui/react";
import { TouchEventHandler, useCallback, useRef, useState } from "react";
import {
  Vector2,
  vector2Angle,
  vector2Equal,
  vector2IsZero,
  vector2Normalize,
  vector2Rotate,
  vector2Subtract,
} from "realms-engine";
import { TrackingCameraSystem } from "realms-engine-browser";
import { useClosestTouch } from "../../hooks/useClosestTouch";
import { GameControllerProps } from "./GameController";
import { useService } from "../../services";
import { PlayerSystem } from "..";
import { DynamicJoystick } from "@components/DynamicJoystick";
import { GiSwordWound } from "react-icons/gi";
import { TbArrowBigUpLinesFilled } from "react-icons/tb";
import { SAFE_AREA_INSET_TOP } from "../../configs";
import { YELLOW_GLOW_BOX_SHADOW } from "@utils/glows";

export function MobileController({ realm }: GameControllerProps) {
  const lastMoveVectorRef = useRef<Vector2>({ x: 0, y: 0 });
  const lastTouchVectorRef = useRef<Vector2 | null>(null);
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);
  const { getClosestTouch } = useClosestTouch(scrollAreaRef.current);
  const [currentTouchId, setCurrentTouchId] = useState<number | null>();
  const dialogueService = useService("dialogue");
  const [attackTouch, setAttackTouch] = useState(false);
  const [jumpTouch, setJumpTouch] = useState(false);

  const onPositionChange = (position: Vector2) => {
    const moveVector: Vector2 = vector2IsZero(position)
      ? position
      : vector2Rotate(vector2Normalize(position), -Math.PI / 2);
    if (!vector2Equal(moveVector, lastMoveVectorRef.current)) {
      lastMoveVectorRef.current = moveVector;

      const cameraSystem = realm.systems.get(TrackingCameraSystem);

      const move = vector2IsZero(moveVector)
        ? moveVector
        : vector2Rotate(cameraSystem.getHVector(), vector2Angle(moveVector));

      dialogueService.endDialogue();
      realm.systems.get(PlayerSystem).handlePlayerInput({ move });
    }
  };

  const onTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
    if (currentTouchId !== null) {
      return;
    }
    const closestTouch = getClosestTouch(e.touches);
    if (!closestTouch) {
      return;
    }
    lastTouchVectorRef.current = {
      x: closestTouch.clientX,
      y: closestTouch.clientY,
    };
    setCurrentTouchId(closestTouch.identifier);
  };

  const onTouchEnd: TouchEventHandler<HTMLDivElement> = (e) => {
    lastTouchVectorRef.current = null;
    // Remove current touch
    if (
      setCurrentTouchId !== null &&
      !Array.from(e.touches).find(
        (touch) => touch.identifier === currentTouchId
      )
    ) {
      setCurrentTouchId(null);
    }
  };

  const onTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
    const touch = Array.from(e.touches).find(
      (touch) => touch.identifier === currentTouchId
    );
    if (!touch) {
      return;
    }
    const currTouch = { x: touch.clientX, y: touch.clientY };
    if (lastTouchVectorRef.current !== null) {
      const vectorDiff = vector2Subtract(currTouch, lastTouchVectorRef.current);
      const cameraSystem = realm.systems.get(TrackingCameraSystem);
      const hSensitivity = 0.005;
      const vSensitivity = 0.0005;
      cameraSystem.rotateHAngle(vectorDiff.x * hSensitivity);
      cameraSystem.rotateVAngle(vectorDiff.y * vSensitivity);
    }
    lastTouchVectorRef.current = currTouch;
  };

  const onAttack = useCallback(() => {
    dialogueService.endDialogue();
    realm.systems.get(PlayerSystem).handlePlayerInput({ attack: true });
  }, [dialogueService, realm.systems]);

  const onJump = useCallback(() => {
    dialogueService.endDialogue();
    realm.systems.get(PlayerSystem).handlePlayerInput({ jump: true });
  }, [dialogueService, realm.systems]);

  return (
    <Box position={"fixed"} width="100%" height={"100%"} zIndex={3}>
      <Box
        width="50%"
        height={"100%"}
        marginLeft="50%"
        ref={scrollAreaRef}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
      />
      <Box position="absolute" top={0} left={0} height={"100%"} width={"50%"}>
        <DynamicJoystick onPositionChange={onPositionChange} />
      </Box>
      <Box
        position={"absolute"}
        bottom={4}
        right={SAFE_AREA_INSET_TOP}
        display={"flex"}
        flexDir={"column"}
        alignItems={"center"}
      >
        <Box
          width={"80px"}
          height={"80px"}
          marginBottom={2}
          borderRadius={"full"}
          backgroundColor={"blackAlpha.400"}
          opacity={"0.8"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          marginRight={"50px"}
          onTouchStart={(e) => {
            onAttack();
            setAttackTouch(true);
            e.preventDefault();
          }}
          onTouchEnd={(e) => {
            setAttackTouch(false);
            e.preventDefault();
          }}
          transition={"transform 0.1s, color 0.1s"}
          color={attackTouch ? "yellow.500" : "gray.50"}
          transform={attackTouch ? "scale(1.2)" : undefined}
          boxShadow={attackTouch ? YELLOW_GLOW_BOX_SHADOW : undefined}
        >
          <GiSwordWound size={40} />
        </Box>
        <Box
          width={"60px"}
          height={"60px"}
          marginBottom={2}
          borderRadius={"full"}
          backgroundColor={"blackAlpha.400"}
          opacity={"0.8"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          marginLeft={"50px"}
          onTouchStart={(e) => {
            onJump();
            setJumpTouch(true);
            e.preventDefault();
          }}
          onTouchEnd={(e) => {
            setJumpTouch(false);
            e.preventDefault();
          }}
          transition={"transform 0.1s, color 0.1s"}
          color={jumpTouch ? "yellow.500" : "gray.50"}
          transform={jumpTouch ? "scale(1.2)" : undefined}
          boxShadow={jumpTouch ? YELLOW_GLOW_BOX_SHADOW : undefined}
        >
          <TbArrowBigUpLinesFilled size={30} />
        </Box>
      </Box>
    </Box>
  );
}
