"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MMOAPIError = void 0;
var MMOAPIError;
(function (MMOAPIError) {
    MMOAPIError["NonPlayerAccount"] = "Account is not a player account";
    MMOAPIError["InvalidCharacter"] = "The character specified does not exist";
    MMOAPIError["InsufficientPermissions"] = "You do not have the required permissions to do this action";
    MMOAPIError["UsernameAlreadyExists"] = "Username provided already exists";
    MMOAPIError["CharacterLimitReached"] = "You have reached the max character limit";
    MMOAPIError["InvalidAccount"] = "The given account does not exist";
})(MMOAPIError || (exports.MMOAPIError = MMOAPIError = {}));
