import baseCharacterMaleAsset from "@assets/base_character_v2/base_character_male_rigged.fbx";
import baseCharacterMaleSkinShadowsAsset from "@assets/base_character_v2/textures/male_base_skin_shadows.png";
import baseCharacterMaleEyesNormalAsset from "@assets/base_character_v2/textures/male_eyes_normal.png";
import baseCharacterMaleEyesDeterminedAsset from "@assets/base_character_v2/textures/male_eyes_determined.png";
import baseCharacterMaleEyesCuriousAsset from "@assets/base_character_v2/textures/male_eyes_curious.png";
import baseCharacterMaleEyesCoolAsset from "@assets/base_character_v2/textures/male_eyes_cool.png";
import baseCharacterMaleEyesKindAsset from "@assets/base_character_v2/textures/male_eyes_kind.png";
import baseCharacterMaleEyesBeautifulAsset from "@assets/base_character_v2/textures/male_eyes_beautiful.png";
import baseCharacterMaleEyesSharpAsset from "@assets/base_character_v2/textures/male_eyes_sharp.png";
import baseCharacterMaleEyesAlmondAsset from "@assets/base_character_v2/textures/male_eyes_almond.png";
import baseCharacterMaleEyesFierceAsset from "@assets/base_character_v2/textures/male_eyes_fierce.png";
import baseCharacterMaleEyesCatAsset from "@assets/base_character_v2/textures/male_eyes_cat.png";
import baseCharacterMaleClothingAsset from "@assets/base_character_v2/textures/male_base_clothing.png";
import malePantsBaseAsset from "@assets/base_character_v2/textures/male_pants_base.png";
import malePantsAssassinsAsset from "@assets/base_character_v2/textures/male_pants_assassins.png";
import maleShirtAssassinsAsset from "@assets/base_character_v2/textures/male_shirt_assassins_top.png";
import maleShirtMageAsset from "@assets/base_character_v2/textures/male_shirt_mage.png";
import maleShirtWarriorAsset from "@assets/base_character_v2/textures/male_shirt_warrior.png";
import baseCharacterMaleIdleAnimationAsset from "@assets/base_character_v2/animations/idle_male.fbx";
import warriorMaleIdleAnimationAsset from "@assets/base_character_v2/animations/warrior_idle_male.fbx";
import rogueMaleIdleAnimationAsset from "@assets/base_character_v2/animations/rogue_idle_male.fbx";
import mageMaleIdleAnimationAsset from "@assets/base_character_v2/animations/mage_idle_male.fbx";
import baseCharacterMaleWalkAnimationAsset from "@assets/base_character_v2/animations/walk_male.fbx";
import baseCharacterMaleJumpAnimationAsset from "@assets/base_character_v2/animations/jump_male.fbx";
import baseCharacterMaleDeathAnimationAsset from "@assets/base_character_v2/animations/death_male.fbx";
import baseCharacterMaleHorizontalSlashAnimationAsset from "@assets/base_character_v2/animations/horizontal_slash_male.fbx";
import baseCharacterMaleAttack360AnimationAsset from "@assets/base_character_v2/animations/attack_360_male.fbx";
import baseCharacterMaleVerticalSlashAnimationAsset from "@assets/base_character_v2/animations/vertical_slash_male.fbx";
import baseCharacterMaleDefaultRaceTextureAsset from "@assets/base_character_v2/textures/male_default_race_texture.png";
import baseCharacterMaleOrcTextureAsset from "@assets/base_character_v2/textures/male_orc_texture.png";
import baseCharacterMoustacheNormalAsset from "@assets/base_character_v2/moustache/moustache_normal.fbx";
import baseCharacterBeardNormalAsset from "@assets/base_character_v2/beard/beard_normal.fbx";
import baseCharacterFemaleAsset from "@assets/base_character_v2/base_character_female_rigged.fbx";
import baseCharacterFemaleSkinShadowsAsset from "@assets/base_character_v2/textures/female_base_skin_shadow.png";
import baseCharacterFemaleEyesNormalAsset from "@assets/base_character_v2/textures/female_eyes_normal.png";
import baseCharacterFemaleEyesDeterminedAsset from "@assets/base_character_v2/textures/female_eyes_determined.png";
import baseCharacterFemaleEyesCuriousAsset from "@assets/base_character_v2/textures/female_eyes_curious.png";
import baseCharacterFemaleEyesCoolAsset from "@assets/base_character_v2/textures/female_eyes_cool.png";
import baseCharacterFemaleEyesKindAsset from "@assets/base_character_v2/textures/female_eyes_kind.png";
import baseCharacterFemaleEyesBeautifulAsset from "@assets/base_character_v2/textures/female_eyes_beautiful.png";
import baseCharacterFemaleEyesSharpAsset from "@assets/base_character_v2/textures/female_eyes_sharp.png";
import baseCharacterFemaleEyesAlmondAsset from "@assets/base_character_v2/textures/female_eyes_almond.png";
import baseCharacterFemaleEyesFierceAsset from "@assets/base_character_v2/textures/female_eyes_fierce.png";
import baseCharacterFemaleEyesCatAsset from "@assets/base_character_v2/textures/female_eyes_cat.png";
import baseCharacterFemaleClothingAsset from "@assets/base_character_v2/textures/female_base_clothing.png";
import femalePantsBaseAsset from "@assets/base_character_v2/textures/female_pants_base.png";
import femaleShirtBaseAsset from "@assets/base_character_v2/textures/female_shirt_base.png";
import femalePantsAssassinsAsset from "@assets/base_character_v2/textures/female_pants_assassins.png";
import femaleShirtAssassinsAsset from "@assets/base_character_v2/textures/female_shirt_assassins_top.png";
import femaleShirtMageAsset from "@assets/base_character_v2/textures/female_shirt_mage.png";
import femaleShirtWarriorAsset from "@assets/base_character_v2/textures/female_shirt_warrior.png";
import baseCharacterFemaleIdleAnimationAsset from "@assets/base_character_v2/animations/idle_female.fbx";
import warriorFemaleIdleAnimationAsset from "@assets/base_character_v2/animations/warrior_idle_female.fbx";
import rogueFemaleIdleAnimationAsset from "@assets/base_character_v2/animations/rogue_idle_female.fbx";
import mageFemaleIdleAnimationAsset from "@assets/base_character_v2/animations/mage_idle_female.fbx";
import baseCharacterFemaleWalkAnimationAsset from "@assets/base_character_v2/animations/walk_female.fbx";
import baseCharacterFemaleJumpAnimationAsset from "@assets/base_character_v2/animations/jump_female.fbx";
import baseCharacterFemaleDeathAnimationAsset from "@assets/base_character_v2/animations/death_female.fbx";
import baseCharacterFemaleHorizontalSlashAnimationAsset from "@assets/base_character_v2/animations/horizontal_slash_female.fbx";
import baseCharacterFemaleAttack360AnimationAsset from "@assets/base_character_v2/animations/attack_360_female.fbx";
import baseCharacterFemaleVerticalSlashAnimationAsset from "@assets/base_character_v2/animations/vertical_slash_female.fbx";
import baseCharacterFemaleDefaultRaceTextureAsset from "@assets/base_character_v2/textures/female_default_race_texture.png";
import baseCharacterFemaleOrcTextureAsset from "@assets/base_character_v2/textures/female_orc_texture.png";
import baseCharacterHairBaseNormalAsset from "@assets/base_character_v2/hair_base/hair_base_normal.fbx";
import baseCharacterHairBaseMohawkAsset from "@assets/base_character_v2/hair_base/hair_base_mohawk.fbx";
import baseCharacterHairBaseUndercutAsset from "@assets/base_character_v2/hair_base/hair_base_undercut.fbx";
import baseCharacterHairBaseSpikyAsset from "@assets/base_character_v2/hair_base/hair_base_spiky.fbx";
import baseCharacterHairBackShortAsset from "@assets/base_character_v2/hair_back/hair_back_short.fbx";
import baseCharacterHairBackLongAsset from "@assets/base_character_v2/hair_back/hair_back_long.fbx";
import baseCharacterHairFringeNormalAsset from "@assets/base_character_v2/hair_fringe/fringe_normal.fbx";
import baseCharacterHairFringeStraightAsset from "@assets/base_character_v2/hair_fringe/fringe_straight.fbx";
import baseCharacterHairFringeMiddlePartAsset from "@assets/base_character_v2/hair_fringe/fringe_middle_part.fbx";
import baseCharacterHairFringeMiddlePartThickAsset from "@assets/base_character_v2/hair_fringe/fringe_middle_part_thick.fbx";
import baseCharacterHairFringeStraightBangsAsset from "@assets/base_character_v2/hair_fringe/fringe_straight_bangs.fbx";
import baseCharacterHairSideNormalAsset from "@assets/base_character_v2/hair_side/hair_side_normal.fbx";
import baseCharacterHairTailPonyTailAsset from "@assets/base_character_v2/hair_tail/hair_tail_ponytail.fbx";
import baseCharacterHairTailTwinAsset from "@assets/base_character_v2/hair_tail/hair_tail_twin.fbx";
import baseCharacterHairTailDoubleBun from "@assets/base_character_v2/hair_tail/hair_tail_double_bun.fbx";
import baseCharacterEarsHumanAsset from "@assets/base_character_v2/ears/ears_human.fbx";
import baseCharacterEarsElfAsset from "@assets/base_character_v2/ears/ears_elf.fbx";
import baseCharacterEarsOrcAsset from "@assets/base_character_v2/ears/ears_orc.fbx";

export const CHARACTER_SCALE = 0.002;
export const CHARACTER_UI_SCALE = 5;

const BaseMaleCharacterAssetMap = {
  baseCharacterMale: {
    fileName: baseCharacterMaleAsset,
    scale: CHARACTER_SCALE,
  },
  baseCharacterMaleSkinShadows: {
    fileName: baseCharacterMaleSkinShadowsAsset,
  },
  baseCharacterMaleEyesNormal: {
    fileName: baseCharacterMaleEyesNormalAsset,
  },
  baseCharacterMaleEyesDetermined: {
    fileName: baseCharacterMaleEyesDeterminedAsset,
  },
  baseCharacterMaleEyesCurious: {
    fileName: baseCharacterMaleEyesCuriousAsset,
  },
  baseCharacterMaleEyesCool: {
    fileName: baseCharacterMaleEyesCoolAsset,
  },
  baseCharacterMaleEyesKind: {
    fileName: baseCharacterMaleEyesKindAsset,
  },
  baseCharacterMaleEyesBeautiful: {
    fileName: baseCharacterMaleEyesBeautifulAsset,
  },
  baseCharacterMaleEyesSharp: {
    fileName: baseCharacterMaleEyesSharpAsset,
  },
  baseCharacterMaleEyesAlmond: {
    fileName: baseCharacterMaleEyesAlmondAsset,
  },
  baseCharacterMaleEyesFierce: {
    fileName: baseCharacterMaleEyesFierceAsset,
  },
  baseCharacterMaleEyesCat: {
    fileName: baseCharacterMaleEyesCatAsset,
  },
  baseCharacterMaleClothing: {
    fileName: baseCharacterMaleClothingAsset,
  },
  malePantsBase: {
    fileName: malePantsBaseAsset,
  },
  malePantsAssassins: {
    fileName: malePantsAssassinsAsset,
  },
  maleShirtAssassins: {
    fileName: maleShirtAssassinsAsset,
  },
  maleShirtMage: {
    fileName: maleShirtMageAsset,
  },
  maleShirtWarrior: {
    fileName: maleShirtWarriorAsset,
  },
  baseCharacterMaleDefaultRaceTexture: {
    fileName: baseCharacterMaleDefaultRaceTextureAsset,
  },
  baseCharacterMaleOrcTexture: {
    fileName: baseCharacterMaleOrcTextureAsset,
  },
  baseCharacterMoustacheNormal: {
    unwrap: true,
    fileName: baseCharacterMoustacheNormalAsset,
  },
  baseCharacterBeardNormal: {
    unwrap: true,
    fileName: baseCharacterBeardNormalAsset,
  },

  //
  // Animations
  //
  baseCharacterMaleIdleAnimation: {
    fileName: baseCharacterMaleIdleAnimationAsset,
  },
  warriorMaleIdleAnimation: {
    fileName: warriorMaleIdleAnimationAsset,
  },
  rogueMaleIdleAnimation: {
    fileName: rogueMaleIdleAnimationAsset,
  },
  mageMaleIdleAnimation: {
    fileName: mageMaleIdleAnimationAsset,
  },
  baseCharacterMaleWalkAnimation: {
    fileName: baseCharacterMaleWalkAnimationAsset,
  },
  baseCharacterMaleJumpAnimation: {
    fileName: baseCharacterMaleJumpAnimationAsset,
  },
  baseCharacterMaleDeathAnimation: {
    fileName: baseCharacterMaleDeathAnimationAsset,
  },
  baseCharacterMaleHorizontalSlashAnimation: {
    fileName: baseCharacterMaleHorizontalSlashAnimationAsset,
  },
  baseCharacterMaleAttack360Animation: {
    fileName: baseCharacterMaleAttack360AnimationAsset,
  },
  baseCharacterMaleVerticalSlashAnimation: {
    fileName: baseCharacterMaleVerticalSlashAnimationAsset,
  },
} as const;

const BaseFemaleCharacterAssetMap = {
  baseCharacterFemale: {
    scale: CHARACTER_SCALE,
    fileName: baseCharacterFemaleAsset,
  },
  baseCharacterFemaleSkinShadows: {
    fileName: baseCharacterFemaleSkinShadowsAsset,
  },
  baseCharacterFemaleEyesNormal: {
    fileName: baseCharacterFemaleEyesNormalAsset,
  },
  baseCharacterFemaleEyesDetermined: {
    fileName: baseCharacterFemaleEyesDeterminedAsset,
  },
  baseCharacterFemaleEyesCurious: {
    fileName: baseCharacterFemaleEyesCuriousAsset,
  },
  baseCharacterFemaleEyesCool: {
    fileName: baseCharacterFemaleEyesCoolAsset,
  },
  baseCharacterFemaleEyesKind: {
    fileName: baseCharacterFemaleEyesKindAsset,
  },
  baseCharacterFemaleEyesBeautiful: {
    fileName: baseCharacterFemaleEyesBeautifulAsset,
  },
  baseCharacterFemaleEyesSharp: {
    fileName: baseCharacterFemaleEyesSharpAsset,
  },
  baseCharacterFemaleEyesAlmond: {
    fileName: baseCharacterFemaleEyesAlmondAsset,
  },
  baseCharacterFemaleEyesFierce: {
    fileName: baseCharacterFemaleEyesFierceAsset,
  },
  baseCharacterFemaleEyesCat: {
    fileName: baseCharacterFemaleEyesCatAsset,
  },
  baseCharacterFemaleClothing: {
    fileName: baseCharacterFemaleClothingAsset,
  },
  femalePantsBase: {
    fileName: femalePantsBaseAsset,
  },
  femaleShirtBase: {
    fileName: femaleShirtBaseAsset,
  },
  femalePantsAssassins: {
    fileName: femalePantsAssassinsAsset,
  },
  femaleShirtAssassins: {
    fileName: femaleShirtAssassinsAsset,
  },
  femaleShirtMage: {
    fileName: femaleShirtMageAsset,
  },
  femaleShirtWarrior: {
    fileName: femaleShirtWarriorAsset,
  },
  baseCharacterFemaleDefaultRaceTexture: {
    fileName: baseCharacterFemaleDefaultRaceTextureAsset,
  },
  baseCharacterFemaleOrcTexture: {
    fileName: baseCharacterFemaleOrcTextureAsset,
  },

  //
  // Animations
  //
  baseCharacterFemaleIdleAnimation: {
    fileName: baseCharacterFemaleIdleAnimationAsset,
  },
  warriorFemaleIdleAnimation: {
    fileName: warriorFemaleIdleAnimationAsset,
  },
  rogueFemaleIdleAnimation: {
    fileName: rogueFemaleIdleAnimationAsset,
  },
  mageFemaleIdleAnimation: {
    fileName: mageFemaleIdleAnimationAsset,
  },
  baseCharacterFemaleWalkAnimation: {
    fileName: baseCharacterFemaleWalkAnimationAsset,
  },
  baseCharacterFemaleJumpAnimation: {
    fileName: baseCharacterFemaleJumpAnimationAsset,
  },
  baseCharacterFemaleDeathAnimation: {
    fileName: baseCharacterFemaleDeathAnimationAsset,
  },
  baseCharacterFemaleHorizontalSlashAnimation: {
    fileName: baseCharacterFemaleHorizontalSlashAnimationAsset,
  },
  baseCharacterFemaleAttack360Animation: {
    fileName: baseCharacterFemaleAttack360AnimationAsset,
  },
  baseCharacterFemaleVerticalSlashAnimation: {
    fileName: baseCharacterFemaleVerticalSlashAnimationAsset,
  },
};

const CommonBaseCharacterAssetMap = {
  baseCharacterHairBaseNormal: {
    unwrap: true,
    fileName: baseCharacterHairBaseNormalAsset,
  },
  baseCharacterHairBaseMohawk: {
    unwrap: true,
    fileName: baseCharacterHairBaseMohawkAsset,
  },
  baseCharacterHairBaseUndercut: {
    unwrap: true,
    fileName: baseCharacterHairBaseUndercutAsset,
  },
  baseCharacterHairBaseSpiky: {
    unwrap: true,
    fileName: baseCharacterHairBaseSpikyAsset,
  },
  baseCharacterHairBackShort: {
    unwrap: true,
    fileName: baseCharacterHairBackShortAsset,
  },
  baseCharacterHairBackLong: {
    unwrap: true,
    fileName: baseCharacterHairBackLongAsset,
  },
  baseCharacterHairFringeNormal: {
    unwrap: true,
    fileName: baseCharacterHairFringeNormalAsset,
  },
  baseCharacterHairFringeStraight: {
    unwrap: true,
    fileName: baseCharacterHairFringeStraightAsset,
  },
  baseCharacterHairFringeMiddlePart: {
    unwrap: true,
    fileName: baseCharacterHairFringeMiddlePartAsset,
  },
  baseCharacterHairFringeMiddlePartThick: {
    unwrap: true,
    fileName: baseCharacterHairFringeMiddlePartThickAsset,
  },
  baseCharacterHairFringeStraightBangs: {
    unwrap: true,
    fileName: baseCharacterHairFringeStraightBangsAsset,
  },
  baseCharacterHairSideNormal: {
    unwrap: true,
    fileName: baseCharacterHairSideNormalAsset,
  },
  baseCharacterHairTailPonyTail: {
    unwrap: true,
    fileName: baseCharacterHairTailPonyTailAsset,
  },
  baseCharacterHairTailTwin: {
    unwrap: true,
    fileName: baseCharacterHairTailTwinAsset,
  },
  baseCharacterHairTailDoubleBun: {
    unwrap: true,
    fileName: baseCharacterHairTailDoubleBun,
  },
  baseCharacterEarsHuman: {
    unwrap: true,
    fileName: baseCharacterEarsHumanAsset,
  },
  baseCharacterEarsElf: {
    unwrap: true,
    fileName: baseCharacterEarsElfAsset,
  },
  baseCharacterEarsOrc: {
    unwrap: true,
    fileName: baseCharacterEarsOrcAsset,
  },
};

export const BaseCharacterAssetMap = {
  ...BaseMaleCharacterAssetMap,
  ...BaseFemaleCharacterAssetMap,
  ...CommonBaseCharacterAssetMap,
} as const;
