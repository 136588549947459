import { BaseCharacterMeshConfig } from "mmo-common";

export type BaseCharacterConfigChanges = Partial<{
  [k in keyof BaseCharacterMeshConfig]: {
    oldValue: BaseCharacterMeshConfig[k];
    newValue: BaseCharacterMeshConfig[k];
  };
}>;

/**
 * Gets the changes between two character configs
 */
export function getBaseCharacterConfigChanges(
  oldConfig: BaseCharacterMeshConfig,
  newConfig: BaseCharacterMeshConfig
) {
  const changes: BaseCharacterConfigChanges = {};
  for (const [k, v] of Object.entries(oldConfig)) {
    const field = k as keyof BaseCharacterMeshConfig;
    const oldValue =
      v as BaseCharacterMeshConfig[keyof BaseCharacterMeshConfig];
    if (newConfig[field] !== oldValue) {
      changes[field] = {
        oldValue,
        newValue: newConfig[field],
      } as any;
    }
  }
  return changes;
}
