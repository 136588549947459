import { CreatureAnimation, MMONetSchema, PlayerState } from "mmo-common";
import { NetworkSlave } from "realms-engine-network-client";
import { CharacterGameObject } from "./character.gameObject";
import { FloatingWeaponGameObject } from "../floating-weapon/floatingWeapon.gameObject";
import {
  TimerGameObject,
  quatToVector2D,
  vector2Multiply,
  vector3Subtract,
} from "realms-engine";
import { toThreeQuaternion } from "realms-engine-browser";
import THREE from "realms-three";

const SHOW_FLOATING_WEAPON_WAIT_TIME = 3000;

export interface PlayerGameObjectEventsMap {
  inCombatChange: { inCombat: boolean };
}

/*
 * Manages the player state
 */
export class PlayerGameObject
  extends CharacterGameObject<PlayerGameObjectEventsMap>
  implements NetworkSlave<MMONetSchema["TMap"]["character"]>
{
  clientId: string;
  characterId: number;
  inRangeInteractableIds: number[];

  private floatingWeapon: FloatingWeaponGameObject;
  protected playerState: PlayerState;
  private showFloatingWeaponTimer: TimerGameObject;
  private showingFloatingWeapon: boolean;

  constructor(state: PlayerState, options: { shouldHideUI?: boolean } = {}) {
    super(state, options);
    this.clientId = state.clientId;
    this.characterId = state.characterId;
    this.inRangeInteractableIds = state.inRangeInteractableIds;
    this.playerState = state;
    this.floatingWeapon = this.addChild(
      new FloatingWeaponGameObject({
        weapon: this.playerState.character.weapon,
      })
    );

    const shouldShowFloatingWeapon = this.shouldShowFloatingWeapon(
      state.creature.animation.name
    );
    this.showingFloatingWeapon = shouldShowFloatingWeapon;
    this.characterGameObject.setWeaponVisibility(!shouldShowFloatingWeapon);
    this.floatingWeapon.setWeaponVisibility(shouldShowFloatingWeapon);
    this.showFloatingWeaponTimer = this.addChild(new TimerGameObject());
    this.showFloatingWeaponTimer.addListener(
      "onFinish",
      this.onShowFloatingWeaponTimerEnd
    );
  }

  getCharacterId() {
    return this.characterId;
  }

  getPlayerState(): Readonly<PlayerState> {
    return this.playerState;
  }

  private onShowFloatingWeaponTimerEnd = () => {
    this.showFloatingWeapon(true);
  };

  override onSlaveChanges(
    changes: Partial<PlayerState>,
    newState: PlayerState
  ): void {
    this.playerState = newState;
    if (changes.clientId !== undefined) {
      this.clientId = newState.clientId;
    }

    if (changes.inCombat !== undefined) {
      this.dispatch("inCombatChange", {
        inCombat: changes.inCombat,
      });
    }

    if (changes.characterId !== undefined) {
      this.characterId = newState.characterId;
    }

    if (changes.inRangeInteractableIds !== undefined) {
      this.inRangeInteractableIds = changes.inRangeInteractableIds;
    }

    if (changes.character?.weapon !== undefined) {
      this.floatingWeapon.setWeapon(newState.character.weapon);
    }

    if (changes.creature?.animation !== undefined) {
      const shouldShowFloatingWeapon = this.shouldShowFloatingWeapon(
        changes.creature.animation.name
      );

      if (
        shouldShowFloatingWeapon &&
        !this.showFloatingWeaponTimer.isRunning()
      ) {
        this.showFloatingWeaponTimer.start(SHOW_FLOATING_WEAPON_WAIT_TIME);
      }

      // Hide showing floating weapon
      if (!shouldShowFloatingWeapon) {
        this.showFloatingWeaponTimer.stop();
        this.showFloatingWeapon(false);
      }
    }

    if (
      changes.creature?.position !== undefined ||
      changes.creature?.quaternion !== undefined
    ) {
      const weaponOffset = 2;
      const weaponDisplacement = vector2Multiply(
        quatToVector2D(newState.creature.quaternion),
        weaponOffset
      );
      const target = vector3Subtract(newState.creature.position, {
        x: weaponDisplacement.x,
        y: 0,
        z: weaponDisplacement.y,
      });
      this.floatingWeapon.setTarget(target);
      const weaponQuat = toThreeQuaternion(newState.creature.quaternion);
      weaponQuat.multiply(
        new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 0, 1),
          Math.PI / 8
        )
      );
      weaponQuat.multiply(
        new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(1, 0, 0),
          -Math.PI / 10
        )
      );
      this.floatingWeapon.setQuaternion(weaponQuat);
    }

    super.onSlaveChanges(changes, newState);
  }

  private showFloatingWeapon(show: boolean) {
    // No change so do nothing
    if (show === this.showingFloatingWeapon) {
      return;
    }

    const DELAY = show ? 800 : 0;

    this.floatingWeapon.setWeaponVisibility(show, { delay: DELAY });
    this.characterGameObject.setWeaponVisibility(!show);
    this.showingFloatingWeapon = show;
  }

  private shouldShowFloatingWeapon(animation: string) {
    return !(
      [
        CreatureAnimation.NormalAttack1,
        CreatureAnimation.NormalAttack2,
        CreatureAnimation.NormalAttack3,
      ] as string[]
    ).includes(animation);
  }
}
