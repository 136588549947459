import { MMOAssetManager } from "@game/config";
import { hexToRgb } from "realms-utils";
import { processImage } from "../player/characterUtils";
import {
  VARIABLE_TEXTURE_COLOR,
  VARIABLE_TEXTURE_SECONDARY_COLOR,
} from "../player/constants";
import { AssetNames } from "./mapping";

const INTERVAL_DELIMITER = "?";
const RANGE_DELIMITER = "!";
const BASE_ENCODING = 36;

/**
 * Decompress Pixel Indexes
 */
export const decompressPixelIndexes = (compressedPixels: string): number[] => {
  return compressedPixels
    .split(INTERVAL_DELIMITER)
    .flatMap((compressedPixel) => {
      const [start, end] = compressedPixel.split(RANGE_DELIMITER);
      // Only one pixel
      if (end === undefined) {
        return parseInt(start, BASE_ENCODING) * 4;
      }
      const startPixel = parseInt(start, BASE_ENCODING) * 4;
      const endPixel = parseInt(end, BASE_ENCODING) * 4;
      const pixels: number[] = [];
      let i = startPixel;
      while (i <= endPixel) {
        pixels.push(i);
        i += 4;
      }
      return pixels;
    });
};

/**
 * Compresses the pixel indexes
 */
export const compressPixelIndexes = (pixels: number[]): string => {
  const sortedPixels = [...pixels].sort();
  const variablePixelIndexes: string[] = [];
  let interval: { start: number; end: number } | null = null;
  for (let i = 0; i < sortedPixels.length; i++) {
    // Divide it by 4 since it is RGBA
    const pixel = sortedPixels[i] / 4;

    // No interval so start a new interval
    if (interval === null) {
      interval = { start: pixel, end: pixel };
      continue;
    }

    // Last Interval already exists and we can increase it
    if (pixel - interval.end === 1) {
      interval.end = pixel;
      continue;
    }

    // Last Interval ended
    variablePixelIndexes.push(
      interval.start === interval.end
        ? interval.start.toString(BASE_ENCODING)
        : `${interval.start.toString(
            BASE_ENCODING
          )}${RANGE_DELIMITER}${interval.end.toString(BASE_ENCODING)}`
    );

    // Start new interval with current pixel
    interval.start = pixel;
    interval.end = pixel;
  }

  // Add the final interval as it is skipped on last go
  if (interval !== null) {
    variablePixelIndexes.push(
      interval.start === interval.end
        ? interval.start.toString(BASE_ENCODING)
        : `${interval.start.toString(
            BASE_ENCODING
          )}${RANGE_DELIMITER}${interval.end.toString(BASE_ENCODING)}`
    );
  }

  return variablePixelIndexes.join(INTERVAL_DELIMITER);
};

/**
 * Generates the metadata of a texture
 */
export const getTextureVariableIndexes = (key: AssetNames) => {
  const targetPixels: number[] = [];
  processImage(
    MMOAssetManager.getTexture(key, {
      clone: true,
    }).image,
    ({ r, g, b, a, i }) => {
      const rgbVariableColor = hexToRgb(VARIABLE_TEXTURE_COLOR);
      if (
        r === rgbVariableColor.r &&
        g === rgbVariableColor.g &&
        b === rgbVariableColor.b
      ) {
        targetPixels.push(i);
      }
      const rgbVariableSecondaryColor = hexToRgb(
        VARIABLE_TEXTURE_SECONDARY_COLOR
      );
      if (
        r === rgbVariableSecondaryColor.r &&
        g === rgbVariableSecondaryColor.g &&
        b === rgbVariableSecondaryColor.b
      ) {
        targetPixels.push(i);
      }
      return { r, g, b, a };
    }
  );
  return targetPixels;
};
