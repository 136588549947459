import { Vector3 } from "realms-engine";
import { MMOAssetManager } from "../../config";
import {
  Weapon,
  BaseCharacterGender,
  BaseCharacterEyes,
  BaseCharacterClothing,
  BaseCharacterHairBase,
  BaseCharacterHairBack,
  BaseCharacterHairFringe,
  BaseCharacterHairSide,
  BaseCharacterHairTail,
  BaseCharacterRace,
  BaseCharacterMoustache,
  BaseCharacterBeard,
  BaseCharacterClass,
  CreatureAnimation,
  BaseCharacterPants,
  BaseCharacterShirt,
} from "mmo-common";
import warriorShieldImg from "@assets/warrior_shield.png";
import mageStaffImg from "@assets/mage_staff.png";
import rogueMaskImg from "@assets/rogue_mask.png";

export type AssetNames = Parameters<(typeof MMOAssetManager)["getTexture"]>[0];

export const WEAPON_ASSET_MAPPING: {
  [w in Exclude<Weapon, Weapon.None>]: {
    asset: AssetNames;
    transformation: {
      [g in BaseCharacterGender]: {
        position: Vector3;
        rotation: Vector3;
      };
    };
  };
} = {
  [Weapon.Sword]: {
    asset: "sword",
    transformation: {
      [BaseCharacterGender.Male]: {
        position: {
          x: -650,
          y: 300,
          z: 20,
        },
        rotation: {
          x: 0,
          y: Math.PI / 2,
          z: 0,
        },
      },
      [BaseCharacterGender.Female]: {
        position: {
          x: -650,
          y: 300,
          z: 110,
        },
        rotation: {
          x: 0,
          y: Math.PI / 2,
          z: 0,
        },
      },
    },
  },
  [Weapon.Dagger]: {
    asset: "dagger",
    transformation: {
      [BaseCharacterGender.Male]: {
        position: {
          x: 650,
          y: 300,
          z: 20,
        },
        rotation: {
          x: 0,
          y: -Math.PI / 2,
          z: 0,
        },
      },
      [BaseCharacterGender.Female]: {
        position: {
          x: 650,
          y: 300,
          z: 110,
        },
        rotation: {
          x: 0,
          y: -Math.PI / 2,
          z: 0,
        },
      },
    },
  },
  [Weapon.Staff]: {
    asset: "staff",
    transformation: {
      [BaseCharacterGender.Male]: {
        position: {
          x: -2350,
          y: 300,
          z: 20,
        },
        rotation: {
          x: 0,
          y: Math.PI / 2,
          z: 0,
        },
      },
      [BaseCharacterGender.Female]: {
        position: {
          x: -2350,
          y: 300,
          z: 110,
        },
        rotation: {
          x: 0,
          y: Math.PI / 2,
          z: 0,
        },
      },
    },
  },
};

export const BASE_CHARACTER_ASSET_MAPPING: {
  gendered: {
    [g in BaseCharacterGender]: {
      eyes: {
        [v in BaseCharacterEyes]: AssetNames;
      };
      skinShadow: AssetNames;
      clothing: {
        [v in Exclude<
          BaseCharacterClothing,
          BaseCharacterClothing.None
        >]: AssetNames;
      };
      pants: {
        [v in Exclude<BaseCharacterPants, BaseCharacterPants.None>]: AssetNames;
      };
      defaultPants: AssetNames;
      defaultShirt: AssetNames | null;
      shirt: {
        [v in Exclude<BaseCharacterShirt, BaseCharacterShirt.None>]: AssetNames;
      };
    };
  };
  common: {
    hairBase: {
      [v in Exclude<
        BaseCharacterHairBase,
        BaseCharacterHairBase.None
      >]: AssetNames;
    };
    hairBack: {
      [v in Exclude<
        BaseCharacterHairBack,
        BaseCharacterHairBack.None
      >]: AssetNames;
    };
    hairFringe: {
      [v in Exclude<
        BaseCharacterHairFringe,
        BaseCharacterHairFringe.None
      >]: AssetNames;
    };
    hairSide: {
      [v in Exclude<
        BaseCharacterHairSide,
        BaseCharacterHairSide.None
      >]: AssetNames;
    };
    hairTail: {
      [v in Exclude<
        BaseCharacterHairTail,
        BaseCharacterHairTail.None
      >]: AssetNames;
    };
    ears: {
      [r in BaseCharacterRace]: AssetNames;
    };
    raceTexture: {
      [r in BaseCharacterRace]:
        | {
            [g in BaseCharacterGender]: AssetNames;
          }
        | null;
    };
    moustache: {
      [v in Exclude<
        BaseCharacterMoustache,
        BaseCharacterMoustache.None
      >]: AssetNames;
    };
    beard: {
      [v in Exclude<BaseCharacterBeard, BaseCharacterBeard.None>]: AssetNames;
    };
  };
} = {
  gendered: {
    [BaseCharacterGender.Male]: {
      eyes: {
        [BaseCharacterEyes.Normal]: "baseCharacterMaleEyesNormal",
        [BaseCharacterEyes.Determined]: "baseCharacterMaleEyesDetermined",
        [BaseCharacterEyes.Curious]: "baseCharacterMaleEyesCurious",
        [BaseCharacterEyes.Cool]: "baseCharacterMaleEyesCool",
        [BaseCharacterEyes.Kind]: "baseCharacterMaleEyesKind",
        [BaseCharacterEyes.Beautiful]: "baseCharacterMaleEyesBeautiful",
        [BaseCharacterEyes.Sharp]: "baseCharacterMaleEyesSharp",
        [BaseCharacterEyes.Almond]: "baseCharacterMaleEyesAlmond",
        [BaseCharacterEyes.Fierce]: "baseCharacterMaleEyesFierce",
        [BaseCharacterEyes.Cat]: "baseCharacterMaleEyesCat",
      },
      skinShadow: "baseCharacterMaleSkinShadows",
      clothing: {
        [BaseCharacterClothing.Normal]: "baseCharacterMaleClothing",
      },
      pants: {
        [BaseCharacterPants.Assassins]: "malePantsAssassins",
      },
      defaultPants: "malePantsBase",
      shirt: {
        [BaseCharacterShirt.Assassins]: "maleShirtAssassins",
        [BaseCharacterShirt.Mage]: "maleShirtMage",
        [BaseCharacterShirt.Warrior]: "maleShirtWarrior",
      },
      defaultShirt: null,
    },
    [BaseCharacterGender.Female]: {
      eyes: {
        [BaseCharacterEyes.Normal]: "baseCharacterFemaleEyesNormal",
        [BaseCharacterEyes.Determined]: "baseCharacterFemaleEyesDetermined",
        [BaseCharacterEyes.Curious]: "baseCharacterFemaleEyesCurious",
        [BaseCharacterEyes.Cool]: "baseCharacterFemaleEyesCool",
        [BaseCharacterEyes.Kind]: "baseCharacterFemaleEyesKind",
        [BaseCharacterEyes.Beautiful]: "baseCharacterFemaleEyesBeautiful",
        [BaseCharacterEyes.Sharp]: "baseCharacterFemaleEyesSharp",
        [BaseCharacterEyes.Almond]: "baseCharacterFemaleEyesAlmond",
        [BaseCharacterEyes.Fierce]: "baseCharacterFemaleEyesFierce",
        [BaseCharacterEyes.Cat]: "baseCharacterFemaleEyesCat",
      },
      skinShadow: "baseCharacterFemaleSkinShadows",
      clothing: {
        [BaseCharacterClothing.Normal]: "baseCharacterFemaleClothing",
      },
      pants: {
        [BaseCharacterPants.Assassins]: "femalePantsAssassins",
      },
      defaultPants: "femalePantsBase",
      shirt: {
        [BaseCharacterShirt.Assassins]: "femaleShirtAssassins",
        [BaseCharacterShirt.Mage]: "femaleShirtMage",
        [BaseCharacterShirt.Warrior]: "femaleShirtWarrior",
      },
      defaultShirt: "femaleShirtBase",
    },
  },
  common: {
    hairBase: {
      [BaseCharacterHairBase.Normal]: "baseCharacterHairBaseNormal",
      [BaseCharacterHairBase.Mohawk]: "baseCharacterHairBaseMohawk",
      [BaseCharacterHairBase.Undercut]: "baseCharacterHairBaseUndercut",
      [BaseCharacterHairBase.Spiky]: "baseCharacterHairBaseSpiky",
    },
    hairBack: {
      [BaseCharacterHairBack.Short]: "baseCharacterHairBackShort",
      [BaseCharacterHairBack.Long]: "baseCharacterHairBackLong",
    },
    hairFringe: {
      [BaseCharacterHairFringe.Normal]: "baseCharacterHairFringeNormal",
      [BaseCharacterHairFringe.Straight]: "baseCharacterHairFringeStraight",
      [BaseCharacterHairFringe.MiddlePart]: "baseCharacterHairFringeMiddlePart",
      [BaseCharacterHairFringe.MiddlePartThick]:
        "baseCharacterHairFringeMiddlePartThick",
      [BaseCharacterHairFringe.StraightBangs]:
        "baseCharacterHairFringeStraightBangs",
    },
    hairSide: {
      [BaseCharacterHairSide.Normal]: "baseCharacterHairSideNormal",
    },
    hairTail: {
      [BaseCharacterHairTail.Ponytail]: "baseCharacterHairTailPonyTail",
      [BaseCharacterHairTail.Twin]: "baseCharacterHairTailTwin",
      [BaseCharacterHairTail.DoubleBun]: "baseCharacterHairTailDoubleBun",
    },
    ears: {
      [BaseCharacterRace.Human]: "baseCharacterEarsHuman",
      [BaseCharacterRace.Elf]: "baseCharacterEarsElf",
      [BaseCharacterRace.Orc]: "baseCharacterEarsOrc",
    },
    raceTexture: {
      [BaseCharacterRace.Human]: {
        [BaseCharacterGender.Male]: "baseCharacterMaleDefaultRaceTexture",
        [BaseCharacterGender.Female]: "baseCharacterFemaleDefaultRaceTexture",
      },
      [BaseCharacterRace.Elf]: {
        [BaseCharacterGender.Male]: "baseCharacterMaleDefaultRaceTexture",
        [BaseCharacterGender.Female]: "baseCharacterFemaleDefaultRaceTexture",
      },
      [BaseCharacterRace.Orc]: {
        [BaseCharacterGender.Male]: "baseCharacterMaleOrcTexture",
        [BaseCharacterGender.Female]: "baseCharacterFemaleOrcTexture",
      },
    },
    moustache: {
      [BaseCharacterMoustache.Normal]: "baseCharacterMoustacheNormal",
    },
    beard: {
      [BaseCharacterBeard.Normal]: "baseCharacterBeardNormal",
    },
  },
};

export const CLASS_ANIMATION_MAPPING: {
  [characterClass in BaseCharacterClass]: {
    [animation in CreatureAnimation]?: string;
  };
} = {
  [BaseCharacterClass.Warrior]: {
    idle: "warriorIdle",
  },
  [BaseCharacterClass.Rogue]: {
    idle: "rogueIdle",
  },
  [BaseCharacterClass.Mage]: {
    idle: "mageIdle",
  },
};

export const CLASS_ICON_MAPPING: {
  [characterClass in BaseCharacterClass]: { icon: string };
} = {
  [BaseCharacterClass.Warrior]: {
    icon: warriorShieldImg,
  },
  [BaseCharacterClass.Rogue]: {
    icon: rogueMaskImg,
  },
  [BaseCharacterClass.Mage]: {
    icon: mageStaffImg,
  },
};
