import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";
import { MMOTheme } from "./theme";
import { AuthProvider } from "realmsbase-sdk";
import { Suspense } from "react";
import { ServiceProvider } from "./services";
import { getEnvironment } from "realms-react-utils";
import { ROUTER } from "./routes";
import { SplashScreen } from "@capacitor/splash-screen";
import { LoadingScreen } from "@utils/loading/LoadingScreen";

// Hide splash screen on ios
SplashScreen.hide();

console.log(`Arcadian Realms ${getEnvironment()} mode`);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ChakraProvider theme={MMOTheme}>
    <AuthProvider>
      <Suspense fallback={<LoadingScreen />}>
        <ServiceProvider>
          <RouterProvider router={ROUTER} />
        </ServiceProvider>
      </Suspense>
    </AuthProvider>
  </ChakraProvider>
);
