"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.gameObjectSchema = void 0;
var realms_utils_1 = require("realms-utils");
exports.gameObjectSchema = __assign({ id: "number", type: "function", getName: "function", getGroups: "function", addGroup: "function", log: "function", removeFromGroup: "function", addChildWithName: "function", addChild: "function", getChildByName: "function", removeChildByName: "function", removeChild: "function", queueRemoveChild: "function", getAllChildren: "function", getParent: "function", getGameObjectFamily: "function", removeFromParent: "function", queueRemoveFromParent: "function", queueAtStepEnd: "function", getScene: "function", isInScene: "function", _setScene: "function", getAllInheritedTypes: "function", onInit: "function", onRemove: "function" }, realms_utils_1.eventEmitterSchema);
