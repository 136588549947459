import { useEffect, useState } from "react";
import { MMOAssetManager } from "../game";
import { useLoading } from "@utils/loading/useLoading";

interface AssetGuardProps {
  children: any;
}

export const AssetGuard = ({ children }: AssetGuardProps) => {
  const { createLoadingTask, endLoadingTask, updateLoadingTask } = useLoading();
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  useEffect(() => {
    const taskId = createLoadingTask({
      type: "progress",
      value: 0,
      max: 100,
      text: "Loading Assets",
    });

    const hasLoaded = MMOAssetManager.hasLoaded();
    setAssetsLoaded(hasLoaded);

    if (!hasLoaded) {
      MMOAssetManager.loadAssets(
        (state) => {
          updateLoadingTask(taskId, (task) => ({
            ...task,
            max: state.assetsToLoad,
            value: state.assetsLoaded,
            text: `${state.assetsLoaded} / ${state.assetsToLoad} assets loaded`,
          }));
        },
        () => {
          endLoadingTask(taskId);
          setAssetsLoaded(true);
        }
      );
      return;
    }
    endLoadingTask(taskId);
  }, [createLoadingTask, endLoadingTask, updateLoadingTask]);

  if (assetsLoaded) {
    return children;
  }

  return null;
};
