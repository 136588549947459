import {
  GoblinClothing,
  GoblinFeatures,
  GoblinSkin,
  MMONetSchema,
  PHYSICS_GRAVITY,
} from "mmo-common";
import { Physics3DModule, Realm } from "realms-engine";
import { Render3DSystem, TrackingCameraSystem } from "realms-engine-browser";
import {
  createNetworkPhysicsOutlineFactoryMap,
  createPhysicsPredictionFactoryMap,
  NetworkPhysicsOutlineSystem,
  NetworkSlaveSystem,
  PhysicsPredictionSystem,
  RealmsEngineClient,
} from "realms-engine-network-client";
import THREE from "realms-three";
import { setUpScene } from "./setUpScene";
import { getMMOClientFeatureFlags } from "../../mmoClientFeatureFlags";
import { PlayerGameObject, SlaveShapeGameObject } from "../gameObjects";
import { InteractableSystem, PlayerSystem } from "../systems";
import { SlaveInteractableGameObject } from "../gameObjects/interactable/slaveInteractable.gameObject";
import { DEFAULT_SHOW_PHYSICS_OUTLINE, OUTLINE_COLOR } from "../../configs";
import { GoblinGameObject } from "@game/gameObjects/goblin/goblinGameObject";
import { CharacterGameObject } from "@game/gameObjects/player/character.gameObject";
import { SelfPlayerGameObject } from "@game/gameObjects/player/selfPlayer.gameObject";

interface CreateRealmProps {
  client: RealmsEngineClient<MMONetSchema>;
  canvas: HTMLCanvasElement;
  width: number;
  height: number;
  characterId: number;
}

export function createRealmsCamera(
  width: number,
  height: number,
  fov?: number
) {
  return new THREE.PerspectiveCamera(fov ?? 45, width / height, 0.1, 1000);
}

export function createRealm({
  client,
  canvas,
  width,
  height,
  characterId,
}: CreateRealmProps) {
  const camera = createRealmsCamera(width, height);

  return new Realm([
    // Syncs with server
    new NetworkSlaveSystem<MMONetSchema>(client, {
      ...createNetworkPhysicsOutlineFactoryMap(),
      ...createPhysicsPredictionFactoryMap(),
      player: (state, realm) => {
        if (
          realm.systems.get(PlayerSystem).getSelfCharacterId() ===
          state.characterId
        ) {
          return new SelfPlayerGameObject(state);
        }
        return new PlayerGameObject(state);
      },
      character: (state) => new CharacterGameObject(state),
      goblin: (state) =>
        new GoblinGameObject(
          {
            skin: GoblinSkin.Green,
            features: GoblinFeatures.Fierce,
            clothing: GoblinClothing.Armoured,
          },
          state
        ),
      shape: (state) => new SlaveShapeGameObject(state),
      interactable: (state) => new SlaveInteractableGameObject(state),
    }),

    new Physics3DModule({ gravity: PHYSICS_GRAVITY }),

    new PhysicsPredictionSystem(),

    // Displays physics outline
    ...(getMMOClientFeatureFlags().physicsOutline
      ? [
          new NetworkPhysicsOutlineSystem({
            visibility: DEFAULT_SHOW_PHYSICS_OUTLINE,
          }),
        ]
      : []),

    new PlayerSystem(characterId),

    new InteractableSystem(),

    // Follows player
    new TrackingCameraSystem(camera, {
      enableTargetDamping: true,
      targetDampingSmoothTime: 200,
      targetDampingMaxSpeed: 100,
    }).addDependency(PlayerSystem),

    // Renders threejs
    new Render3DSystem({
      camera,
      // Set up the three js scene
      setUpScene,
      canvas,
      width,
      height,
      outlineColor: OUTLINE_COLOR,
    })
      .addDependency(NetworkSlaveSystem)
      .addDependency(TrackingCameraSystem),
  ]);
}
