import {
  BaseCharacterState,
  CreatureAnimation,
  MMONetSchema,
} from "mmo-common";
import { GameObject, Quat, Vector3, vector3Add } from "realms-engine";
import { NetworkSlave } from "realms-engine-network-client";
import {
  SlashEffectColor,
  SlashEffectGameObject,
  SlashEffectType,
} from "../effects/slashEffect/SlashEffect.gameObject";
import { threeToVector3, toThreeQuaternion } from "realms-engine-browser";
import THREE from "realms-three";
import { CreatureUIGameObject } from "../ui/creatureUI.gameObject";
import { BaseCharacterGameObject } from "../base-character/baseCharacter.gameObject";

export class CharacterGameObject<T extends {} = {}>
  extends GameObject<T>
  implements NetworkSlave<MMONetSchema["TMap"]["character"]>
{
  protected characterGameObject: BaseCharacterGameObject;
  private shouldHideUI: boolean;
  private ui: CreatureUIGameObject;
  private physicsGameObjectId: number;

  constructor(
    state: BaseCharacterState,
    options: { shouldHideUI?: boolean } = {}
  ) {
    super();

    this.physicsGameObjectId = state.physicsGameObjectId;

    this.characterGameObject = this.addChild(
      new BaseCharacterGameObject({ character: state.character })
    );

    this.ui = this.characterGameObject.addChild(
      new CreatureUIGameObject({
        health: state.creature.health,
        maxHealth: state.creature.maxHealth,
        name: state.creature.name,
      })
    );

    this.shouldHideUI = options.shouldHideUI ?? false;
    this.setUIVisibility(!this.shouldHideUI);
  }

  getUI() {
    return this.ui;
  }

  getPhysicsGameObjectId() {
    return this.physicsGameObjectId;
  }

  onSlaveChanges(
    changes: Partial<BaseCharacterState>,
    newState: BaseCharacterState
  ): void {
    this.physicsGameObjectId = newState.physicsGameObjectId;

    if (changes?.creature?.position !== undefined) {
      this.setCharacterPosition(newState.creature.position);
    }
    if (changes?.creature?.quaternion !== undefined) {
      this.setCharacterQuarternion(newState.creature.quaternion);
    }
    if (changes?.creature?.name !== undefined) {
      this.ui.setCreatureName(newState.creature.name);
    }
    if (changes?.creature?.animation !== undefined) {
      this.characterGameObject.playAnimation(newState.creature.animation);

      // Handle animation
      switch (newState.creature.animation.name) {
        case CreatureAnimation.NormalAttack1: {
          this.createSlashEffect({
            type: SlashEffectType.ArcMedium,
            color: SlashEffectColor.Blue,
            duration: 300,
            delay: 400,
            width: 40,
            height: 40,
            offset: 15,
            heightOffset: 10,
          });
          break;
        }
        case CreatureAnimation.NormalAttack2: {
          this.createSlashEffect({
            type: SlashEffectType.ArcMedium,
            color: SlashEffectColor.Blue,
            duration: 400,
            delay: 600,
            width: 40,
            height: 40,
            offset: 10,
            heightOffset: 10,
            rotation: new THREE.Quaternion().setFromAxisAngle(
              new THREE.Vector3(1, 0, 0),
              Math.PI
            ),
          });
          break;
        }
        case CreatureAnimation.NormalAttack3: {
          // To implement
          break;
        }
      }

      // Hide on death
      const isDead =
        newState.creature.animation.name === CreatureAnimation.Death;
      this.setShouldHideUI(isDead);
    }
    if (changes?.character !== undefined) {
      this.characterGameObject.updateConfig(newState.character);
    }
    if (changes?.creature?.health !== undefined) {
      this.ui.setHealth(newState.creature.health);
    }
    if (changes?.creature?.maxHealth !== undefined) {
      this.ui.setMaxHealth(newState.creature.maxHealth);
    }
  }

  private createSlashEffect({
    type,
    color,
    duration,
    width,
    height,
    delay,
    offset,
    heightOffset,
    reverse,
    rotation,
  }: {
    type: SlashEffectType;
    color: SlashEffectColor;
    duration: number;
    width: number;
    height: number;
    delay: number;
    offset: number;
    heightOffset: number;
    reverse?: boolean;
    rotation?: THREE.Quaternion;
  }) {
    const slash = new SlashEffectGameObject({
      type,
      color,
      duration,
      width,
      height,
      delay,
      reverse,
    });
    const quaternion = toThreeQuaternion(this.getQuaternion())
      .multiply(
        new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          Math.PI
        )
      )
      .multiply(
        new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(1, 0, 0),
          (3 * Math.PI) / 2
        )
      );

    if (rotation) {
      quaternion.multiply(rotation);
    }

    const faceDirection = new THREE.Vector3(1, 0, 0);
    faceDirection.applyQuaternion(quaternion);
    faceDirection.multiplyScalar(offset);

    slash.setQuaternion(quaternion);
    slash.setPosition(
      vector3Add(
        vector3Add(this.getPosition(), { x: 0, y: heightOffset, z: 0 }),
        threeToVector3(faceDirection)
      )
    );
    this.addChild(slash);
  }

  setShouldHideUI(shouldHide: boolean) {
    if (this.shouldHideUI !== shouldHide) {
      this.shouldHideUI = shouldHide;
      this.setUIVisibility(!this.shouldHideUI);
    }
  }

  getHealth() {
    return this.ui.getHealth();
  }

  getMaxHealth() {
    return this.ui.getMaxHealth();
  }

  getPosition() {
    return this.characterGameObject.getPosition();
  }

  protected setCharacterPosition(position: Vector3) {
    this.characterGameObject.setPosition(position);
  }

  protected setCharacterQuarternion(quaternion: Quat) {
    this.characterGameObject.setQuaternion(quaternion);
  }

  getQuaternion() {
    return this.characterGameObject.getQuaternion();
  }

  private setUIVisibility(visible: boolean) {
    this.ui.setShowHealth(visible);
    this.ui.setShowName(visible);
  }
}
