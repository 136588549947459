import { Weapon } from "mmo-common";
import { Vector3 } from "realms-engine";

export const FLOATING_WEAPON_MAPPING: {
  [w in Exclude<Weapon, Weapon.None>]: {
    transformation: {
      position: Vector3;
      rotation: Vector3;
    };
  };
} = {
  [Weapon.Sword]: {
    transformation: {
      position: { x: 0, y: 14, z: -1 },
      rotation: {
        x: Math.PI / 2,
        y: 0,
        z: 0,
      },
    },
  },
  [Weapon.Staff]: {
    transformation: {
      position: { x: 0, y: 14, z: -1 },
      rotation: {
        x: Math.PI / 2,
        y: 0,
        z: 0,
      },
    },
  },
  [Weapon.Dagger]: {
    transformation: {
      position: { x: 0, y: 14, z: -1 },
      rotation: {
        x: Math.PI / 2,
        y: 0,
        z: 0,
      },
    },
  },
};
