import { ALL_SHADERS, Common } from "./patchShaders.const";

/**
 * A reimplementation of patch shaders from gl-noise.
 *
 * Reimplemented because it doesnt work due to broken node imports: https://www.npmjs.com/package/gl-noise
 */
export const patchShaders = (
  /**
   * The shader itself
   */
  shader: string,
  /**
   * All the additional shader functions to add. By default all are added
   */
  chunks: string[] = ALL_SHADERS
) => {
  const headers = Common;
  return "\n" + headers + "\n" + chunks.join("\n") + "\n" + shader;
};
