import { YellowSlashEffectsAssetMap } from "./slashEffects/yellowSlashEffectsAssetMap";
import { PurpleSlashEffectsAssetMap } from "./slashEffects/purpleSlashEffectsAssetMap";
import { BlueSlashEffectsAssetMap } from "./slashEffects/blueSlashEffectsAssetMap";
import { OrangeSlashEffectsAssetMap } from "./slashEffects/orangeSlashEffectsAssetMap";

export const TextureAssetMap = {
  ...YellowSlashEffectsAssetMap,
  ...PurpleSlashEffectsAssetMap,
  ...BlueSlashEffectsAssetMap,
  ...OrangeSlashEffectsAssetMap,
  toonMaterialThreeTone: {
    fileName: "/assets/shaders/threeTone.jpg",
  },
  toonMaterialFourTone: {
    fileName: "/assets/shaders/fourTone.jpg",
  },
  toonMaterialFiveTone: {
    fileName: "/assets/shaders/fiveTone.jpg",
  },
} as const;
