import { CreatureAnimation, PlayerInput, PlayerState } from "mmo-common";
import { PlayerGameObject } from "./player.gameObject";
import {
  PhysicsTranslationForce,
  ProcessGameObject,
  Quat,
  Vector3,
  lerp,
  lerpVector2,
  quatSlerp,
  vector2DToQuat,
  vector2DirectionTo,
  vector2DistanceTo,
  vector2IsZero,
  vector2Multiply,
  vector2Subtract,
  vector3Add,
  vector3ProjectTo2D,
  vector3Subtract,
} from "realms-engine";
import { PhysicsPredictionGameObject } from "realms-engine-network-client";

const VERTICAL_LERP_CONSTANT = 6;
const HORIZONTAL_LERP_CONSTANT = 3;
const QUATERNION_SLERP_CONSTANT = 3;

const MAX_HORIZONTAL_DISTANCE = 20;
const MAX_VERTICAL_DISTANCE = 10;

export class SelfPlayerGameObject extends PlayerGameObject {
  stepObject: ProcessGameObject;
  private movingForce = new PhysicsTranslationForce({ x: 0, y: 0, z: 0 });
  private physicsPredictionGameObject: PhysicsPredictionGameObject | null =
    null;

  constructor(state: PlayerState) {
    super(state, { shouldHideUI: true });
    this.stepObject = this.addChild(new ProcessGameObject({}, this.onStep));
  }

  /**
   * Syncs simulated state with master
   */
  syncWithMaster() {
    this.setPredictionPosition(this.playerState.creature.position);
    this.setPredictionQuaternion(this.playerState.creature.quaternion);
  }

  private onStep = (delta: number) => {
    if (!this.physicsPredictionGameObject) {
      return;
    }

    this.reconcilePosition({ delta });
    this.reconcileQuaternion({ delta });
  };

  private reconcileQuaternion({ delta }: { delta: number }) {
    const predictionQuat = this.getPredictionQuaternion();
    const masterQuat = this.playerState.creature.quaternion;
    const reconciledQuaternion = quatSlerp(
      predictionQuat,
      masterQuat,
      (QUATERNION_SLERP_CONSTANT * delta) / 1000
    );

    this.setPredictionQuaternion(reconciledQuaternion);

    super.setCharacterQuarternion(reconciledQuaternion);
  }

  private reconcilePosition({ delta }: { delta: number }) {
    const predictionPosition = this.getPredictionPosition();
    const masterPosition = this.playerState.creature.position;

    // Reconcile horizontal
    const horizontalDistance = vector2DistanceTo(
      vector3ProjectTo2D(predictionPosition),
      vector3ProjectTo2D(masterPosition)
    );

    const reconciledHorizontalPosition =
      horizontalDistance >= MAX_HORIZONTAL_DISTANCE
        ? vector2Subtract(
            vector3ProjectTo2D(masterPosition),
            vector2Multiply(
              vector2DirectionTo(
                vector3ProjectTo2D(predictionPosition),
                vector3ProjectTo2D(masterPosition)
              ),
              MAX_HORIZONTAL_DISTANCE
            )
          )
        : lerpVector2(
            vector3ProjectTo2D(predictionPosition),
            vector3ProjectTo2D(masterPosition),
            Math.max((HORIZONTAL_LERP_CONSTANT * delta) / 1000, 0.01)
          );

    const verticalDisplacement = masterPosition.y - predictionPosition.y;
    const reconcileVerticalPosition =
      Math.abs(verticalDisplacement) >= MAX_VERTICAL_DISTANCE
        ? masterPosition.y
        : lerp(
            predictionPosition.y,
            masterPosition.y,
            Math.max((VERTICAL_LERP_CONSTANT * delta) / 1000, 0.01)
          );

    const reconciledPosition = {
      x: reconciledHorizontalPosition.x,
      y: reconcileVerticalPosition,
      z: reconciledHorizontalPosition.y,
    };

    this.setPredictionPosition(reconciledPosition);

    // Update character position
    super.setCharacterPosition(reconciledPosition);
  }

  /**
   * Sets the physics prediction object
   */
  setPhysicsPredictionGameObject(
    physicsPredictionGameObject: PhysicsPredictionGameObject
  ) {
    if (this.physicsPredictionGameObject === physicsPredictionGameObject) {
      return;
    }
    this.physicsPredictionGameObject = physicsPredictionGameObject;
    this.physicsPredictionGameObject.setDisableSync(true);
    // Set initial position to master state
    this.setPredictionPosition(this.getPosition());
  }

  /**
   * Simulate move
   */
  simulatePlayerInput(playerInput: PlayerInput) {
    // Initial set up
    if (this.physicsPredictionGameObject === null) {
      return;
    }

    // Update moving force
    if (
      playerInput.move &&
      (
        [
          CreatureAnimation.Idle,
          CreatureAnimation.Walk,
          CreatureAnimation.Jump,
        ] as string[]
      ).includes(this.playerState.creature.animation.name)
    ) {
      this.physicsPredictionGameObject.physicsObject.removeTranslationForce(
        this.movingForce
      );
      this.physicsPredictionGameObject.physicsObject.addTranslationForce(
        this.movingForce
      );
      this.movingForce.force = {
        x: playerInput.move.x * this.playerState.speed,
        y: 0,
        z: playerInput.move.y * this.playerState.speed,
      };
      if (!vector2IsZero(playerInput.move)) {
        this.setPredictionQuaternion(vector2DToQuat(playerInput.move));
      }
    }

    if (
      playerInput.jump &&
      ([CreatureAnimation.Idle, CreatureAnimation.Walk] as string[]).includes(
        this.playerState.creature.animation.name
      )
    ) {
      this.physicsPredictionGameObject.physicsObject.addVelocity({
        x: 0,
        y: this.playerState.jumpVelocity,
        z: 0,
      });
    }

    // Cancel moving on attack
    if (playerInput.attack) {
      this.movingForce.force = {
        x: 0,
        y: 0,
        z: 0,
      };
    }
  }

  private getPredictionQuaternion(): Quat {
    if (!this.physicsPredictionGameObject) {
      return this.playerState.creature.quaternion;
    }
    return this.physicsPredictionGameObject.physicsObject.getQuaternion();
  }

  private setPredictionQuaternion(quat: Quat) {
    if (!this.physicsPredictionGameObject) {
      return;
    }
    this.physicsPredictionGameObject.physicsObject.setQuaternion(quat);
  }

  private getPredictionPosition(): Vector3 {
    if (!this.physicsPredictionGameObject) {
      // Get current position
      return this.playerState.creature.position;
    }
    return vector3Subtract(
      this.physicsPredictionGameObject.physicsObject.getPosition(),
      {
        x: 0,
        // We want to make the position the bottom of the physics body
        y: this.physicsPredictionGameObject.physicsObject.getHeight() * 0.5,
        z: 0,
      }
    );
  }

  private setPredictionPosition(position: Vector3): void {
    if (!this.physicsPredictionGameObject) {
      return;
    }
    this.physicsPredictionGameObject.physicsObject.setPosition(
      vector3Add(position, {
        x: 0,
        y: this.physicsPredictionGameObject.physicsObject.getHeight() * 0.5,
        z: 0,
      })
    );
  }

  protected override setCharacterPosition(position: Vector3): void {}
  protected override setCharacterQuarternion(quaternion: Quat): void {}
}
