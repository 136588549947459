import { Box, BoxProps } from "@chakra-ui/react";
import logoIcon from "@assets/logo-icon.png";
import { ShimmerEffect } from "@pages/character-creation-page/ShimmerEffect";

interface GameLogoProps extends BoxProps {
  sizePX?: number;
  iconGradient?: BoxProps["background"];
  color?: BoxProps["color"];
  hideText?: boolean;
}

export const GameLogo = ({
  sizePX = 250,
  iconGradient = "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(59,9,121,1) 35%, rgba(0,212,255,1) 100%)",
  color = "gray.900",
  hideText = false,
  ...props
}: GameLogoProps) => {
  return (
    <Box
      display={"flex"}
      flexDir={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      fontFamily={"rpgFont"}
      position={"relative"}
      paddingBottom={!hideText ? `${sizePX / 2}px` : undefined}
      {...props}
    >
      <Box
        height={`${sizePX}px`}
        width={`${2 * sizePX}px`}
        position={"relative"}
        style={{
          maskImage: `url(${logoIcon})`,
          maskRepeat: "no-repeat",
          maskSize: "contain",
          maskPosition: "center",
        }}
      >
        <ShimmerEffect />
        <Box width={"100%"} height={"100%"} background={iconGradient} />
      </Box>
      {!hideText ? (
        <Box
          display={"flex"}
          flexDir={"column"}
          alignItems={"center"}
          position={"absolute"}
          top={`${0.75 * sizePX}px`}
          color={color}
        >
          <Box fontSize={`${sizePX / 5}px`} width={"100%"}>
            Arcadian Realms
          </Box>
          <Box
            width={"100%"}
            height={`${sizePX / 100}px`}
            position={"relative"}
            top={`-${sizePX / 20}px`}
            background={iconGradient}
          />
          <Box
            fontSize={`${sizePX / 3}px`}
            position={"relative"}
            top={`-${sizePX / 10}px`}
          >
            ONLINE
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
