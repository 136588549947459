import { threeFindByName, threeGetByName } from "realms-three-utils";
import { threeQuerySelector } from "../../../utils/threeQuerySelector";

export type MixamoBonesMap = ReturnType<typeof buildMixamoBonesMap>;

/*
 * Builds the mixamorig bones map
 */
export function buildMixamoBonesMap(rootObject: THREE.Object3D) {
  const hips = threeGetByName(rootObject, "mixamorigHips");

  const headTop = threeGetByName(hips, "mixamorigHeadTop_End")!;

  const leftLeg = threeQuerySelector("mixamorigLeftUpLeg", hips)!;

  const rightLeg = threeQuerySelector("mixamorigRightUpLeg", hips)!;

  const leftLegChild = threeQuerySelector("mixamorigLeftLeg", leftLeg)!;

  const rightLegChild = threeQuerySelector("mixamorigRightLeg", rightLeg)!;

  const topSpine = threeQuerySelector(
    "mixamorigSpine.mixamorigSpine1.mixamorigSpine2",
    hips
  )!;

  const rightShoulder = threeQuerySelector("mixamorigRightShoulder", topSpine)!;

  const leftShoulder = threeQuerySelector("mixamorigLeftShoulder", topSpine)!;

  const rightHand = threeFindByName(rightShoulder, "mixamorigRightHand")!;

  return {
    hips,
    headTop,
    leftLeg,
    rightLeg,
    leftLegChild,
    rightLegChild,
    topSpine,
    rightShoulder,
    leftShoulder,
    rightHand,
  };
}
