import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import { LoadingScreen } from "./LoadingScreen";

export interface LoadingTask {
  id: string;
  meta:
    | {
        type: "spinner";
        text: string;
      }
    | {
        type: "progress";
        value: number;
        max: number;
        text: string;
      };
}

const TRANSITION_TIME_MS = 1000;
const PROGRESS_BAR_TRANSITION_MS = 300;

const LoadingContext = createContext<{
  tasks: LoadingTask[];
  setTasks: React.Dispatch<React.SetStateAction<LoadingTask[]>>;
}>({ tasks: [], setTasks: () => {} });

export const useLoading = () => {
  const { tasks, setTasks } = useContext(LoadingContext);
  const localLoadingTasksRef = useRef(new Set<string>());

  const isLoading = useMemo(() => tasks.length !== 0, [tasks.length]);

  /**
   * Creates a new loading task and returns the task id
   */
  const createLoadingTask = useCallback(
    (meta: LoadingTask["meta"]): string => {
      const newTask: LoadingTask = {
        id: uuid(),
        meta,
      };
      setTasks((tasks) => [...tasks, newTask]);
      localLoadingTasksRef.current.add(newTask.id);
      return newTask.id;
    },
    [setTasks]
  );

  /**
   * Updates a loading tasks metadata
   */
  const updateLoadingTask = useCallback(
    (
      id: string,
      transformer: (meta: LoadingTask["meta"]) => LoadingTask["meta"]
    ) => {
      setTasks((tasks) =>
        tasks.map((task) =>
          task.id === id ? { ...task, meta: transformer(task.meta) } : task
        )
      );
    },
    [setTasks]
  );

  /**
   * Ends the given loading task
   */
  const endLoadingTask = useCallback(
    (id: string) => {
      localLoadingTasksRef.current.delete(id);
      setTimeout(
        () => setTasks((tasks) => tasks.filter((t) => t.id !== id)),
        PROGRESS_BAR_TRANSITION_MS
      );
    },
    [setTasks]
  );

  /**
   * End all tasks created by this hook on unmount
   */
  useEffect(() => {
    const loadingTaskIdSet = localLoadingTasksRef.current;
    return () => {
      for (const taskId of Array.from(loadingTaskIdSet)) {
        endLoadingTask(taskId);
      }
    };
  }, [endLoadingTask]);

  return {
    isLoading,
    createLoadingTask,
    updateLoadingTask,
    endLoadingTask,
  };
};

interface LoadingProviderProps {
  children: React.ReactNode;
}

export const LoadingProvider = ({ children }: LoadingProviderProps) => {
  const [tasks, setTasks] = useState<LoadingTask[]>([]);
  const isLoading = useMemo(() => tasks.length !== 0, [tasks.length]);
  const [delayedIsLoading, setDelayedIsLoading] = useState(false);
  const showLoading = useMemo(
    () => isLoading || delayedIsLoading,
    [delayedIsLoading, isLoading]
  );
  const firstTask = useMemo<LoadingTask | undefined>(() => tasks[0], [tasks]);
  const timeoutIdRef = useRef<any | null>(null);

  useEffect(() => {
    if (tasks.length > 0) {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      setDelayedIsLoading(true);
      return;
    }
    timeoutIdRef.current = setTimeout(
      () => setDelayedIsLoading(false),
      TRANSITION_TIME_MS
    ) as any;
  }, [tasks.length]);

  return (
    <LoadingContext.Provider value={{ tasks, setTasks }}>
      {showLoading ? (
        <LoadingScreen
          task={firstTask}
          progressBarTransitionMs={PROGRESS_BAR_TRANSITION_MS}
          opacity={isLoading ? "1" : "0"}
          transition={`opacity ${TRANSITION_TIME_MS / 1000}s`}
        />
      ) : null}
      {children}
    </LoadingContext.Provider>
  );
};
