import { MUSIC_ASSET_MAP, MusicAsset } from "./mapping";
import { Howl } from "howler";

export class MusicService {
  private userHasFirstClicked = false;
  private queuedMusic: MusicAsset | null = null;

  private currentlyPlayingMusic: MusicAsset | null = null;
  private howlCache = new Map<MusicAsset, Howl>();

  constructor() {
    window.addEventListener("click", this.onUserFirstClick);
  }

  private onUserFirstClick = () => {
    window.removeEventListener("click", this.onUserFirstClick);
    this.userHasFirstClicked = true;
    if (this.queuedMusic) {
      this.play(this.queuedMusic);
    }
  };

  /**
   * Plays a given music asset
   */
  async play(music: MusicAsset) {
    if (!this.userHasFirstClicked) {
      this.queuedMusic = music;
      return;
    }

    // Already playing target music so ignore
    if (this.currentlyPlayingMusic === music) {
      return;
    }

    const fadeDuration = 1000;

    // Stop current music
    if (this.currentlyPlayingMusic) {
      const currentSound = this.howlCache.get(this.currentlyPlayingMusic);
      currentSound?.once("fade", () => {
        currentSound.stop();
      });
      currentSound?.fade(1, 0, fadeDuration);
    }

    this.currentlyPlayingMusic = music;

    const sound =
      this.howlCache.get(music) ??
      new Howl({
        src: [MUSIC_ASSET_MAP[music]],
        loop: true,
      });
    this.howlCache.set(music, sound);
    sound.play();
    sound.fade(0, 1, fadeDuration);
  }
}
