import { AssetManager } from "realms-three-utils";
import { TextureAssetMap } from "./assetMaps/textures/textureAssetMap";
import { BaseCharacterAssetMap } from "./assetMaps/baseCharacterAssetMap";
import { WeaponAssetMap } from "./assetMaps/weaponAssetMap";
import { TerrainAssetMap } from "./assetMaps/terrainMap";
import { GoblinAssetMap } from "./assetMaps/goblinAssetMap";

/**
 * All the assets to be loaded
 */
const MMOAssetMap = {
  ...TextureAssetMap,
  ...TerrainAssetMap,
  ...BaseCharacterAssetMap,
  ...WeaponAssetMap,
  ...GoblinAssetMap,
} as const;

export const MMOAssetManager = new AssetManager(MMOAssetMap);
