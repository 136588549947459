import { Weapon } from "mmo-common";
import { Render3DGameObject } from "realms-engine-browser";
import { disposeThreeObject } from "realms-three-utils";
import { WEAPON_ASSET_MAPPING } from "../base-character/mapping";
import { MMOAssetManager } from "@game/config";
import { CHARACTER_SCALE } from "@game/config/assetMaps/baseCharacterAssetMap";
import {
  LerpGameObject,
  ProcessGameObject,
  SmoothDampVector3,
  Vector3,
  vector3Add,
} from "realms-engine";
import { FLOATING_WEAPON_MAPPING } from "./mapping";
import { createDissolveMaterial } from "@game/materials/dissolveMaterial";
import THREE from "realms-three";

export class FloatingWeaponGameObject extends Render3DGameObject {
  processGameObject: ProcessGameObject;

  private weapon: Weapon;
  private weaponMesh: THREE.Mesh | null = null;
  private target: Vector3 | null = null;
  private targetDamping: SmoothDampVector3;
  private targetSmoothTime: number;
  private dampingMaxSpeed: number;
  private weaponDissolveTimeMs: number;

  private updateDissolveMaterial:
    | ReturnType<typeof createDissolveMaterial>["updateUniforms"]
    | null = null;

  private dissolveUniformLerp: LerpGameObject<number>;

  constructor({
    weapon,
    targetSmoothTime = 70,
    dampingMaxSpeed = 300,
    weaponDissolveTimeMs = 800,
  }: {
    weapon: Weapon;
    targetSmoothTime?: number;
    dampingMaxSpeed?: number;
    weaponDissolveTimeMs?: number;
  }) {
    super();
    this.weaponDissolveTimeMs = weaponDissolveTimeMs;
    this.weapon = weapon;
    this.processGameObject = this.addChild(
      new ProcessGameObject({}, this.onStep)
    );
    this.setWeapon(weapon);
    this.getThreeObject().scale.setScalar(CHARACTER_SCALE);
    this.targetSmoothTime = targetSmoothTime;
    this.dampingMaxSpeed = dampingMaxSpeed;
    this.targetDamping = new SmoothDampVector3(
      () => this.getPosition(),
      this.dampingMaxSpeed
    );
    this.dissolveUniformLerp = this.addChild(
      new LerpGameObject<number>((progress) =>
        this.updateDissolveMaterial?.({ progress })
      )
    );
  }

  private onStep = (delta: number) => {
    if (!this.target || this.weapon === Weapon.None) {
      return;
    }
    const { position: offset } =
      FLOATING_WEAPON_MAPPING[this.weapon].transformation;
    this.setPosition(
      this.targetDamping.damp(
        vector3Add(this.target, offset),
        this.targetSmoothTime,
        delta
      )
    );
  };

  setTarget(target: Vector3 | null) {
    this.target = target;
  }

  /**
   * Sets weapon visibility
   */
  setWeaponVisibility(visibility: boolean, options: { delay?: number } = {}) {
    if (!this.weaponMesh) {
      return;
    }
    if (this.weaponMesh.visible === visibility) {
      return;
    }
    this.weaponMesh.visible = visibility;
    if (visibility) {
      this.dissolveUniformLerp.lerp(0, 1, this.weaponDissolveTimeMs, options);
    } else {
      this.dissolveUniformLerp.lerp(1, 0, this.weaponDissolveTimeMs, options);
    }
  }

  /**
   * Sets the weapon
   */
  setWeapon(weapon: Weapon) {
    this.weapon = weapon;
    if (this.weaponMesh) {
      this.getThreeObject().remove(this.weaponMesh);
      disposeThreeObject(this.weaponMesh);
      this.weaponMesh = null;
    }

    // No weapon just remove
    if (weapon === Weapon.None) {
      return;
    }

    // Add weapon to mesh
    this.weaponMesh = MMOAssetManager.getMesh(
      WEAPON_ASSET_MAPPING[weapon].asset,
      { clone: true }
    );
    const { material, updateUniforms } = createDissolveMaterial({
      baseMaterial: THREE.MeshPhongMaterial,
      progress: 1,
    });
    this.weaponMesh.material = material;
    this.weaponMesh.material.side = THREE.DoubleSide;
    this.updateDissolveMaterial = updateUniforms;
    const { rotation } = FLOATING_WEAPON_MAPPING[weapon].transformation;
    this.weaponMesh.rotation.set(rotation.x, rotation.y, rotation.z);
    this.getThreeObject().add(this.weaponMesh);
  }
}
