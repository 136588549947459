import { AssetNames } from "../base-character/mapping";
import {
  GoblinClothing,
  GoblinConfig,
  GoblinFeatures,
  GoblinSkin,
} from "mmo-common";

export const GOBLIN_TEXTURE_MAPPING: {
  [field in keyof GoblinConfig]: {
    [k in GoblinConfig[field]]: AssetNames;
  };
} = {
  skin: {
    [GoblinSkin.Green]: "goblinSkinGreen",
  },
  features: {
    [GoblinFeatures.Normal]: "goblinFeaturesNormal",
    [GoblinFeatures.Fierce]: "goblinFeaturesFierce",
  },
  clothing: {
    [GoblinClothing.Barbaric]: "goblinClothingBarbaric",
    [GoblinClothing.Armoured]: "goblinClothingArmoured",
  },
};
