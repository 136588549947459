import THREE from "realms-three";
import { patchShaders } from "./patchShaders";
import { Simplex } from "./patchShaders.const";

const vertexShader = `
  varying vec2 vUv;
  varying vec3 vPosition; // use the world position instead of the uv
  void main() {
    vUv = uv;
    vPosition = position;
  }`;

let fragmentShader = patchShaders(
  `
  varying vec2 vUv;
  varying vec3 vPosition;
  uniform float uThickness;
  uniform vec3 uColor;
  uniform float uProgress;

  void main() {
    gln_tFBMOpts opts = gln_tFBMOpts(1.0, 0.3, 2.0, 5.0, 1.0, 5, false, false);
    float noise = gln_sfbm(vPosition, opts); //  use the world position instead of the uv for a better effect working on all objects
    noise = gln_normalize(noise);

    float progress = uProgress;

    float alpha = step(1.0 - progress, noise);
    float border = step((1.0 - progress) - uThickness, noise) - alpha;

    csm_DiffuseColor.a = alpha + border;
    csm_DiffuseColor.rgb = mix(csm_DiffuseColor.rgb, uColor, border);
  }`,
  [Simplex]
);

// fragmentShader = DISSOLVE_FRAGMENT_SHADER;

export interface DissolveMaterialUniforms {
  thickness?: number;
  color?: string;
  intensity?: number;
  progress: number;
}

/**
 * Dissolve material based off this youtube video and code
 *
 * Youtube Video: https://www.youtube.com/watch?v=ma9t7HAOZRg&t=1393s&ab_channel=WawaSensei
 *
 * Code: https://github.com/wass08/r3f-objects-dissolve/blob/main/src/components/DissolveMaterial.jsx
 */
export const createDissolveMaterial = ({
  baseMaterial,
  thickness = 0.1,
  color = "#eb5a13",
  intensity = 50,
  progress,
}: {
  baseMaterial: typeof THREE.Material | THREE.Material;
} & DissolveMaterialUniforms) => {
  const uniforms = {
    uThickness: { value: thickness },
    uColor: { value: new THREE.Color(color).multiplyScalar(intensity) },
    uProgress: { value: progress },
  };

  const updateUniforms = ({
    thickness,
    color: newColor,
    intensity: newIntensity,
    progress,
  }: DissolveMaterialUniforms) => {
    if (thickness !== undefined) {
      uniforms.uThickness.value = thickness;
    }
    if (newColor !== undefined || newIntensity !== undefined) {
      uniforms.uColor.value = new THREE.Color(newColor ?? color).multiplyScalar(
        newIntensity ?? intensity
      );
    }
    uniforms.uProgress.value = progress;
  };

  return {
    /**
     * Creates the material
     */
    material: new THREE.CustomShaderMaterial({
      baseMaterial,
      vertexShader,
      fragmentShader,
      uniforms: uniforms,
      toneMapped: false,
      transparent: true,
    }) as any as THREE.Material,

    /**
     * Updates the uniform of this material
     */
    updateUniforms,
  };
};
