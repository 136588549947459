import {
  Render3DGameObject,
  threeToQuat,
  toThreeQuaternion,
} from "realms-engine-browser";
import { BaseCharacterMesh } from "./baseCharacterMesh";
import { ThreeAnimationGameObject } from "../utils";
import {
  BaseCharacterGender,
  BaseCharacterMeshConfig,
  CreatureAnimation,
  CreatureAnimationData,
} from "mmo-common";
import { MMOAssetManager } from "../../config";
import THREE from "realms-three";
import { LerpGameObject, Quat } from "realms-engine";
import { DissolveMaterialUniforms } from "@game/materials/dissolveMaterial";

/**
 * Displays the base character
 */
export class BaseCharacterGameObject extends Render3DGameObject {
  readonly baseCharacterMesh: BaseCharacterMesh;
  private animationGameObject: ThreeAnimationGameObject;

  private weaponDissolveMaterialUniformLerp: LerpGameObject<number>;
  private weaponDissolveMaterialUniforms: Omit<
    DissolveMaterialUniforms,
    "progress"
  > = {};

  constructor(
    private config: { character: BaseCharacterMeshConfig },
    options: { scale?: number } = {}
  ) {
    const baseCharacterMesh = new BaseCharacterMesh(config.character, options);
    super(baseCharacterMesh.getMesh());

    this.weaponDissolveMaterialUniformLerp = this.addChild(
      new LerpGameObject<number>((progress) =>
        this.baseCharacterMesh.updateWeaponDissolveMaterialUniforms?.({
          progress,
          ...this.weaponDissolveMaterialUniforms,
        })
      )
    );

    this.baseCharacterMesh = baseCharacterMesh;
    this.animationGameObject = new ThreeAnimationGameObject();
    this.loadAnimations();
    this.addChild(this.animationGameObject);

    // Update meshes
    this.baseCharacterMesh.addListener("onMeshChange", (mesh) => {
      this.loadAnimations();
      this.setThreeObject(mesh.getMesh());
    });
  }

  setWeaponVisibility(visibility: boolean, options: { delay?: number } = {}) {
    this.dissolveWeapon({
      start: visibility ? 0 : 1,
      end: visibility ? 1 : 0,
      durationMs: 500,
      options,
    });
  }

  /**
   * Dissolves the mesh
   */
  dissolveWeapon({
    start,
    end,
    durationMs,
    options = {},
    ...uniforms
  }: {
    start: number;
    end: number;
    durationMs: number;
    options?: { delay?: number };
  } & Omit<DissolveMaterialUniforms, "progress">) {
    this.weaponDissolveMaterialUniforms = uniforms;
    this.weaponDissolveMaterialUniformLerp.lerp(
      start,
      end,
      durationMs,
      options
    );
  }

  private loadAnimations() {
    if (this.config.character.gender === BaseCharacterGender.Male) {
      this.animationGameObject.updateAnimationMap({
        [CreatureAnimation.Idle]: {
          clip: MMOAssetManager.getObject3D("baseCharacterMaleIdleAnimation", {
            clone: true,
          }).animations[0],
        },
        [CreatureAnimation.Walk]: {
          clip: MMOAssetManager.getObject3D("baseCharacterMaleWalkAnimation", {
            clone: true,
          }).animations[0],
        },
        [CreatureAnimation.Jump]: {
          clip: MMOAssetManager.getObject3D("baseCharacterMaleJumpAnimation", {
            clone: true,
          }).animations[0],
        },
        [CreatureAnimation.Death]: {
          clip: MMOAssetManager.getObject3D("baseCharacterMaleDeathAnimation", {
            clone: true,
          }).animations[0],
          clampWhenFinished: true,
        },
        [CreatureAnimation.NormalAttack1]: {
          clip: MMOAssetManager.getObject3D(
            "baseCharacterMaleHorizontalSlashAnimation",
            {
              clone: true,
            }
          ).animations[0],
        },
        [CreatureAnimation.NormalAttack2]: {
          clip: MMOAssetManager.getObject3D(
            "baseCharacterMaleAttack360Animation",
            {
              clone: true,
            }
          ).animations[0],
        },
        [CreatureAnimation.NormalAttack3]: {
          clip: MMOAssetManager.getObject3D(
            "baseCharacterMaleVerticalSlashAnimation",
            {
              clone: true,
            }
          ).animations[0],
        },
        warriorIdle: {
          clip: MMOAssetManager.getObject3D("warriorMaleIdleAnimation", {
            clone: true,
          }).animations[0],
        },
        rogueIdle: {
          clip: MMOAssetManager.getObject3D("rogueMaleIdleAnimation", {
            clone: true,
          }).animations[0],
        },
        mageIdle: {
          clip: MMOAssetManager.getObject3D("mageMaleIdleAnimation", {
            clone: true,
          }).animations[0],
        },
      });
    }
    if (this.config.character.gender === BaseCharacterGender.Female) {
      this.animationGameObject.updateAnimationMap({
        [CreatureAnimation.Idle]: {
          clip: MMOAssetManager.getObject3D(
            "baseCharacterFemaleIdleAnimation",
            {
              clone: true,
            }
          ).animations[0],
        },
        [CreatureAnimation.Walk]: {
          clip: MMOAssetManager.getObject3D(
            "baseCharacterFemaleWalkAnimation",
            {
              clone: true,
            }
          ).animations[0],
        },
        [CreatureAnimation.Jump]: {
          clip: MMOAssetManager.getObject3D(
            "baseCharacterFemaleJumpAnimation",
            {
              clone: true,
            }
          ).animations[0],
        },
        [CreatureAnimation.Death]: {
          clip: MMOAssetManager.getObject3D(
            "baseCharacterFemaleDeathAnimation",
            {
              clone: true,
            }
          ).animations[0],
          clampWhenFinished: true,
        },
        [CreatureAnimation.NormalAttack1]: {
          clip: MMOAssetManager.getObject3D(
            "baseCharacterFemaleHorizontalSlashAnimation",
            {
              clone: true,
            }
          ).animations[0],
        },
        [CreatureAnimation.NormalAttack2]: {
          clip: MMOAssetManager.getObject3D(
            "baseCharacterFemaleAttack360Animation",
            {
              clone: true,
            }
          ).animations[0],
        },
        [CreatureAnimation.NormalAttack3]: {
          clip: MMOAssetManager.getObject3D(
            "baseCharacterFemaleVerticalSlashAnimation",
            {
              clone: true,
            }
          ).animations[0],
        },
        warriorIdle: {
          clip: MMOAssetManager.getObject3D("warriorFemaleIdleAnimation", {
            clone: true,
          }).animations[0],
        },
        rogueIdle: {
          clip: MMOAssetManager.getObject3D("rogueFemaleIdleAnimation", {
            clone: true,
          }).animations[0],
        },
        mageIdle: {
          clip: MMOAssetManager.getObject3D("mageFemaleIdleAnimation", {
            clone: true,
          }).animations[0],
        },
      });
    }
  }

  updateConfig(config: BaseCharacterMeshConfig) {
    this.config.character = config;
    this.baseCharacterMesh.updateConfig(config);
  }

  /**
   * Updates animation data
   */
  playAnimation(
    data: CreatureAnimationData,
    options: { fadeOutDuration?: number } = {}
  ) {
    this.animationGameObject.play(data.name, {
      duration: data.duration,
      fadeOutDuration: options.fadeOutDuration,
    });
  }

  getQuaternion() {
    return threeToQuat(
      toThreeQuaternion(super.getQuaternion()).multiply(
        new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          (-3 * Math.PI) / 2
        )
      )
    );
  }

  setQuaternion(q: Quat) {
    // We have to rotate because the character is in the wrong rotation
    super.setQuaternion(
      toThreeQuaternion(q).multiply(
        new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          (3 * Math.PI) / 2
        )
      )
    );
  }
}
