"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHARACTER_EYE_COLORS = exports.CHARACTER_SKIN_COLORS = exports.CHARACTER_HAIR_COLORS = void 0;
exports.CHARACTER_HAIR_COLORS = [
    "#506372",
    "#890a24",
    "#97894e",
    "#FEFCBF",
    "#F8D26B",
    "#B1BCD5",
    "#949597",
    "#18100E",
    "#A7CEC6",
    "#7A888B",
    "#4E4D52",
    "#514E3C",
    "#866F3F",
    "#90524C",
    "#78471E",
    "#AAAC4F",
    "#56361B",
    "#F687B3",
];
exports.CHARACTER_SKIN_COLORS = [
    "#FFCC99",
    "#FEB186",
    "#DFC183",
    "#CEAB69",
    "#B98865",
    "#AA724B",
    "#935D37",
    "#CBACA3",
    "#C0A183",
    "#C18E74",
    "#EBCDA8",
    "#CAA661",
    "#7B4B2A",
    "#573719",
    "#483728",
];
exports.CHARACTER_EYE_COLORS = [
    "#18100E",
    "#F8D26B",
    "#B1BCD5",
    "#949597",
    "#A7CEC6",
    "#7A888B",
    "#4E4D52",
    "#514E3C",
    "#866F3F",
    "#90524C",
    "#78471E",
    "#AAAC4F",
    "#56361B",
];
