import { MMONetSchema } from "mmo-common";
import {
  networkPhysicsOutlineSyncSchemaMap,
  physicsPredictionSyncSchemaMap,
  SyncMethod,
  SyncSchemaMap,
} from "realms-engine-network-client";

const creatureSyncSchemaMap = {
  name: SyncMethod.DiscreteStart,
  position: {
    x: SyncMethod.Linear,
    y: SyncMethod.Linear,
    z: SyncMethod.Linear,
  },
  quaternion: SyncMethod.Linear,
  health: SyncMethod.DiscreteStart,
  maxHealth: SyncMethod.DiscreteStart,
  animation: SyncMethod.DiscreteStart,
  moveDirection: {
    x: SyncMethod.Linear,
    y: SyncMethod.Linear,
  },
};

const characterSyncSchemaMap = {
  id: SyncMethod.DiscreteStart,
  username: SyncMethod.DiscreteStart,
  race: SyncMethod.DiscreteStart,
  gender: SyncMethod.DiscreteStart,
  skinColor: SyncMethod.DiscreteStart,
  eyeColor: SyncMethod.DiscreteStart,
  eyes: SyncMethod.DiscreteStart,
  hairBase: SyncMethod.DiscreteStart,
  hairColor: SyncMethod.DiscreteStart,
  hairFringe: SyncMethod.DiscreteStart,
  hairBack: SyncMethod.DiscreteStart,
  hairSide: SyncMethod.DiscreteStart,
  hairTail: SyncMethod.DiscreteStart,
  beard: SyncMethod.DiscreteStart,
  moustache: SyncMethod.DiscreteStart,
  neckSize: SyncMethod.DiscreteStart,
  chestSize: SyncMethod.DiscreteStart,
  armSize: SyncMethod.DiscreteStart,
  waistSize: SyncMethod.DiscreteStart,
  thighSize: SyncMethod.DiscreteStart,
  calvesSize: SyncMethod.DiscreteStart,
  noseHeight: SyncMethod.DiscreteStart,
  noseWidth: SyncMethod.DiscreteStart,
  cheeks: SyncMethod.DiscreteStart,
  eyeDroop: SyncMethod.DiscreteStart,
  clothing: SyncMethod.DiscreteStart,
  pants: SyncMethod.DiscreteStart,
  shirt: SyncMethod.DiscreteStart,
  weapon: SyncMethod.DiscreteStart,
};

const shapeSyncSchemaMap = {
  type: SyncMethod.DiscreteStart,
  position: {
    x: SyncMethod.Linear,
    y: SyncMethod.Linear,
    z: SyncMethod.Linear,
  },
  quaternion: SyncMethod.Linear,
  properties: SyncMethod.DiscreteStart,
};

export const mmoSyncSchemaMap: SyncSchemaMap<MMONetSchema> = {
  ...networkPhysicsOutlineSyncSchemaMap,
  ...physicsPredictionSyncSchemaMap,
  character: {
    physicsGameObjectId: SyncMethod.DiscreteStart,
    character: characterSyncSchemaMap,
    creature: creatureSyncSchemaMap,
    jumpVelocity: SyncMethod.DiscreteStart,
    speed: SyncMethod.DiscreteStart,
  },
  player: {
    physicsGameObjectId: SyncMethod.DiscreteStart,
    clientId: SyncMethod.DiscreteStart,
    character: characterSyncSchemaMap,
    characterId: SyncMethod.DiscreteStart,
    creature: creatureSyncSchemaMap,
    inRangeInteractableIds: SyncMethod.DiscreteStart,
    jumpVelocity: SyncMethod.DiscreteStart,
    speed: SyncMethod.DiscreteStart,
    inCombat: SyncMethod.DiscreteStart,
  },
  goblin: creatureSyncSchemaMap,
  shape: shapeSyncSchemaMap,
  interactable: {
    interactableId: SyncMethod.DiscreteStart,
    options: SyncMethod.DiscreteStart,
    position: {
      x: SyncMethod.Linear,
      y: SyncMethod.Linear,
      z: SyncMethod.Linear,
    },
  },
};
