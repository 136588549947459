import { MMOAssetManager } from "../../config";
import THREE from "realms-three";
import { createCanvas, smoothGeometry } from "../player/characterUtils";
import { MixamoBonesMap, buildMixamoBonesMap } from "../utils";
import { createBaseCharacterTexture } from "./texture";
import {
  AssetNames,
  BASE_CHARACTER_ASSET_MAPPING,
  WEAPON_ASSET_MAPPING,
} from "./mapping";
import { disposeThreeObject, setThreeVector3 } from "realms-three-utils";
import { BASE_CHARACTER_MESH_CONFIG } from "./config";
import { EventManager, EventEmitter } from "realms-utils";
import { Vector3 } from "realms-engine";
import {
  BaseCharacterConfigChanges,
  getBaseCharacterConfigChanges,
} from "./utils";
import {
  BaseCharacterGender,
  BaseCharacterHairBase,
  BaseCharacterHairBack,
  BaseCharacterHairFringe,
  BaseCharacterHairSide,
  BaseCharacterHairTail,
  BaseCharacterMoustache,
  BaseCharacterBeard,
  Weapon,
  BaseCharacterMeshConfig,
  DEFAULT_CHARACTER_CONFIG,
  MORPH_TARGET_MAP,
} from "mmo-common";
import { createDissolveMaterial } from "@game/materials/dissolveMaterial";

interface BaseCharacterMeshEventsMap {
  onMeshChange: BaseCharacterMesh;
}

/**
 * Configures the base character
 */
export class BaseCharacterMesh
  implements EventEmitter<BaseCharacterMeshEventsMap>
{
  private rootGroup: THREE.Object3D;
  private baseCharacterMesh: THREE.Object3D | null = null;
  private ears: THREE.Mesh | null = null;

  private customMeshes: {
    hairBase: THREE.Mesh | null;
    hairBack: THREE.Mesh | null;
    hairFringe: THREE.Mesh | null;
    hairSide: THREE.Mesh | null;
    hairTail: THREE.Mesh | null;
    moustache: THREE.Mesh | null;
    beard: THREE.Mesh | null;
    weapon: THREE.Mesh | null;
  } = {
    hairBase: null,
    hairBack: null,
    hairFringe: null,
    hairSide: null,
    hairTail: null,
    moustache: null,
    beard: null,
    weapon: null,
  };

  // Events
  private eventManager = new EventManager<BaseCharacterMeshEventsMap>();
  addListener = this.eventManager.addListener;
  removeListener = this.eventManager.removeListener;
  once = this.eventManager.once;

  updateWeaponDissolveMaterialUniforms:
    | ReturnType<typeof createDissolveMaterial>["updateUniforms"]
    | null = null;
  private currentConfig: BaseCharacterMeshConfig;

  constructor(
    config: BaseCharacterMeshConfig = DEFAULT_CHARACTER_CONFIG,
    options: { scale?: number } = {}
  ) {
    this.rootGroup = new THREE.Object3D();
    this.rootGroup.scale.setScalar(options.scale ?? 1);
    this.currentConfig = config;
    this.updateConfig(config, { ignoreCache: true });
  }

  /**
   * Updates the config
   */
  updateConfig(
    config: BaseCharacterMeshConfig,
    options: { ignoreCache?: boolean } = {}
  ) {
    // Same config so ignore
    const changes = getBaseCharacterConfigChanges(this.currentConfig, config);
    if (Object.entries(changes).length === 0 && !options.ignoreCache) {
      return;
    }

    const oldBaseCharacterMesh = this.baseCharacterMesh;

    // Remove old character mesh if it exists
    if (this.baseCharacterMesh) {
      this.rootGroup.remove(this.baseCharacterMesh);
    }

    // Update the mesh
    const { baseCharacterMesh, bodyMesh } = this.buildMesh(config, changes);
    this.baseCharacterMesh = baseCharacterMesh;

    // Add body texture
    this.updateCharacterTextures({ bodyMesh, config });

    // Add new mesh to root group
    this.rootGroup.add(baseCharacterMesh);
    if (oldBaseCharacterMesh !== this.baseCharacterMesh) {
      this.eventManager.dispatch("onMeshChange", this);
    }
    this.currentConfig = config;
  }

  /**
   * Gets the character box
   */
  getCharacterBox(): THREE.Box3 {
    return new THREE.Box3().setFromObject(
      this.baseCharacterMesh ?? new THREE.Object3D()
    );
  }

  /**
   * Updates the characters textures
   */
  private updateCharacterTextures({
    bodyMesh,
    config,
  }: {
    config: BaseCharacterMeshConfig;
    bodyMesh: THREE.Mesh;
  }) {
    // Clean up previous texture
    (bodyMesh.material as THREE.MeshPhongMaterial).map?.dispose();

    // Add body texture
    (bodyMesh.material as THREE.MeshPhongMaterial).map =
      createBaseCharacterTexture(config);

    if (this.customMeshes.hairBase) {
      this.colorPart(this.customMeshes.hairBase, config.hairColor);
    }

    if (this.customMeshes.hairBack) {
      this.colorPart(this.customMeshes.hairBack, config.hairColor);
    }

    if (this.customMeshes.hairFringe) {
      this.colorPart(this.customMeshes.hairFringe, config.hairColor);
    }

    if (this.customMeshes.hairSide) {
      this.colorPart(this.customMeshes.hairSide, config.hairColor);
    }

    if (this.customMeshes.hairTail) {
      this.colorPart(this.customMeshes.hairTail, config.hairColor);
    }

    if (this.customMeshes.moustache) {
      this.colorPart(this.customMeshes.moustache, config.hairColor);
    }

    if (this.customMeshes.beard) {
      this.colorPart(this.customMeshes.beard, config.hairColor);
    }

    if (this.ears) {
      this.colorPart(this.ears, config.skinColor);
    }
  }

  private updateMorphTarget(
    property: keyof typeof MORPH_TARGET_MAP,
    value: number,
    bodyMesh: THREE.Mesh
  ) {
    if (!bodyMesh.morphTargetInfluences || !bodyMesh.morphTargetDictionary) {
      console.error(bodyMesh);
      throw new Error(`Character mesh is missing morph targets`);
    }

    bodyMesh.morphTargetInfluences[
      bodyMesh.morphTargetDictionary[MORPH_TARGET_MAP[property]]
    ] = Math.max(0, Math.min(1, value));
  }

  /**
   * Builds the mesh
   */
  private buildMesh(
    config: BaseCharacterMeshConfig,
    changes: BaseCharacterConfigChanges
  ) {
    let baseCharacterMesh = this.baseCharacterMesh;

    // Update mesh only if needed
    if (changes.gender || !baseCharacterMesh) {
      baseCharacterMesh =
        config.gender === BaseCharacterGender.Male
          ? MMOAssetManager.getObject3D("baseCharacterMale", {
              cloneDeep: true,
            })
          : MMOAssetManager.getObject3D("baseCharacterFemale", {
              cloneDeep: true,
            });
    }

    // Smooth Mesh
    let bodyMesh: null | THREE.Mesh = null;
    let bones: null | THREE.Object3D = null;

    for (const child of baseCharacterMesh.children) {
      if (child.name === "male" || child.name === "female") {
        if (child instanceof THREE.Mesh) {
          bodyMesh = child;
          smoothGeometry(child);
        }
      }
      if (child.name === "mixamorigHips") {
        bones = child;
      }
    }

    if (!bodyMesh) {
      throw new Error(
        `Unable to find body mesh. Children: ${baseCharacterMesh.children
          .map((c) => c.name)
          .join(",")}`
      );
    }
    if (!bones) {
      throw new Error(
        `Unable to find bones. Children: ${baseCharacterMesh.children
          .map((c) => c.name)
          .join(",")}`
      );
    }

    for (const key of Object.keys(MORPH_TARGET_MAP)) {
      const keyTyped = key as keyof typeof MORPH_TARGET_MAP;
      this.updateMorphTarget(keyTyped, config[keyTyped], bodyMesh);
    }

    bodyMesh.material = this.createMaterial();

    const bonesMap = buildMixamoBonesMap(bones);

    this.updateCustomMesh({
      part: "hairBase",
      isHair: true,
      bonesMap,
      targetBone: "headTop",
      assetPath:
        config.hairBase !== BaseCharacterHairBase.None
          ? BASE_CHARACTER_ASSET_MAPPING.common.hairBase[config.hairBase]
          : undefined,
      position:
        config.hairBase !== BaseCharacterHairBase.None
          ? BASE_CHARACTER_MESH_CONFIG.hairBase[config.hairBase][config.gender]
              .position
          : undefined,
    });

    this.updateCustomMesh({
      part: "hairBack",
      isHair: true,
      bonesMap,
      targetBone: "headTop",
      assetPath:
        config.hairBack !== BaseCharacterHairBack.None
          ? BASE_CHARACTER_ASSET_MAPPING.common.hairBack[config.hairBack]
          : undefined,
      position:
        config.hairBack !== BaseCharacterHairBack.None
          ? BASE_CHARACTER_MESH_CONFIG.hairBack[config.hairBack][config.gender]
              .position
          : undefined,
    });

    this.updateCustomMesh({
      part: "hairFringe",
      isHair: true,
      bonesMap,
      targetBone: "headTop",
      assetPath:
        config.hairFringe !== BaseCharacterHairFringe.None
          ? BASE_CHARACTER_ASSET_MAPPING.common.hairFringe[config.hairFringe]
          : undefined,
      position:
        config.hairFringe !== BaseCharacterHairFringe.None
          ? BASE_CHARACTER_MESH_CONFIG.hairFringe[config.hairFringe][
              config.gender
            ].position
          : undefined,
    });

    this.updateCustomMesh({
      part: "hairSide",
      isHair: true,
      bonesMap,
      targetBone: "headTop",
      assetPath:
        config.hairSide !== BaseCharacterHairSide.None
          ? BASE_CHARACTER_ASSET_MAPPING.common.hairSide[config.hairSide]
          : undefined,
      position:
        config.hairSide !== BaseCharacterHairSide.None
          ? BASE_CHARACTER_MESH_CONFIG.hairSide[config.hairSide][config.gender]
              .position
          : undefined,
    });

    this.updateCustomMesh({
      part: "hairTail",
      isHair: true,
      bonesMap,
      targetBone: "headTop",
      assetPath:
        config.hairTail !== BaseCharacterHairTail.None
          ? BASE_CHARACTER_ASSET_MAPPING.common.hairTail[config.hairTail]
          : undefined,
      position:
        config.hairTail !== BaseCharacterHairTail.None
          ? BASE_CHARACTER_MESH_CONFIG.hairTail[config.hairTail][config.gender]
              .position
          : undefined,
    });

    this.updateCustomMesh({
      part: "moustache",
      isHair: true,
      bonesMap,
      targetBone: "headTop",
      assetPath:
        config.moustache !== BaseCharacterMoustache.None &&
        config.gender === BaseCharacterGender.Male
          ? BASE_CHARACTER_ASSET_MAPPING.common.moustache[config.moustache]
          : undefined,
      position:
        config.moustache !== BaseCharacterMoustache.None &&
        config.gender === BaseCharacterGender.Male
          ? BASE_CHARACTER_MESH_CONFIG.moustache[config.moustache].position
          : undefined,
    });

    this.updateCustomMesh({
      part: "beard",
      isHair: true,
      bonesMap,
      targetBone: "headTop",
      assetPath:
        config.beard !== BaseCharacterBeard.None &&
        config.gender === BaseCharacterGender.Male
          ? BASE_CHARACTER_ASSET_MAPPING.common.beard[config.beard]
          : undefined,
      position:
        config.beard !== BaseCharacterBeard.None &&
        config.gender === BaseCharacterGender.Male
          ? BASE_CHARACTER_MESH_CONFIG.beard[config.beard].position
          : undefined,
    });

    this.updateEarsMesh({ bonesMap, config });

    this.updateCustomMesh({
      part: "weapon",
      bonesMap,
      targetBone: "rightHand",
      assetPath:
        config.weapon !== Weapon.None
          ? WEAPON_ASSET_MAPPING[config.weapon].asset
          : undefined,
      rotation:
        config.weapon !== Weapon.None
          ? WEAPON_ASSET_MAPPING[config.weapon].transformation[config.gender]
              .rotation
          : undefined,
      position:
        config.weapon !== Weapon.None
          ? WEAPON_ASSET_MAPPING[config.weapon].transformation[config.gender]
              .position
          : undefined,
    });

    // Update weapon mesh
    if (this.customMeshes.weapon) {
      const { material, updateUniforms } = createDissolveMaterial({
        baseMaterial: this.customMeshes.weapon.material as THREE.Material,
        progress: 1,
      });
      this.customMeshes.weapon.material = material;
      this.updateWeaponDissolveMaterialUniforms = updateUniforms;
    } else {
      this.updateWeaponDissolveMaterialUniforms = null;
    }

    return {
      baseCharacterMesh,
      bodyMesh,
      bonesMap,
    };
  }

  /**
   * Updates a custom mesh
   */
  private updateCustomMesh<T extends keyof BaseCharacterMesh["customMeshes"]>({
    part,
    bonesMap,
    targetBone,
    assetPath,
    position,
    rotation,
    isHair,
  }: {
    part: T;
    bonesMap: MixamoBonesMap;
    targetBone: keyof MixamoBonesMap;
    assetPath?: AssetNames;
    position?: Vector3;
    rotation?: Vector3;
    isHair?: boolean;
  }) {
    // Remove old mesh
    const oldMesh = this.customMeshes[part];
    if (oldMesh) {
      bonesMap[targetBone].remove(oldMesh);
      disposeThreeObject(oldMesh);
    }

    // None type so remove
    if (!assetPath) {
      this.customMeshes[part] = null;
      return;
    }

    const newMesh = MMOAssetManager.getMesh(assetPath, {
      cloneDeep: true,
    });
    newMesh.material = isHair
      ? this.createHairMaterial()
      : this.createMaterial();
    this.customMeshes[part] = newMesh;
    if (position) {
      this.customMeshes[part]?.position.set(position.x, position.y, position.z);
    }
    if (rotation) {
      this.customMeshes[part]?.rotation.set(rotation.x, rotation.y, rotation.z);
    }
    if (newMesh) {
      (newMesh.material as THREE.Material).side = THREE.DoubleSide;
      bonesMap[targetBone].add(newMesh);
    }
  }

  /**
   * Updates ears mesh
   */
  private updateEarsMesh({
    bonesMap,
    config,
  }: {
    config: BaseCharacterMeshConfig;
    bonesMap: MixamoBonesMap;
  }) {
    if (this.ears) {
      bonesMap.headTop.remove(this.ears);
      disposeThreeObject(this.ears);
    }
    this.ears = MMOAssetManager.getMesh(
      BASE_CHARACTER_ASSET_MAPPING.common.ears[config.race],
      { cloneDeep: true }
    );
    this.ears.material = this.createMaterial();
    setThreeVector3(
      this.ears.position,
      BASE_CHARACTER_MESH_CONFIG.ears[config.race][config.gender].position
    );
    bonesMap.headTop.add(this.ears);
  }

  /**
   * Gets the mesh itself
   */
  getMesh() {
    return this.rootGroup;
  }

  /*
   * Adds a color to a specific part in the character
   */
  private colorPart(
    mesh: THREE.Object3D,
    color: string,
    options: { recursive?: boolean } = {}
  ) {
    const { canvas: skinCanvas } = createCanvas(1, 1, color);
    if (mesh instanceof THREE.Mesh) {
      (mesh.material as THREE.MeshPhongMaterial).map?.dispose();
      (mesh.material as THREE.MeshPhongMaterial).map = new THREE.CanvasTexture(
        skinCanvas
      );
    }
    if (options.recursive) {
      // Recursively add to children
      for (const child of mesh.children) {
        this.colorPart(child, color, { recursive: true });
      }
    }
  }

  private createHairMaterial(): THREE.Material {
    return new THREE.MeshPhongMaterial();
  }

  /**
   * Creates the material to use
   */
  private createMaterial(): THREE.Material {
    return new THREE.MeshPhongMaterial();
  }
}
