import menuMusic from "@assets/music/menu-theme.mp3";
import mainMusic from "@assets/music/main-theme.mp3";
import forestMusic from "@assets/music/forest.mp3";
import battleThemeMusic from "@assets/music/battle-theme.mp3";

export const MUSIC_ASSET_MAP = {
  menu: menuMusic,
  main: mainMusic,
  forest: forestMusic,
  battleTheme: battleThemeMusic,
} as const;

export type MusicAsset = keyof typeof MUSIC_ASSET_MAP;
