import { Navigate, createBrowserRouter } from "react-router-dom";
import "./index.css";
import { lazy } from "react";
import { createRealmsbaseRoutes } from "realmsbase-dashboard";
import { getEnvironment } from "realms-react-utils";
import { AssetGuard } from "@page-guards/AssetGuard";
import { AdminGuard } from "@page-guards/AdminGuard";
import { NinePatchImageEditor } from "mmo-ui";
import { Box } from "@chakra-ui/react";
import { LoadingProvider } from "@utils/loading/useLoading";
import { Environment } from "realms-utils";

console.log(`Arcadian Realms ${getEnvironment()} mode`);
const IS_DEV = getEnvironment() === Environment.Development;

// Code splitting pages

const CharacterCreationPage = lazy(() =>
  import("./pages/character-creation-page/CharacterCreationPage").then(
    ({ CharacterCreationPage }) => ({ default: CharacterCreationPage })
  )
);

const CharacterSelectionPage = lazy(() =>
  import("./pages/character-selection-page/CharacterSelectionPage").then(
    ({ CharacterSelectionPage }) => ({ default: CharacterSelectionPage })
  )
);

const GamePage = lazy(() =>
  import("./pages/game-page/GamePage").then(({ GamePage }) => ({
    default: GamePage,
  }))
);

const LoginPage = lazy(() =>
  import("./pages/LoginPage").then(({ LoginPage }) => ({ default: LoginPage }))
);

const RegisterPage = lazy(() =>
  import("./pages/RegisterPage").then(({ RegisterPage }) => ({
    default: RegisterPage,
  }))
);

const SetTokenPage = lazy(() =>
  import("./pages/internal/SetTokenPage").then(({ SetTokenPage }) => ({
    default: SetTokenPage,
  }))
);

const AdminDashboardPage = lazy(() =>
  import("./pages/admin/admin-dashboard-page/AdminDashboardPage").then(
    ({ AdminDashboardPage }) => ({
      default: AdminDashboardPage,
    })
  )
);

const ThreePlaygroundPage = lazy(() =>
  import(
    "./pages/admin/playground/three-playground-page/ThreePlaygroundPage"
  ).then(({ ThreePlaygroundPage }) => ({ default: ThreePlaygroundPage }))
);

const BaseCharacterPlayground = lazy(() =>
  import("./pages/dev/base-character-playground/BaseCharacterPlayground").then(
    ({ BaseCharacterPlayground }) => ({ default: BaseCharacterPlayground })
  )
);

const TextureTool = lazy(() =>
  import("./pages/dev/texture/TextureTool").then(({ TextureTool }) => ({
    default: TextureTool,
  }))
);

const CanvasUIPage = lazy(() =>
  import("./pages/admin/playground/canvas-ui/CanvasUIPage").then(
    ({ CanvasUIPage }) => ({ default: CanvasUIPage })
  )
);

const GameLogo = lazy(() =>
  import("./components/GameLogo").then(({ GameLogo }) => ({
    default: GameLogo,
  }))
);

const TestThreeRenderPage = lazy(() =>
  import("./pages/dev/test-three-render/TestThreeRender").then(
    ({ TestThreeRender }) => ({ default: TestThreeRender })
  )
);

// Add to routes
const ROUTES = [
  {
    path: "/character",
    children: [
      {
        path: "select",
        element: (
          <LoadingProvider>
            <AssetGuard>
              <CharacterSelectionPage />
            </AssetGuard>
          </LoadingProvider>
        ),
      },
      {
        path: "create",
        element: (
          <LoadingProvider>
            <AssetGuard>
              <CharacterCreationPage />
            </AssetGuard>
          </LoadingProvider>
        ),
      },
    ],
  },
  {
    path: "/internal",
    children: [
      {
        path: "set-token",
        element: <SetTokenPage />,
      },
    ],
  },
  {
    path: "/game",
    element: (
      <LoadingProvider>
        <AssetGuard>
          <GamePage />
        </AssetGuard>
      </LoadingProvider>
    ),
  },
  {
    path: "/login",
    element: (
      <LoadingProvider>
        <LoginPage />
      </LoadingProvider>
    ),
  },
  {
    path: "/register",
    element: (
      <LoadingProvider>
        <RegisterPage />
      </LoadingProvider>
    ),
  },
  ...createRealmsbaseRoutes("/admin/realmsbase/"),
  {
    path: "/admin",
    children: [
      {
        path: "playground",
        children: [
          {
            path: "canvas-ui",
            element: <CanvasUIPage />,
          },
          {
            path: "",
            element: (
              <LoadingProvider>
                <AdminGuard>
                  <ThreePlaygroundPage />
                </AdminGuard>
              </LoadingProvider>
            ),
          },
        ],
      },
      {
        path: "",
        element: (
          <LoadingProvider>
            <AdminGuard>
              <AdminDashboardPage />
            </AdminGuard>
          </LoadingProvider>
        ),
      },
    ],
  },

  // Dev pages
  ...(IS_DEV
    ? [
        {
          path: "/nine-patch-image",
          element: <NinePatchImageEditor />,
        },
        {
          path: "/base-character-playground",
          element: (
            <LoadingProvider>
              <AssetGuard>
                <BaseCharacterPlayground />
              </AssetGuard>
            </LoadingProvider>
          ),
        },
        {
          path: "/texture-tool",
          element: (
            <LoadingProvider>
              <AssetGuard>
                <TextureTool />
              </AssetGuard>
            </LoadingProvider>
          ),
        },
        {
          path: "/test-three-render",
          element: (
            <AssetGuard>
              <TestThreeRenderPage />
            </AssetGuard>
          ),
        },
        {
          path: "/logo",
          element: (
            <Box
              height={"100vh"}
              width={"100vw"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <GameLogo sizePX={window.innerHeight / 3} />
            </Box>
          ),
        },
      ]
    : []),
  {
    path: "*",
    element: <Navigate to="/login" />,
  },
];

export const ROUTER = createBrowserRouter(ROUTES);
