"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useKeyboardListener = void 0;
var react_1 = require("react");
var realms_browser_utils_1 = require("realms-browser-utils");
/**
 * Listens to keyboard events
 */
var useKeyboardListener = function (_a) {
    var onKeyboardEvent = _a.onKeyboardEvent;
    var listenerRef = (0, react_1.useRef)(new realms_browser_utils_1.KeyboardListener());
    (0, react_1.useEffect)(function () {
        var listener = listenerRef.current;
        listener.subscribe();
        listener.event.addListener("keyboardEvent", onKeyboardEvent);
        return function () {
            listener.event.removeListener("keyboardEvent", onKeyboardEvent);
            listener.unsubscribe();
        };
    }, [onKeyboardEvent]);
};
exports.useKeyboardListener = useKeyboardListener;
