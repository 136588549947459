"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhysicsPredictionSystem = void 0;
var realms_engine_1 = require("realms-engine");
var networkSlaveSystem_1 = require("./networkSlaveSystem");
var physicsPredictionGameObject_1 = require("./physicsPredictionGameObject");
var PhysicsPredictionSystem = /** @class */ (function (_super) {
    __extends(PhysicsPredictionSystem, _super);
    function PhysicsPredictionSystem() {
        var _this = _super.call(this, [physicsPredictionGameObject_1.PhysicsPredictionGameObject], [networkSlaveSystem_1.NetworkSlaveSystem]) || this;
        _this.physicsBodyIdMap = new Map();
        return _this;
    }
    /**
     * Gets the given physics prediction game object
     */
    PhysicsPredictionSystem.prototype.getPhysicsPredictionGameObject = function (physicsGameObjectId) {
        var _a;
        return (_a = this.physicsBodyIdMap.get(physicsGameObjectId)) !== null && _a !== void 0 ? _a : null;
    };
    PhysicsPredictionSystem.prototype.onTargetGameObjectEnter = function (gameObject) {
        if (gameObject instanceof physicsPredictionGameObject_1.PhysicsPredictionGameObject) {
            this.physicsBodyIdMap.set(gameObject.getPhysicsGameObjectId(), gameObject);
        }
    };
    PhysicsPredictionSystem.prototype.onTargetGameObjectExit = function (gameObject) {
        if (gameObject instanceof physicsPredictionGameObject_1.PhysicsPredictionGameObject) {
            this.physicsBodyIdMap.delete(gameObject.getPhysicsGameObjectId());
        }
    };
    return PhysicsPredictionSystem;
}(realms_engine_1.System));
exports.PhysicsPredictionSystem = PhysicsPredictionSystem;
