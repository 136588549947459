import { LocalStorageKey, LocalStorageValue } from "./mapping";

export class LocalStorageService {
  /**
   * Gets a given item
   */
  getItem<T extends LocalStorageKey>(key: T): LocalStorageValue<T> | null {
    const item = window.localStorage.getItem(key);
    if (item === null) {
      return null;
    }
    return JSON.parse(item).value;
  }

  /**
   * Sets the item in local storage
   */
  setItem<T extends LocalStorageKey>(key: T, value: LocalStorageValue<T>) {
    window.localStorage.setItem(
      key,
      JSON.stringify({
        value,
      })
    );
  }
}
