"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPhysicsPredictionFactoryMap = void 0;
var physicsPredictionGameObject_1 = require("./physicsPredictionGameObject");
function createPhysicsPredictionFactoryMap() {
    return {
        physicsPrediction: function (state) {
            return new physicsPredictionGameObject_1.PhysicsPredictionGameObject(state);
        },
    };
}
exports.createPhysicsPredictionFactoryMap = createPhysicsPredictionFactoryMap;
