"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerMessageType = exports.ChatMessageSenderType = void 0;
var ChatMessageSenderType;
(function (ChatMessageSenderType) {
    ChatMessageSenderType["Player"] = "player";
    ChatMessageSenderType["Server"] = "server";
})(ChatMessageSenderType || (exports.ChatMessageSenderType = ChatMessageSenderType = {}));
var ServerMessageType;
(function (ServerMessageType) {
    ServerMessageType["Log"] = "log";
})(ServerMessageType || (exports.ServerMessageType = ServerMessageType = {}));
