import { Box, BoxProps } from "@chakra-ui/react";
import { GameLogo } from "@components/GameLogo";
import { LoadingTask } from "./useLoading";
import { BarLoader } from "react-spinners";
import { useScreenSize } from "realms-react-utils";
import { MMODivider } from "mmo-ui";

interface LoadingScreenProps extends BoxProps {
  task?: LoadingTask;
  progressBarTransitionMs?: number;
}

export const LoadingScreen = ({
  task,
  progressBarTransitionMs = 0,
  ...props
}: LoadingScreenProps) => {
  const { isDesktop } = useScreenSize();
  return (
    <Box
      pos={"fixed"}
      top={0}
      left={0}
      display={"flex"}
      flexDir={"column"}
      height={"100%"}
      width={"100%"}
      alignItems={"center"}
      justifyContent="center"
      zIndex={99999}
      backgroundColor={"gray.50"}
      {...props}
    >
      <GameLogo
        sizePX={isDesktop ? 250 : 150}
        opacity={"0.8"}
        hideText={true}
      />
      <MMODivider
        position={"relative"}
        top={"-20px"}
        minH={isDesktop ? "40px" : "30px"}
        fontSize={isDesktop ? "large" : "medium"}
        marginBottom={4}
        color={"blackAlpha.700"}
        width={"70%"}
        childrenWidth={"100%"}
        size={isDesktop ? "normal" : "small"}
      >
        {task ? task.meta.text : " "}
      </MMODivider>
      <Box
        minH={isDesktop ? "30px" : "20px"}
        width={"30%"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {!task ? null : task.meta.type === "spinner" ? (
          <BarLoader color="#D45079" width={"100%"} />
        ) : task.meta.type === "progress" ? (
          <Box
            width={"100%"}
            height={1}
            backgroundColor={"gray.600"}
            borderRadius={"full"}
            overflow={"hidden"}
          >
            <Box
              height={"100%"}
              backgroundColor={"#D45079"}
              width={`${(100 * task.meta.value) / task.meta.max}%`}
              transition={`width ${progressBarTransitionMs / 1000}s`}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
