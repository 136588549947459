import {
  BaseCharacterRace,
  BaseCharacterGender,
  BaseCharacterHairBase,
  BaseCharacterHairBack,
  BaseCharacterHairFringe,
  BaseCharacterHairSide,
  BaseCharacterHairTail,
  BaseCharacterMoustache,
  BaseCharacterBeard,
} from "mmo-common";
import { Vector3 } from "realms-engine";

interface BaseCharacterMeshTransformationConfig {
  ears: {
    [v in BaseCharacterRace]: {
      [g in BaseCharacterGender]: {
        position: Vector3;
      };
    };
  };
  hairBase: {
    [v in Exclude<BaseCharacterHairBase, BaseCharacterHairBase.None>]: {
      [g in BaseCharacterGender]: {
        position: Vector3;
      };
    };
  };
  hairBack: {
    [v in Exclude<BaseCharacterHairBack, BaseCharacterHairBack.None>]: {
      [g in BaseCharacterGender]: {
        position: Vector3;
      };
    };
  };
  hairFringe: {
    [v in Exclude<BaseCharacterHairFringe, BaseCharacterHairFringe.None>]: {
      [g in BaseCharacterGender]: {
        position: Vector3;
      };
    };
  };
  hairSide: {
    [v in Exclude<BaseCharacterHairSide, BaseCharacterHairSide.None>]: {
      [g in BaseCharacterGender]: {
        position: Vector3;
      };
    };
  };
  hairTail: {
    [v in Exclude<BaseCharacterHairTail, BaseCharacterHairTail.None>]: {
      [g in BaseCharacterGender]: {
        position: Vector3;
      };
    };
  };
  moustache: {
    [v in Exclude<BaseCharacterMoustache, BaseCharacterMoustache.None>]: {
      position: Vector3;
    };
  };
  beard: {
    [v in Exclude<BaseCharacterBeard, BaseCharacterBeard.None>]: {
      position: Vector3;
    };
  };
}

export const BASE_CHARACTER_MESH_CONFIG: BaseCharacterMeshTransformationConfig =
  {
    ears: {
      [BaseCharacterRace.Human]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: -70,
            y: -500,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: -70,
            y: -500,
            z: 0,
          },
        },
      },
      [BaseCharacterRace.Elf]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: -70,
            y: -500,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: -70,
            y: -500,
            z: 0,
          },
        },
      },
      [BaseCharacterRace.Orc]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: -70,
            y: -500,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: -70,
            y: -500,
            z: 0,
          },
        },
      },
    },
    hairBase: {
      [BaseCharacterHairBase.Normal]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: -50,
            y: 50,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: -85,
            y: 50,
            z: 0,
          },
        },
      },
      [BaseCharacterHairBase.Mohawk]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: -50,
            y: 300,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: -30,
            y: 290,
            z: 0,
          },
        },
      },
      [BaseCharacterHairBase.Undercut]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: -120,
            y: 40,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: -120,
            y: 40,
            z: 0,
          },
        },
      },
      [BaseCharacterHairBase.Spiky]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: -90,
            y: -170,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: -100,
            y: -170,
            z: 0,
          },
        },
      },
    },
    hairBack: {
      [BaseCharacterHairBack.Short]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: -660,
            y: -400,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: -660,
            y: -400,
            z: 0,
          },
        },
      },
      [BaseCharacterHairBack.Long]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: -615,
            y: -890,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: -640,
            y: -860,
            z: 0,
          },
        },
      },
    },
    hairFringe: {
      [BaseCharacterHairFringe.Normal]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: 370,
            y: -100,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: 330,
            y: -100,
            z: 0,
          },
        },
      },
      [BaseCharacterHairFringe.Straight]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: 370,
            y: -100,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: 330,
            y: -100,
            z: 0,
          },
        },
      },
      [BaseCharacterHairFringe.MiddlePart]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: 380,
            y: -200,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: 340,
            y: -200,
            z: 0,
          },
        },
      },
      [BaseCharacterHairFringe.MiddlePartThick]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: 380,
            y: -200,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: 340,
            y: -200,
            z: 0,
          },
        },
      },
      [BaseCharacterHairFringe.StraightBangs]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: 370,
            y: -100,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: 350,
            y: -100,
            z: 0,
          },
        },
      },
    },
    hairSide: {
      [BaseCharacterHairSide.Normal]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: 200,
            y: -500,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: 200,
            y: -500,
            z: 0,
          },
        },
      },
    },
    hairTail: {
      [BaseCharacterHairTail.Ponytail]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: -800,
            y: -600,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: -850,
            y: -600,
            z: 0,
          },
        },
      },
      [BaseCharacterHairTail.Twin]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: 100,
            y: -300,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: 100,
            y: -300,
            z: 0,
          },
        },
      },
      [BaseCharacterHairTail.DoubleBun]: {
        [BaseCharacterGender.Male]: {
          position: {
            x: -450,
            y: -450,
            z: 0,
          },
        },
        [BaseCharacterGender.Female]: {
          position: {
            x: -450,
            y: -450,
            z: 0,
          },
        },
      },
    },
    moustache: {
      [BaseCharacterMoustache.Normal]: {
        position: {
          x: 450,
          y: -800,
          z: 0,
        },
      },
    },
    beard: {
      [BaseCharacterBeard.Normal]: {
        position: {
          x: 430,
          y: -1000,
          z: 0,
        },
      },
    },
  };
