import { hexToRgb } from "realms-utils";
import { MMOAssetManager } from "../../config";
import { createCanvas, processImage } from "../player/characterUtils";
import {
  VARIABLE_TEXTURE_COLOR,
  VARIABLE_TEXTURE_SECONDARY_COLOR,
} from "../player/constants";
import { BASE_CHARACTER_ASSET_MAPPING } from "./mapping";
import THREE from "realms-three";
import {
  BaseCharacterClothing,
  BaseCharacterMeshConfig,
  BaseCharacterPants,
  BaseCharacterShirt,
} from "mmo-common";
import { TEXTURE_PIXEL_MAP } from "./texture-pixel-map";
import { decompressPixelIndexes } from "./texture-metdata";

/**
 * Creates the texture for the character
 */
export function createBaseCharacterTexture(config: BaseCharacterMeshConfig) {
  const skinShadowTexture = createSkinShadowTexture(config);

  const { canvas, ctx } = createCanvas(
    skinShadowTexture.image.width,
    skinShadowTexture.image.width,
    config.skinColor
  );

  ctx.drawImage(skinShadowTexture.image, 0, 0);

  const raceTextureConfig =
    BASE_CHARACTER_ASSET_MAPPING.common.raceTexture[config.race];
  if (raceTextureConfig) {
    ctx.drawImage(
      MMOAssetManager.getTexture(raceTextureConfig[config.gender], {
        clone: true,
      }).image,
      0,
      0
    );
  }

  const clothingTexture = createClothingTexture(config);
  if (clothingTexture) {
    ctx.drawImage(clothingTexture.image, 0, 0);
  }

  const pantsTexture = createPantsTexture(config);
  ctx.drawImage(pantsTexture.image, 0, 0);

  const shirtTexture = createShirtTexture(config);
  if (shirtTexture) {
    ctx.drawImage(shirtTexture.image, 0, 0);
  }

  ctx.drawImage(createEyesTexture(config), 0, 0);
  return new THREE.CanvasTexture(canvas);
}

/**
 * Creates a skin shadow texture
 */
function createSkinShadowTexture(config: BaseCharacterMeshConfig) {
  return MMOAssetManager.getTexture(
    BASE_CHARACTER_ASSET_MAPPING.gendered[config.gender].skinShadow,
    {
      clone: true,
    }
  );
}

/**
 * Creates the clothing texture
 */
function createClothingTexture(config: BaseCharacterMeshConfig) {
  if (config.clothing === BaseCharacterClothing.None) {
    return;
  }
  return MMOAssetManager.getTexture(
    BASE_CHARACTER_ASSET_MAPPING.gendered[config.gender].clothing[
      config.clothing
    ],
    {
      clone: true,
    }
  );
}

function createShirtTexture(config: BaseCharacterMeshConfig) {
  if (config.shirt === BaseCharacterShirt.None) {
    const shirtAsset =
      BASE_CHARACTER_ASSET_MAPPING.gendered[config.gender].defaultShirt;
    if (!shirtAsset) {
      return null;
    }
    return MMOAssetManager.getTexture(shirtAsset, { clone: true });
  }
  return MMOAssetManager.getTexture(
    BASE_CHARACTER_ASSET_MAPPING.gendered[config.gender].shirt[config.shirt],
    {
      clone: true,
    }
  );
}

/**
 * Creates pants texture
 */
function createPantsTexture(config: BaseCharacterMeshConfig) {
  if (config.pants === BaseCharacterPants.None) {
    return MMOAssetManager.getTexture(
      BASE_CHARACTER_ASSET_MAPPING.gendered[config.gender].defaultPants,
      {
        clone: true,
      }
    );
  }
  return MMOAssetManager.getTexture(
    BASE_CHARACTER_ASSET_MAPPING.gendered[config.gender].pants[config.pants],
    {
      clone: true,
    }
  );
}

/**
 * Creates the eye texture
 */
function createEyesTexture(config: BaseCharacterMeshConfig) {
  const targetPixels: number[] = [];
  const assetKey =
    BASE_CHARACTER_ASSET_MAPPING.gendered[config.gender].eyes[config.eyes];
  const pixelIndexes = TEXTURE_PIXEL_MAP[assetKey];
  const cachedTargetPixels = pixelIndexes
    ? decompressPixelIndexes(pixelIndexes)
    : undefined;

  if (!cachedTargetPixels) {
    console.warn(`texture ${assetKey} is missing a pixel map`);
  }

  const eyesTexture = processImage(
    MMOAssetManager.getTexture(assetKey, {
      clone: true,
    }).image,
    ({ r, g, b, a, i }) => {
      const rgbVariableColor = hexToRgb(VARIABLE_TEXTURE_COLOR);
      if (
        r === rgbVariableColor.r &&
        g === rgbVariableColor.g &&
        b === rgbVariableColor.b
      ) {
        if (!cachedTargetPixels) {
          targetPixels.push(i);
        }
        return { ...hexToRgb(config.eyeColor), a };
      }
      const rgbVariableSecondaryColor = hexToRgb(
        VARIABLE_TEXTURE_SECONDARY_COLOR
      );
      if (
        r === rgbVariableSecondaryColor.r &&
        g === rgbVariableSecondaryColor.g &&
        b === rgbVariableSecondaryColor.b
      ) {
        if (!cachedTargetPixels) {
          targetPixels.push(i);
        }
        return { ...hexToRgb(config.hairColor), a };
      }

      return { r, g, b, a };
    },
    {
      targetPixels: cachedTargetPixels ?? undefined,
    }
  );
  return eyesTexture;
}
