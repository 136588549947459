import {
  GameAuth,
  MMONetSchema,
  MMORPCError,
  MMORPCParams,
  MMORPCProcedure,
  MMORPCResponse,
  MMOServerEvent,
  MinimalInteractableData,
} from "mmo-common";
import {
  NetworkSlaveSystem,
  RealmsEngineClient,
  SocketIOServerConnection,
} from "realms-engine-network-client";
import { MMOConfig } from "../../configs";
import { RealmAssetLocationType, RealmBlueprint } from "realmforge-common";
import { RealmBlueprintRenderBuilder, getAssetFileName } from "realmforge-lib";
import THREE from "realms-three";
import { Realm } from "realms-engine";
import { BrowserRealmRunner, Render3DSystem } from "realms-engine-browser";
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  delay,
  firstValueFrom,
} from "rxjs";
import {
  MMORealmEngineGroups,
  mmoSyncSchemaMap,
  createRealm,
  PlayerSystem,
  InteractableSystem,
} from "../../game";
import { NetworkRealmState } from "realms-engine-network";
import { WindowSize } from "realms-react-utils";
import { CleanUp, EventEmitter, EventManager } from "realms-utils";

export enum MMOGameClientState {
  Inactive = "inactive",
  Initialised = "initialised",
  Connected = "connected",
}

export interface MMOGameClientServiceEventsMap {
  [MMOServerEvent.QuestStateChange]: MMONetSchema["NMap"][MMOServerEvent.QuestStateChange];
  [MMOServerEvent.ChatMessage]: MMONetSchema["NMap"][MMOServerEvent.ChatMessage];
}

export class MMOGameClientService
  implements EventEmitter<MMOGameClientServiceEventsMap>
{
  private eventManager = new EventManager<MMOGameClientServiceEventsMap>();
  addListener = this.eventManager.addListener;
  removeListener = this.eventManager.removeListener;
  once = this.eventManager.once;

  private blueprintBuilder: RealmBlueprintRenderBuilder;
  private realmsEngineClient: RealmsEngineClient<MMONetSchema>;
  private windowSize = new WindowSize();
  private cleanUpManager = new CleanUp();
  private characterId: number | null = null;

  // Subjects
  private realmSubject$ = new BehaviorSubject<Realm | undefined>(undefined);
  private healthSubject$ = new ReplaySubject<number>(1);
  private maxHealthSubject$ = new ReplaySubject<number>(1);
  private pingSubject$ = new ReplaySubject<number>(1);
  private fpsSubject$ = new ReplaySubject<number>(1);
  private stateSubject$ = new BehaviorSubject(MMOGameClientState.Inactive);
  private networkRealmStateSubject$ = new ReplaySubject<
    NetworkRealmState<MMONetSchema>
  >(1);
  private realmBlueprintReceivedSubject$ = new BehaviorSubject(false);
  private currentInteractableSubject$ =
    new BehaviorSubject<MinimalInteractableData | null>(null);

  // Observables
  readonly realm$ = this.realmSubject$.asObservable();
  readonly health$ = this.healthSubject$.asObservable();
  readonly maxHealth$ = this.maxHealthSubject$.asObservable();
  readonly ping$ = this.pingSubject$.asObservable();
  readonly fps$ = this.fpsSubject$.asObservable();
  readonly state$ = this.stateSubject$.asObservable();
  readonly networkRealmState$ = this.networkRealmStateSubject$.asObservable();
  readonly realmBlueprintReceived$ =
    this.realmBlueprintReceivedSubject$.asObservable();
  readonly isLoaded$: Observable<boolean>;
  readonly currentInteractable$ =
    this.currentInteractableSubject$.asObservable();

  get realm() {
    return this.realmSubject$.getValue();
  }

  constructor() {
    this.blueprintBuilder = new RealmBlueprintRenderBuilder({
      gameObjectGroup: MMORealmEngineGroups.BlueprintGroup,
      getAssetTexture: async (asset) => {
        const fileName = getAssetFileName(asset);
        const textureLoader = new THREE.TextureLoader();
        try {
          const texture = await textureLoader.loadAsync(
            asset.location === RealmAssetLocationType.Local
              ? `/assets/realms/${fileName}`
              : asset.remoteSrc
          );
          return texture;
        } catch (e) {
          throw new Error(`unable to load ${fileName}`);
        }
      },
    });

    this.realmsEngineClient = new RealmsEngineClient<MMONetSchema>(
      // new UWebsocketsServerConnection(MMOConfig.serverUrl, {
      //   ssl: getEnvironment() === Environment.Production,
      // }),
      // TODO: UWebsockets lead to OOM - please fix
      new SocketIOServerConnection(MMOConfig.serverUrl),
      mmoSyncSchemaMap,
      {
        ping: true,
        onServerEvent: (event, data) => {
          switch (event) {
            case MMOServerEvent.CreateRealmBlueprint: {
              const realmBlueprint = data as RealmBlueprint;
              if (this.realm) {
                this.blueprintBuilder.clearBlueprint(this.realm);
                this.blueprintBuilder
                  .buildRealmBlueprint(this.realm, realmBlueprint)
                  .catch((e) => {
                    console.error(e);
                  });

                // Resync state
                this.realm.systems
                  .get(PlayerSystem)
                  .getPlayerGameObject()
                  ?.syncWithMaster();

                this.realmBlueprintReceivedSubject$.next(true);
              }
              break;
            }
            case MMOServerEvent.QuestStateChange: {
              this.eventManager.dispatch(
                MMOServerEvent.QuestStateChange,
                data as MMONetSchema["NMap"][MMOServerEvent.QuestStateChange]
              );
              break;
            }
            case MMOServerEvent.ChatMessage: {
              const messageData =
                data as MMONetSchema["NMap"][MMOServerEvent.ChatMessage];
              this.eventManager.dispatch(
                MMOServerEvent.ChatMessage,
                messageData
              );
              if (this.realm) {
                const playerSystem = this.realm.systems.get(PlayerSystem);
                playerSystem.handleChatMessage(messageData);
              }
              break;
            }
          }
        },
      }
    );

    this.isLoaded$ = this.realmBlueprintReceived$.pipe(delay(1000));
  }

  getCharacterId() {
    return this.characterId;
  }

  /**
   * Makes an rpc call
   */
  rpc = async <T extends MMORPCProcedure>(
    procedure: T,
    params: MMORPCParams<T>
  ): Promise<MMORPCResponse<T> | MMORPCError> => {
    return this.realmsEngineClient.rpc(procedure, params);
  };

  /**
   * Initialises the realm
   */
  async initialise({
    characterId,
    canvas,
    token,
    roomToken,
  }: {
    characterId: number;
    canvas: HTMLCanvasElement;
    token: string;
    roomToken?: string;
  }) {
    // Not inactive so ignore
    if ((await firstValueFrom(this.state$)) !== MMOGameClientState.Inactive) {
      return;
    }

    this.characterId = characterId;

    // Subscribe to window size changes
    this.windowSize.subscribe();
    this.cleanUpManager.onCleanUp(() => {
      this.windowSize.unsubscribe();
    });

    const width = await firstValueFrom(this.windowSize.width$);
    const height = await firstValueFrom(this.windowSize.height$);

    const realm = createRealm({
      characterId,
      client: this.realmsEngineClient,
      canvas,
      width,
      height,
    });
    this.realmSubject$.next(realm);

    const windowSizeSubscription = combineLatest([
      this.windowSize.width$,
      this.windowSize.height$,
    ]).subscribe(([width, height]) => {
      realm.systems.get(Render3DSystem).updateSize({ width, height });
    });

    this.cleanUpManager.onCleanUp(() => {
      windowSizeSubscription.unsubscribe();
    });

    const realmRunner = new BrowserRealmRunner(realm);
    realmRunner.run(200);

    const onFPSChange = (fps: number) => {
      this.fpsSubject$.next(fps);
    };
    realmRunner.fpsMonitor.event.addListener("fpsChange", onFPSChange);
    this.cleanUpManager.onCleanUp(() => {
      realmRunner.fpsMonitor.event.removeListener("fpsChange", onFPSChange);
    });

    const healthChangeListener = (e: { health: number; maxHealth: number }) => {
      this.healthSubject$.next(e.health);
      this.maxHealthSubject$.next(e.maxHealth);
    };

    const interactableListener = (e: MinimalInteractableData | null) => {
      this.currentInteractableSubject$.next(e);
    };

    // Add listeners
    const onStateStep = (state: NetworkRealmState<MMONetSchema>) => {
      this.networkRealmStateSubject$.next(state);
    };
    realm.systems
      .get<NetworkSlaveSystem<MMONetSchema>>(NetworkSlaveSystem)
      .addListener("onStateStep", onStateStep);
    this.cleanUpManager.onCleanUp(() => {
      realm.systems
        .get<NetworkSlaveSystem<MMONetSchema>>(NetworkSlaveSystem)
        .removeListener("onStateStep", onStateStep);
    });

    realm.systems
      .get(PlayerSystem)
      .addListener("healthChange", healthChangeListener);

    this.cleanUpManager.onCleanUp(() => {
      realm.systems
        .get(PlayerSystem)
        .removeListener("healthChange", healthChangeListener);
    });

    realm.systems
      .get(InteractableSystem)
      .addListener("onCurrentInteractableChange", interactableListener);

    this.cleanUpManager.onCleanUp(() => {
      realm.systems
        .get(InteractableSystem)
        .removeListener("onCurrentInteractableChange", interactableListener);
    });

    this.cleanUpManager.onCleanUp(() => {
      realm.clean();
      realmRunner.end();
    });

    // On connect
    const onConnect = () => {
      this.stateSubject$.next(MMOGameClientState.Connected);
    };
    this.realmsEngineClient.getConnection().addListener("connect", onConnect);

    this.cleanUpManager.onCleanUp(() => {
      this.realmsEngineClient
        .getConnection()
        .removeListener("connect", onConnect);
    });

    // On disconnect
    const onDisconnect = () => {
      this.stateSubject$.next(MMOGameClientState.Initialised);
    };
    this.realmsEngineClient
      .getConnection()
      .addListener("disconnect", onDisconnect);
    this.cleanUpManager.onCleanUp(() => {
      this.realmsEngineClient
        .getConnection()
        .removeListener("disconnect", onDisconnect);
    });

    this.stateSubject$.next(MMOGameClientState.Initialised);

    this.connect({ token, characterId, roomToken });
  }

  /**
   * Connects to the server
   */
  private connect({
    token,
    characterId,
    roomToken,
  }: {
    token: string;
    characterId: number;
    roomToken?: string;
  }) {
    if (this.realmsEngineClient.isConnected()) {
      return;
    }
    const auth: GameAuth = {
      token,
      characterId,
      ...(roomToken ? { roomToken } : {}),
    };

    // Add listeners
    const onPingUpdate = (ping: number) => {
      this.pingSubject$.next(ping);
    };
    this.realmsEngineClient.addListener("pingUpdate", onPingUpdate);
    this.cleanUpManager.onCleanUp(() => {
      this.realmsEngineClient.removeListener("pingUpdate", onPingUpdate);
    });

    this.cleanUpManager.onCleanUp(() => {
      this.realmsEngineClient.disconnect();
    });

    // Connect
    this.realmsEngineClient.connect(auth);
  }

  /**
   * Cleans up the client
   */
  cleanup() {
    this.cleanUpManager.cleanUp();
    this.stateSubject$.next(MMOGameClientState.Inactive);
  }

  isInitialised() {
    return this.stateSubject$.getValue() !== MMOGameClientState.Inactive;
  }
}
