import {
  CreatureAnimation,
  CreatureData,
  GoblinConfig,
  MMONetSchema,
} from "mmo-common";
import { ConfigurableMeshGameObject } from "../utils/configurableMeshGameObject";
import { GoblinMesh } from "./goblinMesh";
import { NetworkSlave } from "realms-engine-network-client";
import { CreatureUIGameObject } from "../ui/creatureUI.gameObject";
import { toThreeQuaternion } from "realms-engine-browser";
import THREE from "realms-three";
import { quatToVector2D, vector2Angle } from "realms-engine";

const GOBLIN_ANIMATION_MAP = {
  [CreatureAnimation.Walk]: "goblinWalkAnimation",
  walkLeft: "goblinWalkLeftAnimation",
  walkRight: "goblinWalkRightAnimation",
  walkBack: "goblinWalkBackAnimation",
  [CreatureAnimation.Idle]: "goblinIdleAnimation",
  [CreatureAnimation.Death]: "goblinDeathAnimation",
  [CreatureAnimation.Jump]: "goblinJumpAnimation",
  [CreatureAnimation.NormalAttack1]: "goblinAttackAnimation",
  [CreatureAnimation.NormalAttack2]: "goblinAttackAnimation",
  [CreatureAnimation.NormalAttack3]: "goblinAttackAnimation",
};

export class GoblinGameObject
  extends ConfigurableMeshGameObject<GoblinConfig, typeof GOBLIN_ANIMATION_MAP>
  implements NetworkSlave<MMONetSchema["TMap"]["goblin"]>
{
  private ui: CreatureUIGameObject;

  constructor(config: GoblinConfig, data: CreatureData) {
    super(new GoblinMesh(config), GOBLIN_ANIMATION_MAP);
    this.ui = this.addChild(new CreatureUIGameObject(data));
  }

  onSlaveChanges(changes: Partial<CreatureData>, newState: CreatureData): void {
    if (changes.position !== undefined) {
      this.setPosition(newState.position);
    }
    if (changes.quaternion !== undefined) {
      this.setQuaternion(
        toThreeQuaternion(newState.quaternion).multiply(
          new THREE.Quaternion().setFromAxisAngle(
            new THREE.Vector3(0, 1, 0),
            (3 * Math.PI) / 2
          )
        )
      );
    }

    if (changes.health !== undefined) {
      this.ui.setHealth(newState.health);
    }

    if (changes.maxHealth !== undefined) {
      this.ui.setMaxHealth(newState.maxHealth);
    }

    if (changes.animation?.name === CreatureAnimation.Death) {
      (this.getConfigurableMesh() as GoblinMesh).dissolve({
        start: 1,
        end: 0,
        durationMs: 3000,
        color: "#E53E3E",
      });
    }

    if (newState.animation.name === CreatureAnimation.Walk) {
      const faceDirection = quatToVector2D(newState.quaternion);
      const angleDifference =
        vector2Angle(faceDirection) - vector2Angle(newState.moveDirection);
      this.playAnimation({
        ...newState.animation,
        name:
          angleDifference > Math.PI / 4 && angleDifference <= (7 * Math.PI) / 8
            ? "walkLeft"
            : angleDifference < -Math.PI / 4 &&
              angleDifference >= (-7 * Math.PI) / 8
            ? "walkRight"
            : angleDifference > (7 * Math.PI) / 8 ||
              angleDifference < (-7 * Math.PI) / 8
            ? "walkBack"
            : CreatureAnimation.Walk,
      });
    } else if (changes.animation !== undefined) {
      this.playAnimation({
        ...newState.animation,
        name: newState.animation.name as keyof typeof GOBLIN_ANIMATION_MAP,
      });
    }

    if (changes.name !== undefined) {
      this.ui.setCreatureName(newState.name);
    }
  }
}
