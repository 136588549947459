import THREE from "realms-three";
import { GameObject } from "realms-engine";

export interface ConfigurableMeshEventsMap<T extends {}> {
  onMeshChange: ConfigurableMesh<T>;
}

export type ConfigurableMeshChanges<T extends {}> = {
  [k in keyof T]?: {
    oldValue: T[k];
    newValue: T[k];
  };
};

export interface ConfigurableMeshOptions {
  scale?: number;
}

/**
 * A mesh that can be configured
 */
export abstract class ConfigurableMesh<T extends {}> extends GameObject<
  ConfigurableMeshEventsMap<T>
> {
  private currentConfig: T;
  protected rootGroup: THREE.Object3D;

  constructor(
    protected baseMesh: THREE.Object3D,
    config: T,
    options: ConfigurableMeshOptions = {}
  ) {
    super();
    this.rootGroup = new THREE.Object3D();
    this.rootGroup.scale.setScalar(options.scale ?? 1);
    this.currentConfig = config;
    this.updateConfig(config, { ignoreCache: true });
  }

  /**
   * Updates the mesh config
   */
  updateConfig(config: T, options: { ignoreCache?: boolean } = {}) {
    const changes: ConfigurableMeshChanges<T> = {};
    for (const [k, v] of Object.entries(this.currentConfig)) {
      if (config[k as keyof T] !== v) {
        changes[k as keyof T] = {
          oldValue: v as T[keyof T],
          newValue: config[k as keyof T],
        };
      }
    }

    // No changes so ignore
    if (Object.entries(changes).length === 0 && !options.ignoreCache) {
      return;
    }

    // Update mesh changes

    const oldMesh = this.baseMesh;

    const newMesh = this.onConfigChanges(config, changes);
    if (oldMesh !== newMesh || options.ignoreCache) {
      this.rootGroup.remove(oldMesh);
      this.baseMesh = newMesh;
      this.rootGroup.add(newMesh);
      this.dispatch("onMeshChange", this);
      this.currentConfig = config;
    }
  }

  /**
   * Returns the mesh
   */
  getMesh() {
    return this.rootGroup;
  }

  /**
   * Updates the mesh on changes
   */
  protected abstract onConfigChanges(
    config: T,
    changes: ConfigurableMeshChanges<T>
  ): THREE.Object3D;
}
