import { Box } from "@chakra-ui/react";
import { TouchEventHandler, useCallback, useRef, useState } from "react";
import {
  Vector2,
  vector2Add,
  vector2Length,
  vector2Multiply,
  vector2Normalize,
  vector2Subtract,
} from "realms-engine";
import { BiSolidJoystickButton } from "react-icons/bi";

interface JoystickTouch {
  touchId: number;
  /**
   * Origin relative to client
   */
  origin: Vector2;
  /**
   * Position relative to origin
   */
  position: Vector2;
}

interface DynamicJoystickProps {
  onPositionChange: (position: Vector2) => void;
  joystickSizePx?: number;
  joystickCenterSizePx?: number;
}

export const DynamicJoystick = ({
  onPositionChange,
  joystickSizePx = 150,
  joystickCenterSizePx = 70,
}: DynamicJoystickProps) => {
  const areaRef = useRef<HTMLDivElement | null>(null);
  const [joystickTouch, setJoystickTouch] = useState<JoystickTouch | null>(
    null
  );

  const onTouchStart: TouchEventHandler<HTMLDivElement> = useCallback((e) => {
    const rect = areaRef.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }

    const targetTouch = Array.from(e.touches).find(
      (touch) =>
        touch.clientX >= rect.left &&
        touch.clientX <= rect.right &&
        touch.clientY >= rect.top &&
        touch.clientY <= rect.bottom
    );

    if (!targetTouch) {
      return;
    }

    setJoystickTouch({
      touchId: targetTouch.identifier,
      origin: { x: targetTouch.clientX, y: targetTouch.clientY },
      position: { x: 0, y: 0 },
    });
  }, []);

  const onTouchMove: TouchEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (!joystickTouch) {
        return;
      }
      const targetTouch = Array.from(e.touches).find(
        (touch) => touch.identifier === joystickTouch.touchId
      );
      if (!targetTouch) {
        return;
      }
      const touchPosition = { x: targetTouch.clientX, y: targetTouch.clientY };
      const displacement = vector2Subtract(touchPosition, joystickTouch.origin);
      const moveDistance = vector2Length(displacement);

      // Shift joystick center if moved too far away
      if (moveDistance >= joystickSizePx / 2) {
        const shiftOriginDistance = moveDistance - joystickSizePx / 2;
        const shiftOriginDisplacement = vector2Multiply(
          vector2Normalize(displacement),
          shiftOriginDistance
        );
        const origin = vector2Add(
          joystickTouch.origin,
          shiftOriginDisplacement
        );
        const position = vector2Subtract(touchPosition, origin);
        setJoystickTouch((touch) =>
          touch ? { ...touch, origin, position } : null
        );
        onPositionChange(position);
        return;
      }
      const position = vector2Subtract(touchPosition, joystickTouch.origin);
      // Just update center
      setJoystickTouch((touch) => (touch ? { ...touch, position } : null));
      onPositionChange(position);
    },
    [joystickSizePx, joystickTouch, onPositionChange]
  );

  const onTouchEnd: TouchEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault();
      setJoystickTouch(null);
      onPositionChange({ x: 0, y: 0 });
    },
    [onPositionChange]
  );

  return (
    <Box
      ref={areaRef}
      width={"100%"}
      height={"100%"}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
    >
      {joystickTouch ? (
        <Box
          backgroundColor={"blackAlpha.400"}
          position={"fixed"}
          left={joystickTouch.origin.x}
          top={joystickTouch.origin.y}
          width={`${joystickSizePx}px`}
          height={`${joystickSizePx}px`}
          borderRadius={"full"}
          transform={"translate(-50%, -50%)"}
        >
          <Box
            left={`calc(50% + ${joystickTouch.position.x}px)`}
            top={`calc(50% + ${joystickTouch.position.y}px)`}
            transform={"translate(-50%, -50%)"}
            position={"absolute"}
            backgroundColor={"red.600"}
            color={"red.900"}
            width={`${joystickCenterSizePx}px`}
            height={`${joystickCenterSizePx}px`}
            borderRadius={"full"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            boxShadow={
              "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
            }
          >
            <BiSolidJoystickButton size={joystickSizePx / 3} />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
