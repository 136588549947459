import { Box } from "@chakra-ui/react";

export const ShimmerEffect = () => {
  return (
    <Box
      position={"absolute"}
      bottom={0}
      top={0}
      right={0}
      left={0}
      background={
        "linear-gradient(-45deg, rgba(255,255,255,0) 40%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 60%)"
      }
      backgroundSize={"1000%"}
      style={{
        backgroundPositionX: "100%",
      }}
      animation={"shimmer 3s infinite linear"}
    />
  );
};
