"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NetworkPhysicsOutlineSystem = void 0;
var realms_engine_1 = require("realms-engine");
var networkPhysicsOutlineGameObject_1 = require("./networkPhysicsOutlineGameObject");
var networkSlaveSystem_1 = require("./networkSlaveSystem");
var NetworkPhysicsOutlineSystem = /** @class */ (function (_super) {
    __extends(NetworkPhysicsOutlineSystem, _super);
    function NetworkPhysicsOutlineSystem(_a) {
        var _b = _a === void 0 ? {} : _a, visibility = _b.visibility;
        var _this = _super.call(this, [networkPhysicsOutlineGameObject_1.NetworkPhysicsOutlineGameObject], [networkSlaveSystem_1.NetworkSlaveSystem]) || this;
        _this.visibility = visibility !== undefined ? visibility : false;
        return _this;
    }
    NetworkPhysicsOutlineSystem.prototype.onTargetGameObjectEnter = function (gameObject) {
        if (gameObject instanceof networkPhysicsOutlineGameObject_1.NetworkPhysicsOutlineGameObject) {
            this.setGameObjectVisbility(gameObject);
        }
    };
    NetworkPhysicsOutlineSystem.prototype.getVisibility = function () {
        return this.visibility;
    };
    NetworkPhysicsOutlineSystem.prototype.setVisibility = function (isVisible) {
        var _this = this;
        this.visibility = isVisible;
        // Update all current gameObjects visibility
        this.getTargetGameObjects().forEach(function (gameObject) {
            if (gameObject instanceof networkPhysicsOutlineGameObject_1.NetworkPhysicsOutlineGameObject) {
                _this.setGameObjectVisbility(gameObject);
            }
        });
    };
    NetworkPhysicsOutlineSystem.prototype.setGameObjectVisbility = function (gameObject) {
        gameObject.setVisible(this.visibility);
    };
    return NetworkPhysicsOutlineSystem;
}(realms_engine_1.System));
exports.NetworkPhysicsOutlineSystem = NetworkPhysicsOutlineSystem;
