import { createCanvas } from "../player/characterUtils";
import THREE from "realms-three";

/**
 * Combine multiple textures into one
 */
export const combineTextures = (
  textures: THREE.Texture[],
  options: { baseColor?: string } = {}
) => {
  let canvas: HTMLCanvasElement | null = null;
  let ctx: CanvasRenderingContext2D | null = null;
  for (const texture of textures) {
    // Create canvas if not exists
    if (!canvas || !ctx) {
      ({ canvas, ctx } = createCanvas(
        texture.image.width,
        texture.image.height,
        options.baseColor ?? "#ffffff"
      ));
    }
    ctx.drawImage(texture.image, 0, 0);
  }

  if (!canvas) {
    throw new Error(`No textures given`);
  }
  return new THREE.CanvasTexture(canvas);
};
