"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoblinClothing = exports.GoblinFeatures = exports.GoblinSkin = void 0;
var GoblinSkin;
(function (GoblinSkin) {
    GoblinSkin["Green"] = "green";
})(GoblinSkin || (exports.GoblinSkin = GoblinSkin = {}));
var GoblinFeatures;
(function (GoblinFeatures) {
    GoblinFeatures["Normal"] = "normal";
    GoblinFeatures["Fierce"] = "fierce";
})(GoblinFeatures || (exports.GoblinFeatures = GoblinFeatures = {}));
var GoblinClothing;
(function (GoblinClothing) {
    GoblinClothing["Barbaric"] = "barbaric";
    GoblinClothing["Armoured"] = "armoured";
})(GoblinClothing || (exports.GoblinClothing = GoblinClothing = {}));
