"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimerGameObject = void 0;
var core_1 = require("../core");
/*
 * Starts a timer
 */
var TimerGameObject = /** @class */ (function (_super) {
    __extends(TimerGameObject, _super);
    function TimerGameObject() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loop = false;
        _this.timerDuration = null;
        _this.timeLeft = null;
        return _this;
    }
    /**
     * Starts the timer. If the timer is already running, restarts it
     */
    TimerGameObject.prototype.start = function (timerDurationInMS, options) {
        var _a;
        if (options === void 0) { options = {}; }
        this.timerDuration = timerDurationInMS;
        this.timeLeft = timerDurationInMS;
        this.loop = (_a = options.loop) !== null && _a !== void 0 ? _a : false;
    };
    /**
     * Stops the timer pre maturely. Does not emit event
     */
    TimerGameObject.prototype.stop = function () {
        this.timerDuration = null;
        this.timeLeft = null;
    };
    /**
     * Returns whether the timer is still running. When a timer reaches the end,
     * this will be false
     */
    TimerGameObject.prototype.isRunning = function () {
        return this.timeLeft !== null && this.timerDuration !== null;
    };
    TimerGameObject.prototype.step = function (delta) {
        if (this.timeLeft !== null && this.timerDuration !== null) {
            this.timeLeft = Math.max(0, this.timeLeft - delta);
            if (this.timeLeft === 0) {
                this.dispatch("onFinish", this);
                if (this.loop) {
                    this.timeLeft = this.timerDuration;
                }
                else {
                    this.stop();
                }
            }
        }
    };
    return TimerGameObject;
}(core_1.ProcessGameObject));
exports.TimerGameObject = TimerGameObject;
