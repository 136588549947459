"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFriendly = exports.HostilityType = void 0;
var HostilityType;
(function (HostilityType) {
    /**
     * Players and all friendly NPCs
     */
    HostilityType["Ally"] = "ally";
    /**
     * No team. Cannot be hurt by either team nor hurt either team
     */
    HostilityType["Neutral"] = "neutral";
    /**
     * All enemies
     */
    HostilityType["Enemy"] = "enemy";
})(HostilityType || (exports.HostilityType = HostilityType = {}));
/**
 * Returns if friendly
 */
var isFriendly = function (selfHostility, targetHostility) {
    if (selfHostility === HostilityType.Ally) {
        return targetHostility !== HostilityType.Enemy;
    }
    if (selfHostility === HostilityType.Enemy) {
        return targetHostility !== HostilityType.Ally;
    }
    // Neutral is friendly to anyone
    return true;
};
exports.isFriendly = isFriendly;
