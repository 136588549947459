"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RealmBlueprintPhysicsBuilder = void 0;
var realms_engine_1 = require("realms-engine");
var physics_model_1 = require("./physics.model");
var realms_utils_1 = require("realms-utils");
var RealmBlueprintPhysicsBuilder = /** @class */ (function () {
    function RealmBlueprintPhysicsBuilder(gameObjectGroup) {
        this.gameObjectGroup = gameObjectGroup;
    }
    /**
     * Builds the exported realm
     */
    RealmBlueprintPhysicsBuilder.prototype.buildRealmBlueprint = function (realm, exportedRealm) {
        var e_1, _a;
        try {
            for (var _b = __values(exportedRealm.physicsBodies), _c = _b.next(); !_c.done; _c = _b.next()) {
                var physicsBody = _c.value;
                var _d = this.physicsBodyToCannonShape(physicsBody), shape = _d.shape, offset = _d.offset;
                var body = new realms_engine_1.CANNON.Body({
                    type: realms_engine_1.CANNON.BODY_TYPES["STATIC"],
                    position: (0, realms_engine_1.toCannonVec3)(physicsBody.position),
                    quaternion: (0, realms_engine_1.toCannonQuaternion)(physicsBody.quaternion),
                });
                var physicsGameObject = new realms_engine_1.Physics3DBodyGameObject(body, [
                    __assign({ shape: shape }, (offset ? { position: offset } : {})),
                ]);
                physicsGameObject.addGroup(this.gameObjectGroup);
                realm.scene.add(physicsGameObject);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    RealmBlueprintPhysicsBuilder.prototype.physicsBodyToCannonShape = function (physicsBody) {
        switch (physicsBody.type) {
            case physics_model_1.PhysicsBodyType.Box: {
                return {
                    shape: new realms_engine_1.CANNON.Box(new realms_engine_1.CANNON.Vec3(
                    // We need to halve since it is half extents
                    physicsBody.width * 0.5, physicsBody.height * 0.5, physicsBody.depth * 0.5)),
                };
            }
            case physics_model_1.PhysicsBodyType.Sphere: {
                return { shape: new realms_engine_1.CANNON.Sphere(physicsBody.radius) };
            }
            case physics_model_1.PhysicsBodyType.Plane: {
                var vertices = (0, realms_utils_1.getHeightMapVertices)({
                    data: physicsBody.data,
                    height: physicsBody.heightUnits,
                    width: physicsBody.widthUnits,
                });
                var cols = [];
                for (var x = 0; x < physicsBody.widthUnits + 1; x++) {
                    var col = [];
                    for (var y = 0; y < physicsBody.heightUnits + 1; y++) {
                        var i = y * (physicsBody.widthUnits + 1) + x;
                        var height = vertices[i];
                        col.push(height !== undefined ? height : 0);
                    }
                    cols.push(col);
                }
                return {
                    shape: new realms_engine_1.CANNON.Heightfield(cols, {
                        elementSize: physicsBody.unitSize,
                    }),
                    /**
                     * The center of a height field is the corner so we need to fix this.
                     * See: https://github.com/pmndrs/cannon-es/blob/master/examples/heightfield.html
                     */
                    offset: new realms_engine_1.CANNON.Vec3(-0.5 * physicsBody.widthUnits * physicsBody.unitSize, -0.5 * physicsBody.heightUnits * physicsBody.unitSize, 0),
                };
            }
            case physics_model_1.PhysicsBodyType.Cylinder: {
                return {
                    shape: new realms_engine_1.CANNON.Cylinder(physicsBody.radiusTop, physicsBody.radiusBottom, physicsBody.height, physicsBody.radialSegments),
                };
            }
        }
    };
    return RealmBlueprintPhysicsBuilder;
}());
exports.RealmBlueprintPhysicsBuilder = RealmBlueprintPhysicsBuilder;
