"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LerpGameObject = void 0;
var core_1 = require("../core");
var interpolation_1 = require("./interpolation");
var quat_1 = require("./quat");
var vector2_1 = require("./vector2");
var vector3_1 = require("./vector3");
/**
 * Lerps between two values
 */
var LerpGameObject = /** @class */ (function (_super) {
    __extends(LerpGameObject, _super);
    function LerpGameObject(setValue) {
        var _this = _super.call(this) || this;
        _this.setValue = setValue;
        _this.currentProcess = null;
        return _this;
    }
    LerpGameObject.prototype.step = function (delta) {
        if (!this.currentProcess) {
            return;
        }
        var totalTime = this.currentProcess.durationMs + this.currentProcess.options.delay;
        if (this.currentProcess.currentTimeMs === totalTime) {
            this.stop();
            return;
        }
        var alpha = Math.max(0, this.currentProcess.currentTimeMs - this.currentProcess.options.delay) / this.currentProcess.durationMs;
        var _a = this.currentProcess, start = _a.start, end = _a.end;
        var newValue = (0, vector2_1.isVector2)(start) && (0, vector2_1.isVector2)(end)
            ? (0, interpolation_1.lerpVector2)(start, end, alpha)
            : (0, vector3_1.isVector3)(start) && (0, vector3_1.isVector3)(end)
                ? (0, interpolation_1.lerpVector3)(start, end, alpha)
                : (0, quat_1.isQuat)(start) && (0, quat_1.isQuat)(end)
                    ? (0, quat_1.quatSlerp)(start, end, alpha)
                    : (0, interpolation_1.lerp)(start, end, alpha);
        this.setValue(newValue);
        // Update time
        this.currentProcess.currentTimeMs = Math.min(totalTime, this.currentProcess.currentTimeMs + delta);
    };
    /**
     * Lerps between two values over a given duration
     * @param start - value to start at
     * @param end - value to end at
     * @param durationMs - duration of lerp, not including delay
     */
    LerpGameObject.prototype.lerp = function (start, end, durationMs, options) {
        var _a;
        if (options === void 0) { options = {}; }
        this.currentProcess = {
            start: start,
            end: end,
            durationMs: durationMs,
            currentTimeMs: 0,
            options: {
                delay: (_a = options.delay) !== null && _a !== void 0 ? _a : 0,
            },
        };
    };
    /**
     * Stops all running processes
     */
    LerpGameObject.prototype.stop = function () {
        this.currentProcess = null;
    };
    return LerpGameObject;
}(core_1.ProcessGameObject));
exports.LerpGameObject = LerpGameObject;
