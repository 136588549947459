import { GameObject, Realm, System } from "realms-engine";
import { TrackingCameraSystem } from "realms-engine-browser";
import {
  PlayerGameObject,
  PlayerGameObjectEventsMap,
} from "../gameObjects/player";
import {
  ChatMessage,
  ChatMessageSenderType,
  MMONetSchema,
  PlayerInput,
} from "mmo-common";
import { SelfPlayerGameObject } from "@game/gameObjects/player/selfPlayer.gameObject";
import {
  NetworkSlaveSystem,
  PhysicsPredictionSystem,
} from "realms-engine-network-client";

export class PlayerSystem extends System<{
  healthChange: {
    health: number;
    maxHealth: number;
  };
  inCombatChange: PlayerGameObjectEventsMap["inCombatChange"];
}> {
  private playerHealth: number | null = null;
  private playerMaxHealth: number | null = null;
  private selfPlayerGameObject: SelfPlayerGameObject | null = null;
  private foundPlayer = false;

  private allPlayerGameObjects = new Set<PlayerGameObject>();

  constructor(private characterId: number) {
    super([PlayerGameObject]);
  }

  /**
   * Handle player input
   */
  handlePlayerInput(playerInput: PlayerInput) {
    if (!this.realm) {
      return;
    }

    if (this.selfPlayerGameObject) {
      // Simulate
      this.selfPlayerGameObject?.simulatePlayerInput(playerInput);
    }

    // Send input to server
    this.realm.systems
      .get(NetworkSlaveSystem<MMONetSchema>)
      .getClient()
      .getConnection()
      .emit("input", playerInput);
  }

  /**
   * Gets the target character id
   */
  getSelfCharacterId() {
    return this.characterId;
  }

  /**
   * Handles a chat message
   */
  handleChatMessage(chatMessage: ChatMessage) {
    for (const player of Array.from(this.allPlayerGameObjects.values())) {
      if (
        chatMessage.sender.type === ChatMessageSenderType.Player &&
        player.getCharacterId() === chatMessage.sender.characterId
      ) {
        player.getUI().showChatMessage(chatMessage);
        break;
      }
    }
  }

  onTargetGameObjectEnter(gameObject: GameObject<any>): void {
    if (gameObject instanceof PlayerGameObject) {
      this.allPlayerGameObjects.add(gameObject);
    }
  }

  onTargetGameObjectExit(gameObject: GameObject<any>): void {
    if (gameObject instanceof PlayerGameObject) {
      this.allPlayerGameObjects.delete(gameObject);
    }
  }

  /**
   * Gets the current players game object
   */
  getPlayerGameObject() {
    return this.selfPlayerGameObject;
  }

  processBeforeStep(delta: number, realm: Realm): void {
    this.foundPlayer = false;
  }

  step(delta: number, gameObject: GameObject, realm: Realm): void {
    if (gameObject instanceof SelfPlayerGameObject) {
      this.foundPlayer = true;
      this.selfPlayerGameObject = this.setSelfPlayerGameObject(gameObject);
      this.selfPlayerGameObject.setShouldHideUI(true);
      const physicsPredictionGameObject = realm.systems
        .get(PhysicsPredictionSystem)
        .getPhysicsPredictionGameObject(
          this.selfPlayerGameObject.getPhysicsGameObjectId()
        );
      if (physicsPredictionGameObject) {
        this.selfPlayerGameObject.setPhysicsPredictionGameObject(
          physicsPredictionGameObject
        );
      }

      realm.systems
        .get(TrackingCameraSystem)
        .setTarget(gameObject.getPosition())
        .setLookOffset({ x: 0, y: 10, z: 0 })
        .setCameraOffset({ x: 0, y: 10, z: 0 });

      // Emit health changed event
      const newPlayerHealth = gameObject.getHealth();
      const newPlayerMaxHealth = gameObject.getMaxHealth();
      if (
        this.playerHealth !== newPlayerHealth ||
        this.playerMaxHealth !== newPlayerMaxHealth
      ) {
        this.dispatch("healthChange", {
          health: newPlayerHealth,
          maxHealth: newPlayerMaxHealth,
        });
      }
      this.playerHealth = newPlayerHealth;
      this.playerMaxHealth = newPlayerMaxHealth;
    }
  }

  isCurrentlyInCombat(): boolean {
    return this.selfPlayerGameObject?.getPlayerState().inCombat ?? false;
  }

  private setSelfPlayerGameObject(selfPlayerGameObject: SelfPlayerGameObject) {
    if (this.selfPlayerGameObject !== selfPlayerGameObject) {
      this.selfPlayerGameObject?.removeListener(
        "inCombatChange",
        this.onSelfInCombatChange
      );
      selfPlayerGameObject.addListener(
        "inCombatChange",
        this.onSelfInCombatChange
      );
    }
    this.selfPlayerGameObject = selfPlayerGameObject;
    return selfPlayerGameObject;
  }

  private onSelfInCombatChange = (
    e: PlayerGameObjectEventsMap["inCombatChange"]
  ) => {
    this.dispatch("inCombatChange", e);
  };

  processAfterStep(delta: number, realm: Realm): void {
    if (!this.foundPlayer) {
      this.selfPlayerGameObject = null;
    }
  }
}
