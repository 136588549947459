import goblinAsset from "@assets/goblin/goblin_rigged.fbx";
import goblinSkinAsset from "@assets/goblin/skin.png";
import goblinFeaturesAsset from "@assets/goblin/features.png";
import goblinFeaturesFierceAsset from "@assets/goblin/features_fierce.png";
import goblinClothingAsset from "@assets/goblin/clothing.png";
import goblinClothingArmouredAsset from "@assets/goblin/clothing_armour.png";
import goblinWalkAnimationAsset from "@assets/goblin/walk.fbx";
import goblinWalkLeftAnimationAsset from "@assets/goblin/walk_left.fbx";
import goblinWalkRightAnimationAsset from "@assets/goblin/walk_right.fbx";
import goblinWalkBackAnimationAsset from "@assets/goblin/walk_back.fbx";
import goblinIdleAnimationAsset from "@assets/goblin/idle.fbx";
import goblinAttackAnimationAsset from "@assets/goblin/attack.fbx";
import goblinJumpAnimationAsset from "@assets/goblin/jump.fbx";
import goblinDeathAnimationAsset from "@assets/goblin/death.fbx";

const GOBLIN_SCALE = 0.003;

export const GoblinAssetMap = {
  goblin: {
    fileName: goblinAsset,
    scale: GOBLIN_SCALE,
  },
  goblinSkinGreen: {
    fileName: goblinSkinAsset,
  },
  goblinFeaturesNormal: {
    fileName: goblinFeaturesAsset,
  },
  goblinFeaturesFierce: {
    fileName: goblinFeaturesFierceAsset,
  },
  goblinClothingBarbaric: {
    fileName: goblinClothingAsset,
  },
  goblinClothingArmoured: {
    fileName: goblinClothingArmouredAsset,
  },
  goblinWalkAnimation: {
    fileName: goblinWalkAnimationAsset,
  },
  goblinWalkLeftAnimation: {
    fileName: goblinWalkLeftAnimationAsset,
  },
  goblinWalkRightAnimation: {
    fileName: goblinWalkRightAnimationAsset,
  },
  goblinWalkBackAnimation: {
    fileName: goblinWalkBackAnimationAsset,
  },
  goblinIdleAnimation: {
    fileName: goblinIdleAnimationAsset,
  },
  goblinAttackAnimation: {
    fileName: goblinAttackAnimationAsset,
  },
  goblinJumpAnimation: {
    fileName: goblinJumpAnimationAsset,
  },
  goblinDeathAnimation: {
    fileName: goblinDeathAnimationAsset,
  },
};
