"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NetworkSlaveSystem = void 0;
var realms_engine_1 = require("realms-engine");
var networkSlaveGameObject_1 = require("./networkSlaveGameObject");
var realms_engine_browser_1 = require("realms-engine-browser");
var NetworkSlaveSystem = /** @class */ (function (_super) {
    __extends(NetworkSlaveSystem, _super);
    function NetworkSlaveSystem(client, 
    /*
     * Maps a network type to the factory method of making the gameObject
     */
    gameObjectFactoryMap, options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, [networkSlaveGameObject_1.NetworkSlaveGameObject], []) || this;
        _this.client = client;
        _this.gameObjectFactoryMap = gameObjectFactoryMap;
        _this.options = options;
        /*
         * Maps the master gameObject id to the slave gameObject
         */
        _this.masterIdToSlave = new Map();
        _this.client = client;
        _this.gameObjectFactoryMap = gameObjectFactoryMap;
        return _this;
    }
    NetworkSlaveSystem.prototype.onTargetGameObjectEnter = function (gameObject) {
        if (gameObject instanceof networkSlaveGameObject_1.NetworkSlaveGameObject) {
            this.masterIdToSlave.set(gameObject.masterGameObjectId, gameObject);
        }
    };
    NetworkSlaveSystem.prototype.onTargetGameObjectExit = function (gameObject) {
        if (gameObject instanceof networkSlaveGameObject_1.NetworkSlaveGameObject) {
            this.masterIdToSlave.delete(gameObject.masterGameObjectId);
        }
    };
    NetworkSlaveSystem.prototype.getClient = function () {
        return this.client;
    };
    NetworkSlaveSystem.prototype.onGameStepStart = function (delta, realm) {
        var e_1, _a, e_2, _b;
        // Get current client state and sync that with client realm
        var currState = this.client.getCurrentState();
        // Have not received first state yet so do nothing
        if (currState === null) {
            return;
        }
        // Call listeners
        this.dispatch("onStateStep", currState);
        if (this.options.debugState) {
            realms_engine_browser_1.DebugPanel.logObject("NetworkSlaveSystem:state", currState);
        }
        // All slave gameObject master ids currently existing
        var existingMasterIds = new Set(this.masterIdToSlave.keys());
        try {
            for (var _c = __values(currState.gameObjects), _d = _c.next(); !_d.done; _d = _c.next()) {
                var newGameObjectState = _d.value;
                var masterId = newGameObjectState.id;
                var gameObject = this.masterIdToSlave.get(masterId);
                // New slave gameObject
                if (gameObject === undefined) {
                    // Create slave gameObject
                    var gameObject_1 = this.gameObjectFactoryMap[newGameObjectState.type](newGameObjectState.state, realm);
                    var slaveGameObject = new networkSlaveGameObject_1.NetworkSlaveGameObject(gameObject_1, masterId);
                    realm.scene.add(slaveGameObject);
                }
                else {
                    // Update existing entity
                    gameObject.setState(newGameObjectState.state);
                    existingMasterIds.delete(masterId);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        try {
            // All left over ids have been deleted from the master realm
            // so remove them in the client realm
            for (var existingMasterIds_1 = __values(existingMasterIds), existingMasterIds_1_1 = existingMasterIds_1.next(); !existingMasterIds_1_1.done; existingMasterIds_1_1 = existingMasterIds_1.next()) {
                var masterId = existingMasterIds_1_1.value;
                var slaveGameObject = this.masterIdToSlave.get(masterId);
                if (slaveGameObject !== undefined) {
                    realm.scene.remove(slaveGameObject);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (existingMasterIds_1_1 && !existingMasterIds_1_1.done && (_b = existingMasterIds_1.return)) _b.call(existingMasterIds_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    return NetworkSlaveSystem;
}(realms_engine_1.System));
exports.NetworkSlaveSystem = NetworkSlaveSystem;
