import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LOGGED_OUT_DEFAULT_ROUTE, useAuth } from "realmsbase-sdk";
import { useLoading } from "@utils/loading/useLoading";

interface AdminGuardProps {
  children: any;
}

export const AdminGuard = ({ children }: AdminGuardProps) => {
  const { account } = useAuth({ redirectIfLoggedOut: true });
  const navigate = useNavigate();
  const { createLoadingTask, endLoadingTask } = useLoading();

  useEffect(() => {
    if (!account || !account.isAdmin) {
      const taskId = createLoadingTask({
        type: "spinner",
        text: "Authenticating...",
      });
      return () => {
        endLoadingTask(taskId);
      };
    }
  }, [account, createLoadingTask, endLoadingTask]);

  useEffect(() => {
    if (account && !account.isAdmin) {
      navigate(LOGGED_OUT_DEFAULT_ROUTE);
    }
  }, [account, navigate]);

  return <>{children}</>;
};
